import styled from 'styled-components';

export const FromToStyled = styled.div.attrs((props) => ({
    className : 'pagination-label small text-muted'
}))`
  min-width: 100px;
  font-size: 0.7em;
`

export const BackToTop = styled.div.attrs((props) => ({
    className : ''
}))`
    min-width: 100px;
    font-size: 0.7em;
    margin-top: 10px;

    :hover {
        text-decoration: underline;
    }
`
