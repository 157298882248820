import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {EnvironmentContext} from "../../../../providers/environment/EnvironmentContext";
import {SessionContext} from "../../../../providers/session/SessionContext";
import {SystemContext} from '../../../../providers/system/SystemContext';
import {setAdvancedCellWidth} from "../../../../providers/theme/GuslThemeProvider";
import {cleanWidthFromStyle, getStyle, getWidthFromStyle} from "../../../../utils/CssUtils";
import {arrayNotEmpty, isDefined, notDefined} from "../../../../utils/TypeCheckers";
import {
    areActionsMediaTypeSensitive,
    buildPathFromRef,
    constructUrl,
    matchMediaTypeWithAction,
    noop
} from "../../../../utils/Utils";
import {fieldService} from "../../../FieldService";
import {
    ActionConfigDTO,
    ActionType,
    ConditionalRowDetailsDTO,
    FieldConfigDTO,
    IMenuDTO,
    MediaType,
    TableRowDTO,
    WidgetPanelProperties
} from "../../../types";
import {setOpenExpandedRowId} from "../../action-dialog/actionSlice";
import {InputCheckboxStyled} from "../../checkbox/styled";
import {ElementWithTooltip} from "../../element-with/element-with-tooltip/ElementWithTooltip";
import {GuslReport} from "../../gusl-report/GuslReport";
import {TableColumnResizeState} from "../../gusl-table/guslColumnResizeSlice";
import {GuslTable} from "../../gusl-table/GuslTable";
import {defaultCellWidth, GuslTableState} from "../../gusl-table/guslTableSlice";
import {safeStringify} from "../../gusl-table/inline-edit/functions/safeStringify";
import InlineEditInputField from "../../gusl-table/inline-edit/InlineEditInputField";
import {addToOriginalRows, InlineEditState, updateEditFields} from "../../gusl-table/inline-edit/inlineEditSlice";
import {MultiSelectObjectSelectionDO} from "../../gusl-table/inline-edit/types";
import {expandableIconCellWidth, groupActionsCellWidth} from "../../gusl-table/ListView";
import {ROWS_PER_PAGE} from '../../gusl-table/PaginationService';
import {TableRowStyled} from "../../gusl-table/styled/table/styled";
import {toggleOptionFieldOpen} from "../../option/optionFieldSlice";
import {ActionPlaceholder} from "../action-placeholder/ActionPlaceholder";
import {ActionHandler} from '../ActionHandler';
import Icon from "../bootstrap/Icon";
import {InlineActionPanel} from "../inline-action/InlineActionPanel";
import {maintainTableService} from "../MaintainTableService";
import {ActionColumnStyled, CheckboxWrapperStyled, ColumnStyled} from "../styled";
import {GroupActionState, toggleRowSelection} from "./groupActionsSlice";
import {cleanUpActionOpen, RowActionOpenState} from "./rowActionOpenSlice";
import {RowExpanderActionState, toggleRowExpander} from "./rowExpanderSlice";
import {ChevronIconStyled, ExpandedPanelStyled} from "./styled";
import "./TableRow.css";
import {
    cleanUp,
    closeAction,
    initRow,
    ShowActionType,
    TableRowState,
    toggleActionItem,
    updateCurrentAction
} from "./tableRowSlice";

export type TableRowProperties = {
    code: string;
    rowId: string;
    rowIndex: number;
    row: TableRowDTO,
    fields: FieldConfigDTO[],
    firstRow: boolean,
    lastRow: boolean,
    menuItem: any,
    performTableRefresh: () => void,
    conditionalRowDetails?: ConditionalRowDetailsDTO | undefined
    firstTab?: string | undefined,
    editAs?: "popover" | "modal",
    isSummaryHeader?: boolean | undefined,
    isSummaryFooter?: boolean | undefined,
    isDialog?: boolean;

    mediaType: MediaType;
    // ref?: any;
    // MK 06/09/2023
    isSquashed?: boolean;
    isNested?: boolean;
    isOverflown?: boolean;
    numOfActions?: number;
    widgetPanelProperties?: WidgetPanelProperties

}

interface EditingField {
    fieldType: string;
    name: string;
    value: string | number | boolean | ConditionalRowDetailsDTO | ActionConfigDTO[] | Element | DOMRectReadOnly | null | undefined;
}

export const cascadingTable: string = "cascading table";
export const TableRow = React.forwardRef(({
                                              code,
                                              rowId,
                                              rowIndex,
                                              row,
                                              fields,
                                              menuItem,
                                              performTableRefresh,
                                              conditionalRowDetails,
                                              firstTab,
                                              isSummaryHeader,
                                              isSummaryFooter,
                                              mediaType,
                                              // ref,
                                              // MK 07/09/2023
                                              isSquashed,
                                              isNested,
                                              numOfActions,
                                              widgetPanelProperties,
                                              firstRow,
                                              lastRow,
                                          }: TableRowProperties, ref?: React.ForwardedRef<any>): React.ReactElement => {

    const environmentContext = React.useContext(EnvironmentContext);
    const systemContext = React.useContext(SystemContext);

    const [menuItem2] = useState<IMenuDTO | undefined>(
        systemContext.getMenuConfig(code)
    );
    const hasInfiniScroll: boolean | undefined = menuItem2?.infiniScroll;


    const navigate = useNavigate();

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = useState("TableRow-" + new Date().getTime());

    const dispatch = useAppDispatch();
    const _columnResizeState: TableColumnResizeState = useAppSelector(state => state.guslColumnResizeSlice[code]);
    const _groupActionState: GroupActionState = useAppSelector(state => state.groupActionsSlice[code]);
    const _tableState: GuslTableState = useAppSelector(state => state.guslTableSlice[code]);
    // MK 17-02-2024
    const inlineEditState: InlineEditState = useAppSelector(state => state.inlineEditSlice[code]);
    const inlineEdit: boolean = inlineEditState.inlineEdit;
    const fieldsEditMode = inlineEditState.fieldsInEditMode;
    // console.log({ inlineEditStateFields: inlineEditState.fieldsInEditMode, rows: inlineEditState.rowsInEditMode });
    // MK 06/09/2023
    const sideNavOpen = useAppSelector(state => state.uiSlice.isSideNavOpen);
    // MK 23/08/2023
    const individualAdvancedSearchFields: string[] = _tableState.individualAdvancedSearchFields;
    // const isIndividualFilterShown : boolean | undefined = individualAdvancedSearchFields.includes(fld.name);
    // END OF 23/08/2023
    // const _masterTableRowState: MasterTableRowState = useAppSelector(state => state.tableRowSlice);
    const sessionContext = React.useContext(SessionContext);
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    const tableRowRef = useRef();
    // MK 18-02-2024
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const setFocusHandler = (index: number, timeout: number = 10) => {
        setTimeout(() => inputRefs.current[index]?.focus(), timeout);
    };


    // MK 12/08/2023  for cell width when top search is open
    const showTopTableFilters = _tableState?.showTopFilters;
    // END OF MK 12/08/2023  for cell width when top search is open

    // MK 04-01-2024 setting width of actions cell only for visible actions
    const actionMediaTypeSensitive = areActionsMediaTypeSensitive((row.actions || []), widgetPanelProperties);
    const filteredActions: TableRowDTO["actions"] = row.actions?.filter(action => maintainTableService.passesCondition(action, row, sessionContext.getLoggedInUser()))
        .filter(action => matchMediaTypeWithAction(action, mediaType, actionMediaTypeSensitive)) || [];

    //const [numberOfActions] = useState<number>(filteredActions.length);

    const openInlineRowId = useAppSelector(state => state.actionSlice.openInlineRowId);
    const openExpandedRowId = useAppSelector(state => state.actionSlice.openExpandedRowId);
    const _rowActionOpenState: RowActionOpenState = useAppSelector(state => state.rowActionOpenSlice);

    const currentlyResizingHeaderName: string | undefined = _columnResizeState?.resizeColumnFieldName;

    useEffect(() => {
        dispatch(initRow({
            rowId,
            row,
            fields,
            menuItem,
            performTableRefresh,
            conditionalRowDetails
        }));
        setLoading(false);

        return (() => {
            dispatch(cleanUp({rowId: rowId}));
            dispatch(cleanUpActionOpen({code: code, rowId: rowId}));
        });

    }, [rowId]);


    const isActionIconActive = (state: TableRowState, row: TableRowDTO, actionItem: ActionConfigDTO): boolean => {
        if (state.currentAction) {
            return state.currentAction?.id === actionItem.id && state.currentAction.buttonLabel === actionItem.buttonLabel;
        } else {
            return false;
        }
    };

    const onActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, state: TableRowState, row: TableRowDTO, actionItem: ActionConfigDTO) => {
        if (e) {
            e.stopPropagation();
        }
        if (!actionItem) {
            return;
        }
        if (actionItem.actionType === "FIRE_COMMAND") {
            // console.log('------------ FIRE_COMMAND row',row)
            // console.log('------------ FIRE_COMMAND state',state)
            const actionHandler: ActionHandler = new ActionHandler(navigate, sessionContext, systemContext, dispatch)
            actionHandler.handle(code, actionItem, row)
                .then((complete: boolean) => {
                }).catch(errors => {
            })
        } else if (actionItem.actionType === "ROUTE") {
            navigate("/" + constructUrl(actionItem?.route || "", row));
            return;
        } else if (actionItem.inLine && !(actionItem.actionType === ActionType.ACTION_ONLY || actionItem.actionType === ActionType.DOWNLOAD_NO_PROMPT)) {
            if (state.currentAction && state.currentAction.id === actionItem.id) {
                dispatch(closeAction({rowId})); // clean the action
            } else if (state.currentAction && state.currentAction.id !== actionItem.id) {
                dispatch(closeAction({rowId})); // clean the action
                dispatch(updateCurrentAction({
                    rowId,
                    currentAction: actionItem
                }));
            } else {
                dispatch(updateCurrentAction({
                    rowId,
                    currentAction: actionItem
                }));
            }
            dispatch(toggleOptionFieldOpen({id: rowId}));
        } else {
            if (actionItem.actionType === ActionType.ACTION_ONLY) {
                if (actionItem.actionOnlyFunction) {
                    // log.info(className, 'MSG001', 'ACTION_ONLY', {rowId: row.id, type: action.actionType})
                    actionItem.actionOnlyFunction(actionItem, row);
                }
            } else if (actionItem.actionType === ActionType.DOWNLOAD_NO_PROMPT) {
                if (actionItem.downloadFunction) {
                    actionItem.downloadFunction(actionItem, row);
                }
            } else {
                dispatch(toggleActionItem({rowId, actionItem, showType: ShowActionType.MODAL}));
            }
        }
    };

    const renderRowActions = (state: TableRowState): React.ReactElement => {

        if (isMobile) {
            return <></>;
        }
        return (
            <ActionColumnStyled key={"actions"} numberOfActions={numOfActions as number}>
                {
                    filteredActions
                        .map((action: ActionConfigDTO, idx) =>
                            (typeof action?.tooltip === "undefined") ?
                                <span key={idx} className={"mx-1"}>
                                <Icon
                                    onClick={(e) => onActionClick(e, state, row, action)}
                                    className={" action_icon " + action.icon + (isActionIconActive(state, row, action) ? " active" : "")}
                                    icon={action.icon}/>
                            </span> :
                                <ElementWithTooltip
                                    key={idx}
                                    element={
                                        <span key={idx} className={"mx-1"}>
                                <Icon
                                    onClick={(e) => onActionClick(e, state, row, action)}
                                    className={"action_icon " + action.icon + (isActionIconActive(state, row, action) ? " active" : "")}
                                    icon={action.icon}/>
                            </span>}
                                    tooltip={<span>{maintainTableService.getTitle(row, action?.tooltip || "")}</span>}/>
                        )
                }
            </ActionColumnStyled>
        );
    };

    const renderExpandedPanel = ({state, opened}: RowExpanderActionState): React.ReactElement => {
        if (!opened) {
            return <></>;
        } else {
            return (
                <TableRowStyled key={"id_" + rowId + "_" + state.currentAction?.id}>
                    <ColumnStyled colSpan={fields.length + 1 + (arrayNotEmpty(row.actions) ? 1 : 0)} cellWidth={"auto"}>
                        <div className="row g-0">
                            <div className={"col-md-12"}>
                                <ExpandedPanelStyled
                                    // MK 06/09/2023
                                    isNested={isNested}
                                    isSquashed={isSquashed}
                                    sideNavOpen={sideNavOpen}
                                >
                                    {conditionalRowDetails?.table && <GuslTable
                                        code={"id_" + rowId + "_" + state.currentAction?.id}
                                        hideHeaderPanel={true}
                                        label={cascadingTable}
                                        selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || "", row)}
                                    />}
                                    {conditionalRowDetails?.report && <GuslReport
                                        code={"id_" + rowId + "_" + state.currentAction?.id}
                                        selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || "", row)}
                                    />
                                    }
                                </ExpandedPanelStyled>
                            </div>
                        </div>
                    </ColumnStyled>
                </TableRowStyled>
            );
        }
    };


    const renderGroupAction = (): React.ReactElement => {

        if (!_tableState.haveGroupActions) {
            return <></>;
        }

        const hasMatchingGroupAction = _tableState.groupActions?.filter(action => maintainTableService.passesCondition(action, row, sessionContext.getLoggedInUser()));

        if (hasMatchingGroupAction?.length === 0) {
            return <ColumnStyled key={"row-expand_" + refreshCounter} cellWidth={groupActionsCellWidth}>
                <CheckboxWrapperStyled>
                    <div>&nbsp;</div>
                </CheckboxWrapperStyled>
            </ColumnStyled>;
        }


        // const renderExpandedPanel = ({state, opened}: RowExpanderActionState): React.ReactElement => {
        //     if (!opened) {
        //         return <></>;
        //     } else {
        //         return (
        //             <TableRowStyled key={"id_" + rowId + "_" + state.currentAction?.id}>
        //                 <ColumnStyled colSpan={fields.length + 1 + (arrayNotEmpty(row.actions) ? 1 : 0)} cellWidth={"auto"}>
        //                     <div className="row g-0">
        //                         <div className={"col-md-12"}>
        //                             <ExpandedPanelStyled
        //                                 // MK 06/09/2023
        //                                 isNested={isNested}
        //                                 isSquashed={isSquashed}
        //                                 sideNavOpen={sideNavOpen}
        //                             >
        //                                 {conditionalRowDetails?.table && <GuslTable
        //                                     code={"id_" + rowId + "_" + state.currentAction?.id}
        //                                     hideHeaderPanel={true}
        //                                     label={cascadingTable}
        //                                     selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || "", row)}
        //                                 />}
        //                                 {conditionalRowDetails?.report && <GuslReport
        //                                     code={"id_" + rowId + "_" + state.currentAction?.id}
        //                                     selectUrl={constructUrl(conditionalRowDetails?.url || conditionalRowDetails?.expandUrl || "", row)}
        //                                 />
        //                                 }
        //                             </ExpandedPanelStyled>
        //                         </div>
        //                     </div>
        //                 </ColumnStyled>
        //             </TableRowStyled>
        //         );
        //     }
        // };
//
//
//     const renderGroupAction = (): React.ReactElement => {
//
//         if (!_tableState.haveGroupActions) {
//             return <></>;
//         }
//
        const onCheckboxClick = (e: any) => {
            // required from stopping row open
            if (e) {
                e.stopPropagation();
            }
            e.preventDefault();
            return false;
        };

        const onDataInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            // @ts-ignore
            const shiftKey = e.nativeEvent.shiftKey;

            dispatch(toggleRowSelection({
                code: code,
                rowId: rowId,
                rowIndex: rowIndex,
                row: row,
                selected: e.target.checked,
                withShiftKey: shiftKey,
                tableData: _tableState?.tableData
            }));
            setRefreshCounter(refreshCounter + 1);
        };

        const selected: boolean = _groupActionState && _groupActionState?.selectedRows && isDefined(_groupActionState?.selectedRows["r_" + rowIndex]);

        return (
            <ColumnStyled key={"row-expand_" + refreshCounter} cellWidth={groupActionsCellWidth}>
                <CheckboxWrapperStyled>
                    <InputCheckboxStyled type="checkbox"
                                         id={"groupAction_" + rowId}
                                         key={"groupAction_" + refreshCounter + "_" + rowId}
                                         name={"groupAction"}
                                         checked={selected}
                                         readonly={false}
                                         disabled={false}
                                         onChange={(e) => onDataInputChange(e)}
                                         onClick={(e) => onCheckboxClick(e)}
                                         submitted={false}
                                         noValue={false}
                    />
                </CheckboxWrapperStyled>
            </ColumnStyled>
        );
    };

    const renderRowExpandIcon = ({state, opened}: RowExpanderActionState): React.ReactElement => {
        const onRowExpandClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
            dispatch(toggleRowExpander({state: state, id: rowId}));
            dispatch(setOpenExpandedRowId((openExpandedRowId !== "" && openExpandedRowId === rowId && opened) ? "" : rowId));
        };

        if (notDefined(conditionalRowDetails)) {
            return <></>;
        }

        return (
            <ColumnStyled key={"row-expand"} cellWidth={expandableIconCellWidth}>
                        <span className={"mx-1"}>
                                <ChevronIconStyled isOpen={opened}
                                                   onClick={(e) => onRowExpandClick(e)}
                                                   icon={"fa fa-chevron-right"}/>
                            </span>
            </ColumnStyled>
        );
    };

    const mapStateToProps = (state: any): RowExpanderActionState => {
        let currentState = state.rowExpanderSlice[rowId];
        return ({
            opened: currentState ? currentState.opened : false,
            state: currentState
        });
    };
    const InLineRowExpanderIconConnectedContainer = connect(mapStateToProps)(renderRowExpandIcon);
    const InLineExpanderPanelConnectedContainer = connect(mapStateToProps)(renderExpandedPanel);

    function getCellWidth(fld: FieldConfigDTO) {
        const hdrKey = "hdr_" + fld.name + "_" + code; // + (isSummaryHeader ? '_header' : '') + (isSummaryFooter ? '_footer' : '')
        const correspondingHeader = document.getElementById(hdrKey);
        // console.log({ wr: _columnResizeState?.resizedColumns[fld.name], header: correspondingHeader?.offsetWidth });

        // MK 24-02-2024
        /// was before ----> correspondingHeader ? correspondingHeader.offsetWidth - 1 :
        // this -1 was causing the issues with the tables
        // MK 30-12-2023
        return correspondingHeader ? correspondingHeader.offsetWidth :
            _columnResizeState?.resizedColumns[fld.name] || getWidthFromStyle(fld.style) || getWidthFromStyle(getStyle(fld.tableCss)) || fld.cellWidth || defaultCellWidth;
        // return correspondingHeader?.offsetWidth;
        // console.log('getCellWidth:',fld.name,
        //     'rs: '+_columnResizeState?.resizedColumns[fld.name],
        //     's: '+getWidthFromStyle(fld.style),
        //     'tc: ',getStyle(fld.tableCss),
        //     'tcw: '+getWidthFromStyle(getStyle(fld.tableCss)),
        //     'cw: '+fld.cellWidth,
        //     'd: '+defaultCellWidth
        //     )
        //
        // return  _columnResizeState?.resizedColumns[fld.name] || getWidthFromStyle(fld.style) || getWidthFromStyle(getStyle(fld.tableCss)) || fld.cellWidth || defaultCellWidth;
    }

    function isBeingResized(fld: FieldConfigDTO) {
        return fld.name === currentlyResizingHeaderName;
    }

    const renderFields = (state: TableRowState): React.ReactElement => {
        const onFieldClick = (fld: FieldConfigDTO, e: React.MouseEvent<HTMLDivElement>, idx: number) => {
            const linkPath = buildPathFromRef(fld, row);


            if (linkPath && e && !inlineEdit) {
                e.stopPropagation();
                window.open(linkPath);
                // navigate(linkPath)
            } else if (inlineEdit) {
                console.log("dispatching here")
                dispatch(addToOriginalRows({code, row: row as unknown as { [index: string]: string }}));

                if (fld.type === "multi_select") {
                    // @ts-ignore
                    const defaultSelections: MultiSelectObjectSelectionDO = row[fld.name];
                    if (defaultSelections && defaultSelections.selections) {
                        dispatch(updateEditFields({
                            code,
                            // @ts-ignore
                            field: {
                                id: row["id"],
                                type: fld.type,
                                name: fld.name,
                                value: safeStringify(defaultSelections.selections),
                                rowId
                            }
                        }));
                    } else {
                        dispatch(updateEditFields({
                            code,
                            // @ts-ignore
                            field: {
                                id: row["id"],
                                type: fld.type,
                                name: fld.name,
                                value: "",
                                rowId
                            }
                        }));
                    }

                } else {
                    dispatch(updateEditFields({
                        code,
                        // @ts-ignore
                        field: {id: row["id"], type: fld.type, name: fld.name, value: row[fld.name] as string, rowId}
                    }));
                }

                setFocusHandler(idx);

            }
        };

        function isFieldBeingEdited(fld: FieldConfigDTO) {
            const found = fieldsEditMode.find(item =>
                item.name === fld.name &&
                item.rowId === rowId
            );
            return typeof found !== "undefined";
        }

        function getTheEditedValue(fld: FieldConfigDTO): string | MultiSelectObjectSelectionDO | undefined {
            if (fld.type === "multi_select") {
                // @ts-ignore
                return fieldsEditMode.find(f => f.rowId === rowId && f.name === fld.name)?.value.selections;
            } else {
                return fieldsEditMode.find(f => f.rowId === rowId && f.name === fld.name)?.value;
            }

        }

        function isFieldEditable(fld: FieldConfigDTO): boolean {
            return typeof fld.inlineReadOnly !== "undefined" && !fld.inlineReadOnly;
        }

// MK 17-02-2024
        function inlineFieldEditHandler(e:
                                            | React.MouseEvent<HTMLInputElement>
                                            | React.FocusEvent<HTMLInputElement>
                                            | React.ChangeEvent<HTMLInputElement>
                                            | React.ChangeEvent<HTMLSelectElement>,
                                        fld: FieldConfigDTO
        ) {
            dispatch(updateEditFields({
                code,
                field: {id: row["id"], type: fld.type, name: fld.name, value: e?.currentTarget.value as string, rowId}
            }));

        }


        return (
            <>
                {fields.map((fld: FieldConfigDTO, idx: number) => {
                        const hasLink: boolean = isDefined(fld?.ref);
                        const cellWidth = setAdvancedCellWidth(getCellWidth(fld), showTopTableFilters, individualAdvancedSearchFields.includes(fld.name), fld.sortable, false, fld.type, inlineEdit);


                        // @ts-ignore
                        return <ColumnStyled key={"det_" + rowId + "_" + idx}
                                             hasLink={hasLink}
                                             onClick={(e) => {
                                                 !isFieldBeingEdited(fld) ? onFieldClick(fld, e, idx) : noop();
                                             }}

                                             beingResized={isBeingResized(fld)}
                                             style={fld.colStyle ? cleanWidthFromStyle(fld.colStyle) : cleanWidthFromStyle(fld.style)}

                            // MK 12/08/2023
                            // cellWidth={getCellWidth(fld) }

                                             cellWidth={cellWidth}
                            // END OF 12/08/2023
                                             id={"det_" + rowId + "_" + idx}
                                             className={"joe " + rowId}
                                             isEditable={inlineEdit && isFieldEditable(fld)}

                        >
                            {/*<span>{rowIndex}</span>*/}

                            {(inlineEdit && isFieldEditable(fld) && isFieldBeingEdited(fld)) ?
                                // {(true && true && isFieldBeingEdited(fld)) ?

                                <InlineEditInputField
                                    key={"ff-det_" + rowId + "_" + idx + "---" + fld.name}
                                    rowId={rowId}
                                    idx={idx}
                                    fld={fld}
                                    inputRefs={inputRefs}
                                    inlineFieldEditHandler={(e) => {
                                        inlineFieldEditHandler(e, fld);
                                    }}
                                    setFocus={() => {
                                        setFocusHandler(idx);
                                    }}
                                    row={row}
                                    code={code}
                                    /*@ts-ignore*/
                                    defaultValue={typeof getTheEditedValue(fld) !== "undefined" ? getTheEditedValue(fld) : row[fld.name as keyof TableRowDTO]}
                                />
                                :
                                fieldService.getTableField(fld, menuItem, row, performTableRefresh, code, _tableState, state)?.render()}
                        </ColumnStyled>;
                    }
                )}
            </>
        );
    };

    const renderTableRows = (state: TableRowState): React.ReactElement => {
        const onRowClicked = (event: React.MouseEvent<HTMLTableRowElement>, id: string) => {
            if (event) {
                event.stopPropagation();
            }
            // @ts-ignore
            if (event?.target?.className === "btn-close") {
                return;
            }

            if (firstTab) {
                const hasKey = event.ctrlKey || event.shiftKey || event.altKey || event.metaKey;
                const href = "/" + code + "/" + id + (firstTab ? "/" + firstTab : "");
                if (inlineEditState.inlineEdit) {
                    // do nothing, allow inline edit
                } else if (hasKey) {
                    Object.assign(document.createElement("a"), {
                        target: "_blank",
                        href: href
                    }).click();
                } else {
                    navigate(href);
                }
            }
        };

        if (row.flash) {
            setTimeout(() => document?.getElementById(code + "_id_" + rowId)?.classList?.remove("highlight"), 3000);
        }
        const selected: boolean = _groupActionState && _groupActionState?.selectedRows && isDefined(_groupActionState?.selectedRows["r_" + rowIndex]);
        /*
        + (selected ? (' row-selected') : ' ')
         */
        return (
            <TableRowStyled key={code + "_id_" + rowId + "_" + refreshCounter}
                            id={firstRow ? 'first-row-' + code : (lastRow ? 'last-row-' + code : code + "_id_" + rowId)}
                            data-skip={firstRow ? _tableState?.lastQueryParams?.skip || 0 : (lastRow ? _tableState?.lastQueryParams?.skip || 0 : undefined)}
                            data-limit={firstRow ? _tableState?.lastQueryParams?.limit || 20 : (lastRow ? _tableState?.lastQueryParams?.limit || ROWS_PER_PAGE[0] : undefined)}
                            ref={lastRow ? ref : undefined}
                            data-row-key={hasInfiniScroll ? rowIndex : 1000}
                            selected={selected}
                            className={"fred  " + rowId + " " + (row.flash ? "highlight " : " ") + (row.status ? (" row-status-" + row.status) : " ")}
                            onClick={(event) => onRowClicked(event, row.id)} firstTab={firstTab}>
                {state.expandableRow && <InLineRowExpanderIconConnectedContainer/>}
                {renderGroupAction()}
                {arrayNotEmpty(row.actions) && renderRowActions(state)}
                {renderFields(state)}
            </TableRowStyled>
        );
    };
    // const renderRow = (state: TableRowState): React.ReactElement => {
    //     if (loading) {
    //         return <></>;
    //     }
    //     const closeDialog = () => {
    //         performTableRefresh();
    //     };
    //
    //     if (state) {
    //         return (
    //             <>
    //                 {renderTableRows(state)}
    //                 <InlineActionPanel key={"iap_" + rowId} code={code} rowId={rowId} colspan={fields.length + 1}
    //                                    row={row}
    //                                    performTableRefresh={performTableRefresh} action={state.currentAction}
    //                                    showActionModal={state.showActionModal}
    //                                    openRowId={_rowActionOpenState.rowId}
    //                                    openCode={_rowActionOpenState.code || ""}
    //                                    closeDialog={closeDialog}
    //                 />
    //                 {/*{renderActionPlaceholder(state, row)}*/}
    //                 <ActionPlaceholder key={"aph_" + rowId} code={code} rowId={rowId} colspan={fields.length + 1}
    //                                    row={row}
    //                                    performTableRefresh={performTableRefresh} action={state.currentAction}
    //                                    showActionModal={state.showActionModal}
    //                                    openRowId={_rowActionOpenState.rowId}
    //                                    openCode={_rowActionOpenState.code || ""}
    //                                    closeDialog={closeDialog}
    //                 />
    //                 <InLineExpanderPanelConnectedContainer/>
    //             </>
    //         );
    //     } else {
    //         return <></>;
    //     }
    // };

    const renderRow = (state: TableRowState): React.ReactElement => {
        if (loading) {
            return <></>;
        }
        const closeDialog = () => {
            performTableRefresh();
        };

        if (state) {
            return (
                <>
                    {renderTableRows(state)}
                    <InlineActionPanel key={"iap_" + rowId} code={code} rowId={rowId} colspan={fields.length + 1}
                                       row={row}
                                       performTableRefresh={performTableRefresh} action={state.currentAction}
                                       showActionModal={state.showActionModal}
                                       openRowId={_rowActionOpenState.rowId}
                                       openCode={_rowActionOpenState.code || ""}
                                       closeDialog={closeDialog}
                    />
                    {/*{renderActionPlaceholder(state, row)}*/}
                    <ActionPlaceholder key={"aph_" + rowId} code={code} rowId={rowId} colspan={fields.length + 1}
                                       row={row}
                                       performTableRefresh={performTableRefresh} action={state.currentAction}
                                       showActionModal={state.showActionModal}
                                       openRowId={_rowActionOpenState.rowId}
                                       openCode={_rowActionOpenState.code || ""}
                                       closeDialog={closeDialog}
                    />
                    <InLineExpanderPanelConnectedContainer/>
                </>
            );
        } else {
            return <></>;
        }
    };

    const mapStateToRowProps = (state: any): TableRowState => {
        return state.tableRowSlice[rowId];
    };
    const ConnectedRow = connect(mapStateToRowProps)(renderRow);


    return (
        <>
            {!loading && <ConnectedRow/>}
        </>
    );
});
