import React from 'react';
import {useAppDispatch} from '../../../../app/hooks';
import {SessionContext} from '../../../../providers/session/SessionContext';
import {SystemContext} from '../../../../providers/system/SystemContext';
import {constructUrl} from '../../../../utils/Utils';
import {ActionConfigDTO, ActionType, TableRowDTO} from '../../../types';
import ActionDialog from '../../action-dialog/ActionDialog';
import {closeAction, ShowActionType, toggleActionItem} from '../table-row/tableRowSlice';

interface ActionPlaceholderProps {
    code: string,
    rowId: string,
    row: TableRowDTO,
    openCode: string,
    openRowId: string | undefined,
    colspan: number,
    action: ActionConfigDTO | undefined,
    performTableRefresh: () => void,
    showActionModal: ShowActionType,
    closeDialog: () => void;

}

export const ActionPlaceholder = ({
                                      code,
                                      rowId,
                                      colspan,
                                      action,
                                      row,
                                      performTableRefresh,
                                      showActionModal,
                                      openCode,
                                      openRowId,
                                      closeDialog
                                  }: ActionPlaceholderProps): React.ReactElement<ActionPlaceholderProps> => {
    const dispatch = useAppDispatch();
    const sessionContext = React.useContext(SessionContext);
    const systemContext = React.useContext(SystemContext);

    const refreshData = () => {
        if (action && action.url) {
            const url = action.url
            const method = action.method
            sessionContext.sendRequest<any, any>(
                {
                    method: method,
                    url: constructUrl(url, row)
                })
                .then((response: any) => {
                    if (response.notificationMessage && !response.hideNotification) {
                        systemContext.toast(response)
                    }

                    dispatch(toggleActionItem({
                        rowId,
                        actionItem: undefined,
                        showType: ShowActionType.NO_ACTION
                    }))
                    if (performTableRefresh) {
                        performTableRefresh()
                    }
                }).catch(err => {
                console.log('error', err)
            })
        }
    }

    const closeTheAction = () => {
        setTimeout(() => {
            dispatch(closeAction({rowId})) // clean the action
        }, 100)

    }
    const onRefresh = () => {
        // refreshData()
        if (performTableRefresh) {
            performTableRefresh()
        }
        if (closeDialog) {
            closeDialog()
        }
    }

    if (showActionModal === ShowActionType.NO_MODAL && action) {
        if (action.actionType === ActionType.ACTION_ONLY) {
            if (action.actionOnlyFunction) {
                // log.info(className, 'MSG001', 'ACTION_ONLY', {rowId: row.id, type: action.actionType})
                action.actionOnlyFunction(action, row)
                // dispatch(closeAction({rowId})) // clean the action
            } else if (action.url && action.method) {
                // dispatch(closeAction({rowId})) // clean the action
                refreshData()
            } else {
                console.log('Not configure to do anything', action)
            }
            return <></>
        } else if (action.actionType === ActionType.DOWNLOAD_NO_PROMPT) {
            //  console.log('--- DOWNLOAD_NO_PROMPT')
            if (action.downloadFunction) {
                // dispatch(closeAction({rowId: action.rowId})) // clean the action
                action.downloadFunction(action, row)
            }
            return <></>
        } else {
            return <></>
        }
        // } else if ((openModalRowId && openModalRowId === rowId) || (showActionModal === ShowActionType.MODAL && action)) {
    } else if (showActionModal === ShowActionType.MODAL && action) {

        let currentAction = action;
        // if (openModalRowId === rowId) {
        //     currentAction = getCurrentAction(openModalActionId);
        // }
        // Modal based actions
        // @ts-ignore
        return <>
            <ActionDialog action={currentAction} data={row}
                          onHide={() => {
                              // resetOpenModalActionHandler();
                              dispatch(toggleActionItem({
                                  rowId,
                                  actionItem: undefined,
                                  showType: ShowActionType.NO_ACTION
                              }))
                          }}
                          refreshTable={() => onRefresh()}
                          closeDialog={() => {
                              // resetOpenModalActionHandler();
                              dispatch(toggleActionItem({
                                  rowId,
                                  actionItem: undefined,
                                  showType: ShowActionType.NO_ACTION
                              }))
                              onRefresh();
                          }}/>
        </>
    } else {
        return <></>
    }
}
