import {createSlice, PayloadAction} from '@reduxjs/toolkit'


// Define a type for the slice state
export interface FlagState {
    open: boolean,
}

// Define the initial state using that type
const initialState: FlagState = {
    open: false,
}

export const lookupModalActionSlice = createSlice({
    name: 'lookupModalActionSlice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleActionModalOpen(state, action: PayloadAction<void>) {
            state.open = !state.open;
        },
        cleanUpFlag(state, action: PayloadAction<void>) {
            state.open = false
        }
    }
})

export const {
    toggleActionModalOpen,
    cleanUpFlag
} = lookupModalActionSlice.actions


export default lookupModalActionSlice.reducer
