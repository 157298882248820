import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../app/hooks";
import {BlastContext} from "../../../providers/blast/BlastContext";
import {SessionContext} from "../../../providers/session/SessionContext";
import {getSettings, performValidation} from "../../../services/ValidationService";
import {getStyle} from "../../../utils/CssUtils";
import {isDefined, isObject} from "../../../utils/TypeCheckers";
import {
    assignReferences,
    buildPathFromRef,
    extractExtraFieldProperties,
    noop,
    RunOnceEffect,
    unSubscribe
} from "../../../utils/Utils";
import {FieldProperties, FormMode} from "../../types";
import {watchBlastDeltas} from "../../ui/FieldUtils";
import FieldLabel from "../field-label/FieldLabel";
import {TableColumnResizeState} from "../gusl-table/guslColumnResizeSlice";
import {InlineEditState} from "../gusl-table/inline-edit/inlineEditSlice";
import {GuslFormState} from "../maintain-form/guslFormSlice";
import {maintainTableService} from "../maintain-table/MaintainTableService";
import {ExtraFieldProperties} from "../money/types";
import {
    FieldContentStyled,
    FieldContentWrapperStyled,
    FloatingFormStyled,
    HintStyled,
    InputStyled,
    TextFieldStyled
} from "./styled";

export const TextField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {
    const sessionContext = React.useContext(SessionContext);
    const blastContext = useContext(BlastContext);
    const navigate = useNavigate();

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);
// MK 17-02-2024
    const inlineEditState: InlineEditState = useAppSelector(state => state.inlineEditSlice[code]);
    const [formMode, setFormMode] = useState(properties.formMode);
    const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || "");
    const [isEdit, setIsEdit] = useState(false);


    const valueRef = useRef(properties?.data);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));

    // if you want ellipsis on text field ... need to pass cellWidth to TextFieldStyled
    const code: string = maintainTableService.getCodeFromUrl();
    const _columnResizeState: TableColumnResizeState = useAppSelector(state => state.guslColumnResizeSlice[code]);

    // MK 29-12-2023
    const headerId: string = "hdr_" + properties.fieldConfig.name + "_" + code;
    const correspondingHeader = document.getElementById(headerId);
    // @ts-ignore
    const cellWidth: number | string | undefined = (correspondingHeader?.offsetWidth || _columnResizeState?.resizedColumns[properties.fieldConfig.name]) - 15;
    const [refreshCounter, setRefreshCounter] = useState<number>(1);


    useEffect(() => {
        setFormValue(_guslFormState?.getFieldValue(properties) || properties.data || "")
    }, [_guslFormState?.counter])

    useEffect(() => {
        if (formMode !== FormMode.NEW) {
            setFormValue(properties?.data || "");
        }

        const [loaderSubscription, blastDeltaSubscription, valueObservable] = watchBlastDeltas(sessionContext, blastContext, properties, formMode);
        const valueSubscription = valueObservable.subscribe((newValue: any) => {
            if (newValue) {
                if (isObject(newValue)) {
                    if (newValue[properties.fieldConfig.name]) {
                        setFormValue(newValue[properties.fieldConfig.name]);
                        setRefreshCounter(refreshCounter + 1);
                    }
                } else {
                    setFormValue(newValue);
                    setRefreshCounter(refreshCounter + 1);
                }
            }
        });
        return () => {
            unSubscribe(loaderSubscription);
            unSubscribe(blastDeltaSubscription);
            unSubscribe(valueSubscription);
        };
    }, [properties]);

    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
        setIsEdit(mode === FormMode.EDIT || mode === FormMode.NEW || mode === FormMode.ACTION_DIALOG);
        setFormValue(properties?.data || "");
    };

    const doValidation = (): boolean => {
        return performValidation(formMode,
            properties.menuItem?.code,
            properties.fieldConfig,
            valueRef?.current?.value,
            setSubmitted,
            setErrorMessage);
    };

    RunOnceEffect(() => {
        assignReferences(properties.reference, onFormModeChange, noop, doValidation);
    });
    type CSSProperties = {
        [key: string]: string;
    };

    // MK 29-12-2023
    // if cellWidth => user resized column => we remove width from style,otherwise if user wants wider column
    // and we leave tableCss width, he won't be able to expand it more... (Logs table -> message for example)
    // Logs table had issue with this
    function removeExcessWidth(style: CSSProperties): CSSProperties {
        const pixelRegex = /(\d+)px/;


        const keysToRemove: string[] = [];
        for (const key in style) {
            if (["width", "minWidth", "maxWidth"].includes(key)) {
                const value = style[key];
                const matches = value.match(pixelRegex);
                if (matches) {
                    const pixelValue = parseInt(matches[1], 10);
                    if (!isNaN(pixelValue) && cellWidth) {
                        keysToRemove.push(key);
                    }
                }
            }
        }

        const updatedStyle = {...style};
        keysToRemove.forEach(key => {
            delete updatedStyle[key];
        });

        return updatedStyle;
    }


    const renderTableView = (): React.ReactElement => {
        const style = removeExcessWidth(getStyle(properties.fieldConfig.tableCss, ""));
        // const style = getStyle(properties.fieldConfig.tableCss, '')

        let prefix = "";
        if (extraFieldProperties.prefix) {
            prefix = extraFieldProperties.prefix;
        }
        let suffix = "";
        if (extraFieldProperties.suffix) {
            suffix = extraFieldProperties.suffix;
        }

        const onFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
            const linkPath = buildPathFromRef(properties?.fieldConfig, properties.rowData);
            if (inlineEditState?.inlineEdit) {
                // allow inline edit
                return
            } else if (linkPath && event) {
                event.stopPropagation();
                if (event["ctrlKey"] || event["altKey"] || event["metaKey"]) {
                    window.open(linkPath);
                } else {
                    navigate(linkPath);
                }
            }
        };

        const hasRef: boolean = isDefined(properties?.fieldConfig?.ref);
        // console.log(style,cellWidth,properties.fieldConfig.name,correspondingHeader?.offsetWidth)
        return (
            <TextFieldStyled textAlign={properties.fieldConfig.align}
                             cellWidth={cellWidth} style={style}
                             hasLink={hasRef}
                             onClick={(e) => {
                                 onFieldClick(e);
                             }}>
                {properties.isNumber &&
                    <div className="text-right" style={style}>{prefix}{formValue}{suffix}</div>}
                {!properties.isNumber &&
                    <div dangerouslySetInnerHTML={{__html: prefix + formValue + suffix}}></div>}
            </TextFieldStyled>
        );
    };

    const renderFormView = (): React.ReactElement => {

        const onDataInputChange = (value: string) => {

            if (properties.isNumber) {
                try {
                    const number = parseInt(value, 10);
                    if ("" + number === value) {
                        setFormValue(value);
                        properties.onChange(properties.fieldConfig.name, parseInt(value, 10));
                    }
                } catch (err) {
                    // log.warn(className, 'WRN001', 'not a number', value);
                }
            } else {
                setFormValue(value);
                properties.onChange(properties.fieldConfig.name, valueRef?.current?.value);
            }
        };

        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        return (
            <>
                {!hideField && <>

                    <FloatingFormStyled id={"ffs_" + properties?.fieldConfig?.name}>
                        <FieldContentWrapperStyled id={"fcws_" + properties?.fieldConfig?.name}
                                                   isEdit={isEdit}
                                                   inLine={properties.inline}>
                            <FieldContentStyled id={"fcs_" + properties?.fieldConfig?.name} formMode={formMode}>
                                <InputStyled type={properties?.fieldConfig?.label === "Password" ? "password" : "text"}
                                             id={properties?.fieldConfig?.name || "def"}
                                             key={properties?.fieldConfig?.name || "def"}
                                             ref={valueRef}
                                             value={formValue}
                                             autoFocus={properties.isFirstField}
                                             readOnly={disableField}
                                             disabled={disableField}
                                             onChange={(e) => onDataInputChange(e.target.value)}
                                             required={required}
                                             submitted={submitted}
                                             noValue={!formValue}
                                             inLine={properties.inline}
                                />
                            </FieldContentStyled>
                            {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                            {submitted && errorMessage &&
                                <small className="yellow">{errorMessage}</small>}
                        </FieldContentWrapperStyled>
                        <FieldLabel properties={properties}/>
                    </FloatingFormStyled>
                </>}
            </>
        );
    };
    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    );

};
