import {format, parseISO} from 'date-fns'
import {enGB} from 'date-fns/locale';

import "react-datepicker/dist/react-datepicker.css";

import 'react-datepicker/dist/react-datepicker.css'
import React, {useContext, useEffect, useRef, useState} from 'react';
import {registerLocale} from 'react-datepicker';
import {useAppSelector} from '../../../app/hooks';
import {SessionContext} from '../../../providers/session/SessionContext';
import {log} from '../../../services/LogService';
import {getSettings, shouldHide, validate} from '../../../services/ValidationService';
import {isDate, notDefined} from '../../../utils/TypeCheckers';
import {extractExtraFieldProperties, formatDate, getDateFormat, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {FieldProperties, FormMode} from '../../types';
import FieldLabel from '../field-label/FieldLabel';
import {GuslFormState} from '../maintain-form/guslFormSlice';
import {ExtraFieldProperties} from '../money/types';
import {FieldContentStyled, FieldContentWrapperStyled, FloatingFormStyled, HintStyled} from '../text/styled';
import {BROWSER_TIMEZONE} from '../timezone/TimezoneField';
import {DateFloatingFormStyled, DatePickerStyled, DateStyled} from './styled';


export const DateField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [className] = React.useState<string>(() => 'DateField-' + new Date().getTime());

    const sessionContext = useContext(SessionContext);

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);

    const [formMode, setFormMode] = useState(properties.formMode);
    const valueRef = useRef(properties?.data);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const [extraFieldProperties] = useState<ExtraFieldProperties>(() => extractExtraFieldProperties(properties));
    const [timezone, setTimezone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [showTime] = useState<boolean>((properties.fieldConfig.dateFormat || '').indexOf(':mm') > 0);
    const [formValue, setFormValue] = useState<Date | null>((_guslFormState?.getFieldValue(properties) || properties.data) ? new Date((_guslFormState?.getFieldValue(properties) || properties.data) || '') : null);
    const [dateFormat, setDateFormat] = useState<string>(() => getDateFormat(properties?.fieldConfig?.dateFormat, extraFieldProperties?.timeZone || timezone, false));

    const [localeLoaded, setLocaleLoaded] = useState<boolean>(false);

    const extractTableValue = (_timezone?: string) => {
        if ((_guslFormState?.getFieldValue(properties) || properties?.data)) {
            try {
                const useTimezone: string = extraFieldProperties?.timeZone || _timezone || timezone
                return formatDate(parseISO((_guslFormState?.getFieldValue(properties) || properties?.data) || ''), properties.fieldConfig.dateFormat || 'DD-MM-YYYY', useTimezone)
            } catch (err) {
                console.error('-- error --', err)
                log.error(className, 'ERR001', 'Failed to parse date', err)
                return format(parseISO((_guslFormState?.getFieldValue(properties) || properties?.data) || ''), 'dd-MM-yyyy HH:mm')
            }
        }
        return '';
    }
    const [tableValue, setTableValue] = useState<string>(() => extractTableValue());

    useEffect(() => {
        extractTableValue()
    }, [_guslFormState?.counter])


    RunOnceEffect(() => {
        registerLocale('en-GB', enGB)
        setLocaleLoaded(true)

        let timezoneSubscription = sessionContext.watchTimezone().subscribe((changedTimezone: string) => {
            let _timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (changedTimezone !== BROWSER_TIMEZONE) {
                _timezone = changedTimezone
            }
            setTimezone(_timezone)
            setTableValue(extractTableValue(_timezone))
            setRefreshCounter(refreshCounter + 1)
            setDateFormat(getDateFormat(properties?.fieldConfig?.dateFormat, _timezone))

        });
        return () => {
            unSubscribe(timezoneSubscription);
        }
    });

    const fixDateFormatting = (dateFormat: string): string => {
        if (notDefined(dateFormat)) {
            return 'dd-MM-yyyy HH:mm'
        }
        return dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('YY', 'yy')
    }

    useEffect(() => {
        setRefreshCounter(refreshCounter + 1)
        // setFormValue(properties?.data ? new Date(properties?.data || '') : null)
        setFormValue((_guslFormState?.getFieldValue(properties) || properties?.data) ? new Date(_guslFormState?.getFieldValue(properties) || properties?.data || '') : null)
        setTableValue(extractTableValue())
    }, [properties])


    const onFormModeChange = (mode: FormMode) => {
        setFormMode(mode);
    }

    const onRefresh = () => {
    }

    const doValidation = (fieldValue: Date | null): boolean => {
        setSubmitted(true);
        setErrorMessage(undefined);
        const hideField = shouldHide(formMode, properties.fieldConfig, fieldValue);
        if (!hideField) {
            // @ts-ignore
            const val = isDate(fieldValue) ? fieldValue.toISOString() : fieldValue
            const message = validate(properties.menuItem?.code,
                properties.fieldConfig.name,
                properties.fieldConfig.validators,
                'date',
                val)
            // valueRef?.current?.value);
            if (message) {
                setErrorMessage(message);
                return false;
            }
        }
        return true;
    }

    RunOnceEffect(() => {
        // init
        properties.reference.changeMode = onFormModeChange;
        properties.reference.doRefresh = onRefresh;
        properties.reference.doValidation = doValidation;
        properties.reference.register(properties.reference);
        return () => {
            // clean up
        }
    });

    function onInputChange(date: Date | null) {
        if (date) {
            if (!showTime) {
                const baseDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                setFormValue(baseDate);
                properties.onChange(properties.fieldConfig.name, baseDate);
            } else {
                setFormValue(date);
                properties.onChange(properties.fieldConfig.name, date.toISOString());
            }
        } else {
            setFormValue(null);
            properties.onChange(properties.fieldConfig.name, undefined);
        }
    }

    const renderTableView = (): React.ReactElement => {

        let prefix = '';
        if (extraFieldProperties.prefix) {
            prefix = extraFieldProperties.prefix
        }
        let suffix = '';
        if (extraFieldProperties.suffix) {
            suffix = extraFieldProperties.suffix
        }
        return (
            <span key={'dt_' + properties.fieldConfig.name + '_' + refreshCounter}>{prefix}{tableValue}{suffix}</span>

        )
    }

    const renderFormView = (): React.ReactElement => {
        const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

        if (hideField) {
            return <></>
        }
        if (formMode === FormMode.VIEW || disableField) {
            return <>
                <FloatingFormStyled key={'id_' + refreshCounter}>
                    <FieldContentWrapperStyled>
                        <FieldContentStyled>
                            <DateStyled submitted={submitted}
                                        noValue={!formValue}>
                                {renderTableView()}
                            </DateStyled>
                        </FieldContentStyled>
                        {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    </FieldContentWrapperStyled>
                    <FieldLabel properties={properties}/>
                </FloatingFormStyled>
            </>
        }

        return (
            <DateFloatingFormStyled>
                <FieldContentWrapperStyled>
                    <FieldLabel properties={properties}/>
                    <FieldContentStyled>
                        {localeLoaded && <DatePickerStyled
                            locale="en-GB"
                            submitted={submitted}
                            showTimeSelect={showTime}
                            noValue={!formValue}
                            selected={formValue}
                            dateFormat={dateFormat}
                            onChange={(date: Date | null) => onInputChange(date)}
                        />}
                    </FieldContentStyled>
                    {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    {submitted && errorMessage &&
                        <small className="error-message">{errorMessage}</small>}
                </FieldContentWrapperStyled>
            </DateFloatingFormStyled>
        )
    }

    return (
        <>
            {properties.isTableView ? renderTableView() : renderFormView()}
        </>
    )

}
