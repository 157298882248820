import styled, {ThemeProps} from 'styled-components';
import {CONTENT_START_POSITION, HEADER_HEIGHT, TAB_HEIGHT} from '../../../providers/theme/GuslThemeProvider';

export const BlankLineStyled = styled.div.attrs((props) => ({}))`
  height: 50px;
`

export const DisclaimerStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.columnColor};
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 10px 40px 10px;
`
export const DisclaimerHeaderStyled = styled.span.attrs((props) => ({}))`
  color: ${props => props.theme.panel.titleColor};
  font-size: 12px;
  font-weight: bold;
  padding-left: 60px;
`
export const DisclaimerHeaderDivStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.panel.titleColor};
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px;
`

export const BuySellPanelStyled = styled.div.attrs((props) => ({
    // className: 'p-3'
}))`
    // background-color:${props => props.theme.modal.innerBackground} ;
  padding-bottom: 20px;

`

interface BuySellBodyProperties {
    isMobile: boolean;
    startPos: number
    footerHeight: number;
}

/*
col-lg-8
 */
export const BuySellBodyStyled = styled.div.attrs((props: BuySellBodyProperties) => ({
    className: 'col-12 mx-auto mt-3'
}))<BuySellBodyProperties>`
  overflow: auto;
  height: ${props => {
    if (!props.isMobile) {
      return '100%'
    }
    return 'calc(var(--internal-height) - env(safe-area-inset-bottom) - ' + (props.startPos + props.footerHeight) + 'px)'
  }};
`

interface InputStyledProps extends ThemeProps<any> {
}

export const InputStyled = styled.input.attrs((props: InputStyledProps) => ({
    className: "form-control form-control-sm bg-transparent rounded-0 border-top-0 border-start-0 border-end-0 text-light "
}))<InputStyledProps>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`

export const ErrorStyled = styled.p.attrs((props) => ({
    className: 'small invalid'
}))`
`

export const DisplayContainerStyled = styled.div.attrs((props) => ({
    className: 'row g-0 d-flex '
}))`
    @media only screen and (min-width: 500px) {
        margin-left: 50px;
        width: 500px;
    }
    
`
export const ErrorPanelStyled = styled.div.attrs((props) => ({
className: 'invalid small text-center p-3'
}))`
    @media only screen and (min-width: 500px) {
        margin-left: 50px;
        width: 500px;
    }

`

interface DisplayFieldPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean
}

export const DisplayFieldPanelStyled = styled.div.attrs((props: DisplayFieldPanelStyledProps) => ({
    className: "mb-3 " + (props.isMobile ? 'col-12' : 'col-6')
}))<DisplayFieldPanelStyledProps>`
    max-width: 400px;
    text-align: center;
    margin: 0 auto;

    line-height: 25px;
    margin-top: 20px;
    padding: 0 15px 0 10px;
    background-color: ${props => props.theme.colors.background[0]};
    border-radius: 3px;
`

export const DisplayFieldStyled = styled.div.attrs((props) => ({
    className: 'd-flex justify-content-between text-light'
}))`
    // border-bottom:  ${props => props.theme.form.fieldBorder} !important;
`

export const DisplayLabelStyled = styled.span.attrs((props) => ({}))`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`

export const DisplayValueStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`

interface ActionButtonPanelStyledProps extends ThemeProps<any> {
    isMobile: boolean;
    side?: string;
    canShowInfo: boolean;
    tickerId: string;
}

export const ActionButtonPanelStyled = styled.div.attrs((props: ActionButtonPanelStyledProps) => ({
    className: 'align-items-center justify-content-evenly ' +
        'col-' + (props.side && !props.isMobile && props.canShowInfo && (props.tickerId) ? '6' : '12')
}))<ActionButtonPanelStyledProps>`
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};
`

export const ActionButtonsStyled = styled.div.attrs((props) => ({
    className: 'd-flex align-items-center justify-content-evenly'
}))`
`

export const ActionButtonStyled = styled.div.attrs((props) => ({
    className: 'p-1 mx-1 border'
}))`
`

interface BuySellSelectionPanelProps extends ThemeProps<any> {
    tickerId: string;
}

export const BuySellSelectionPanelStyled = styled.div.attrs((props: BuySellSelectionPanelProps) => ({
    className: 'row g-0 d-flex align-items-center justify-content-evenly pb-2 '
}))<BuySellSelectionPanelProps>`
        // border-bottom:  ${props => props.theme.form.fieldBorder} !important;
    display: ${props => (!props.tickerId || props.tickerId === 'undefined') ? 'none !important' : 'inherit'};
    padding-top: 5px;

    @media only screen and (min-width: 500px) {
        margin-left: 90px;
        width: 500px;
    }

`

interface CloseOrderSelectionPanelStyledProps extends ThemeProps<any> {
    side?: string;
    isMobile: boolean
}

export const CloseOrderPriceStyled = styled.span.attrs((props) => ({}))`
  font-weight: bold;
  font-size: 22px;
`

export const LookupWrapperStyled = styled.div.attrs((props) => ({}))`
  line-height: 30px;

  .form-control:not(.lookup):not(.cron) {
    width: 200px;
    padding-left: 0px;
    margin-left: 7px
  }

  .form-floating > .form-control:not(:placeholder-shown) ~ label {
    display: none;
  }

  .form-floating > .form-control:not(:placeholder-shown) {
    padding: 0px;
  }
`

export const CloseOrderSelectionPanelStyled = styled.div.attrs((props: CloseOrderSelectionPanelStyledProps) => ({
    className: ' col-4 mb-3 border mx-auto p-2 text-light text-center  '
}))<CloseOrderSelectionPanelStyledProps>`
  background-color: ${props => props.side === 'BUY' ? (props.theme?.colors?.bespoke[0] || props.theme.money.positiveColor) : (props.theme?.colors?.bespoke[1] || props.theme.money.negativeColor)};
    // border-bottom:  ${props => props.theme.form.fieldBorder} !important;

  width: ${props => props.isMobile ? '80vw' : '200px'};
  line-height: 30px;
`

interface BuySellProps extends ThemeProps<any> {
    side?: string;
    tickerId: string;
}

export const SellButtonStyled = styled.div.attrs((props: BuySellProps) => ({
    className: 'col-4 border text-center '
}))<BuySellProps>`
    color: ${props => props.side === 'SELL' ? '#ffffff' : props.theme.table.headerColor} !important;
    background-color: ${props => props.side === 'SELL' && (props.tickerId) ? (props.theme?.colors?.bespoke[1] || props.theme.money.negativeColor) : 'inherit'};
    width: 48%;
    margin: 3px;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    max-width: 200px;
    @media only screen and (min-width: 768px) {
        vertical-align: middle;
        margin: 0 auto;
        line-height: 50px;
    }

`

export const BuyButtonStyled = styled.div.attrs((props: BuySellProps) => ({
    className: ' col-4 border text-center '
}))<BuySellProps>`
  color: ${props => props.side === 'BUY' ? '#ffffff' : props.theme.table.headerColor} !important;
  background-color: ${props => props.side === 'BUY' && (props.tickerId) ? (props.theme?.colors?.bespoke[0] || props.theme.money.positiveColor) : 'inherit'};
  width: 48%;
  margin: 3px;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  max-width:200px;
    @media only screen and (min-width: 768px) {
        vertical-align: middle;
        margin: 0 auto;
        line-height: 50px;
    }
    
`

export const OrderTypeWrapperStyled = styled.div.attrs((props) => ({}))`
  padding: 10px 0px 0px 5px;
  color: ${props => props.theme.table.columnColor} !important;
  font-size: ${props => props.theme.table.columnFontSize};

`

export const RadioButtonContainerStyled = styled.div.attrs((props) => ({}))`
  padding-top: 5px;

  :last-child {
    padding-top: 10px;
  }

  display: flex;
`

interface RadioButtonProperties extends ThemeProps<any> {
    active: boolean;
}

export const RadioButtonIconStyled = styled.i.attrs((props: RadioButtonProperties) => ({
    className: props.active ? 'fa-regular fa-circle-dot' : 'fa-regular fa-circle'
}))<RadioButtonProperties>`
  font-size: 16px;
  line-height: 30px;
`

export const RadioButtonLabelStyled = styled.div.attrs((props) => ({}))`
  padding-left: 10px;
  width: 80px;
  line-height: 30px;
`

export const QuantityWrapperStyled = styled.div.attrs((props) => ({}))`
  padding: 10px 0px 0px 5px;
`

export const InputWrapperStyled = styled.div.attrs((props) => ({}))`
  line-height: 30px;
`
export const SymbolWrapperStyled = styled.div.attrs((props) => ({}))`
  line-height: 52px;
`


export const SymbolLabelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
  min-width: 100px;
  line-height: 52px;
  padding-left: 5px;
`

export const QuantityLabelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
  min-width: 100px;
  line-height: 40px;
`

export const LimitPriceLabelStyled = styled.div.attrs((props) => ({}))`
  color: ${props => props.theme.table.headerColor};
  font-size: ${props => props.theme.table.headerFontSize};
  min-width: 100px;
  line-height: 30px;
  vertical-align: middle;
  margin: auto;
`


export const InlineInputStyled = styled.div.attrs((props) => ({}))`
  display: flex;

  input[type='number'] {
    height: 30px !important;
    border-style: none;
    border-bottom: ${props => props.theme.form.fieldBorder} !important;
    width: 200px;
  }
`

interface BuySellPanelFormWrapperProperties {
    isMobile?: boolean;
    isTradePanel?: boolean;
    height?: string
    isEdit?: boolean;
    isIOS: boolean;
    footerHeight: number;

}

export const BuySellPanelFormWrapper = styled.div.attrs((props: BuySellPanelFormWrapperProperties) => ({
    className: ""
}))<BuySellPanelFormWrapperProperties>`

  padding: ${props => props.isMobile ? '0px 3px 0px 3px' : '0 20px 0 20px'};
  overflow: auto;
  max-height: ${props => {
    if (props.isTradePanel && props.isMobile) {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) + 'px)'
    } else if (!props.isTradePanel && props.isMobile) {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) + 'px)'
    } else if (props.height && props.isEdit) {
      return 'calc(' + props.height + ' - 120px);'
    } else if (props.height) {
      return 'calc(' + props.height + ' - 55px);'
    } else if (props.isMobile) {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (props.footerHeight + HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION) + 'px)'
    } else if (props.isEdit) {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION + 22) + 'px)'
    } else {
      return 'calc(var(--internal-height) - env(safe-area-inset-bottom) -  ' + (HEADER_HEIGHT + TAB_HEIGHT + CONTENT_START_POSITION - 45) + 'px)'
    }
  }};

  height: 100%;

  .form-control:not(.lookup):not(.cron) {
    border: none;
    box-shadow: none !important;
  }

  .shadow {
    box-shadow: none !important;
  }
`
