import React from 'react';
import {RouteObject} from 'react-router-dom';
import {HomePage} from '../components/bespoke/home-page/HomePage';
import {BuySellFormOrig} from '../components/common/buy-sell-form/BuySellFormOrig';
import TradingCentralPage from "../components/common/trading-central/TradingCentralPage";
import {NotFoundPage} from '../pages/common/NotFoundPage';
import {GeoRestrictedPage} from '../pages/geo-restricted/GeoRestrictedPage';
import {Login} from '../pages/login/Login';
import {LoginPage} from '../pages/login/LoginPage';
import { BuySellForm } from "../components/common/buy-sell-form/BuySellForm";
import OttoEvents from "../components/bespoke/otto-events/OttoEvents";

export const DEFAULT_PATH = '/';

export const UI_CONSTANTS: { [id: string]: string } = {
    BASE_PATH: DEFAULT_PATH,
    LOGIN: 'login',
    LOGIN_PATH: DEFAULT_PATH + 'login',
    NOT_FOUND: 'notfound',
    NOT_FOUND_PATH: DEFAULT_PATH + 'notfound',
    LEGAL: 'legal',
    LEGAL_PATH: DEFAULT_PATH + 'legal',
    RESTRICTED: 'restricted',
    RESTRICTED_PATH: DEFAULT_PATH + 'restricted',
}

// note: e.g. incident is CODE from MenuItemBO - so need to change both
export const PRE_AUTH_PAGES_MAP: { [id: string]: RouteObject[] } = {
    'login': [{
        path: UI_CONSTANTS.LOGIN_PATH,
        element: <LoginPage/>
    }],
    'notfound': [{
        path: UI_CONSTANTS.NOT_FOUND_PATH,
        element: <NotFoundPage/>
    }],
    'restricted': [{
        path: UI_CONSTANTS.RESTRICTED_PATH,
        element: <GeoRestrictedPage/>
    }],
}

export const BESPOKE_PAGES_MAP: { [id: string]: RouteObject[] } = {

    'TradingCentral': [{
        path: UI_CONSTANTS.BASE_PATH + 'bespoke/TradingCentral', element: <TradingCentralPage/>
    }],
    'BuySellForm': [{
        // path: UI_CONSTANTS.BASE_PATH + 'bespoke/BuySellForm', element: <BuySellForm key={'default'} isTradePanel/>
        path: UI_CONSTANTS.BASE_PATH + 'bespoke/BuySellForm', element: <BuySellFormOrig key={'default'} isTradePanel/>
    }],
    'BuySellFormWithTicker': [{
        path: UI_CONSTANTS.BASE_PATH + 'bespoke/BuySellForm/:tickerId/:action',
        // element: <BuySellForm key={"withTicker"} isTradePanel/>
        element: <BuySellFormOrig key={"withTicker"} isTradePanel/>
    }],
    'HOME': [{
        path: UI_CONSTANTS.BASE_PATH + 'bespoke/home', element: <HomePage key={'home'}/>
    }],
    // 'otto': [{
    //     path: UI_CONSTANTS.BASE_PATH + 'bespoke/otto/:dealId/:articleId', element: <OttoEvents/>
    // }]
}
