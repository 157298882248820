import React, {KeyboardEvent} from "react";
import {CronType, PeriodType, ShortcutsType} from "react-js-cron";
import {RangeQueryDO} from "./charts/types";
import {CardBO} from './common/card/types';
import {GlobalSearchDTO} from './common/global-search/type';
import {DateRangeUnitDO} from "./common/gusl-table/filters/types";
import {GuslTableState} from "./common/gusl-table/guslTableSlice";
import {TableRowState} from "./common/maintain-table/table-row/tableRowSlice";
import {RadialMeterDTO} from "./common/radial-meter/types";
import {ReportDO} from "./common/report/types";

export enum LayoutType {
    APOLLO = "apollo",
    MERCURY = "mercury",
    SUPERNOVA_FE = "supernova-fe",
    ALVAR_FE = "alvar-fe",
    MOBILE = "mobile",
    JUPITER = "jupiter",
}

export enum FormMode {
    VIEW,
    EDIT,
    NEW,
    DELETE,
    ACTION_DIALOG,
    VIEW_WITH_ACTION
}

export interface ChildReference {
    name: string;
    displayOrder: number;
    doRefresh?: () => void;
    changeMode?: (mode: FormMode) => void;
    doValidation?: (fieldValue: any) => boolean;
    register: (childReference: ChildReference) => void;
    fieldType?: string;
    formSaved?: (rowData: any) => void;
}

export interface FieldProperties {
    code: string;
    formMode: FormMode;
    fieldConfig: FieldConfigDTO;
    menuItem: IMenuDTO | undefined;
    isTableView?: boolean;
    data?: any;
    isNumber?: boolean;
    hasLabel?: boolean;
    isFirstField?: boolean;
    chartsData?: ChartPanelDTO[];
    chartData?: ChartPanelDTO;
    radialMeterData?: RadialMeterDTO;
    reportData?: ReportDO;
    rowData?: any;
    // table view only
    parameters?: any;

    onSubmitted: () => void;
    onChange: (name: string, value: any, overwrite?: boolean, panelName?: string | undefined) => void;
    getCurrentRowData: () => any;
    reference: ChildReference;
    allowedPeriods?: PeriodType[];
    allowedDropdowns?: CronType[];
    shortcuts?: ShortcutsType[];

    /** only for lookup field*/
    setTicker?: Function;
    reset?: number; // timestamp
    onReset?: () => void;

    inline?: boolean;

    isDialog?: boolean;
    reLoad?: () => void;

    onArrayChange?: ((parentName: string, childName: string, value: any, rowIndex: number) => void) | undefined;

    uniqueId?: string;

    tableCode?: string;

    tableState?: GuslTableState;

    tableRowState?: TableRowState;
    counter?:number,
    panelName?: string

}

export interface ActionCompletedBO {
    failed: boolean,
    hideNotification: boolean,
    reloadTemplate: boolean,
    notificationMessage: string,
    report?: ReportDO
    table?: ActionResponseTableDO
}

export interface DecimalDO {
    v: number;
    p: number; // decimal places
}

export interface IOriginalEvent {
    originalEvent:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLElement>
        | React.MouseEvent<HTMLLIElement>
        | KeyboardEvent;
    item: IMenuDTO;
}

export interface ITopbarEvent {
    originalEvent:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLElement>
        | React.MouseEvent<HTMLLIElement>
        | KeyboardEvent;
    item: string;
}

// export interface IMenuItem {
//     label: string;
//     disabled?: boolean;
//     icon?: string,
//     badge?: string;
//     to?: string;
//     url?: string;
//     target?: string;
//     badgeStyleClass?: string;
//     class?: string;
//     command?: (originalEvent: IOriginalEvent) => void;
//     items?: IMenuItem[];
// }

export interface ISystemDTO {
    menuGroups: IMenuDTO[];
    forms?: FormDTO[];
    charts?: {
        [key: string]: ChartDO
    };
    cohorts?: CohortDO[];
    toolbarItems?: ToolbarItemDTO[];
    toolbarTickers?: ToolbarTickerDTO[];
    toolbarMenus?: IMenuDTO[];
    lookups?: LookupConfigDTO[];
    pageSizes?: number[];
    actions?: ActionConfigDTO[];
    toolbarActionData?: any;
    widgets?: WidgetDO[];
}


export interface IMenu {
    code: string;
    label: string;
    icon: string;
    route: string;
}

export interface CreateFormConfigDTO {
    rows: FormRowConfigDTO[];
    title?: string;
    insertUrl?: EndpointDTO;
    templateUrl?: EndpointDTO;
    jsEditCondition?: string;
    multipartForm?: boolean;
    permission?: string;
}


export interface IMenuDTO {
    label: string;
    alwaysOpen?: boolean;
    displayOrder: number;
    code?: string;
    menuItems?: IMenuDTO[];
    help?: string;
    selectUrl?: string;
    menuType?: MenuType;
    entityGroup?: EntityGroupConfigDTO;
    singleForm?: SingleFormDTO;
    singlePage?: SinglePageDTO;
    action?: ActionConfigDTO;
    columnBasedLayout?: boolean;
    withTimestamp?: boolean;
    refreshRate?: number;
    footer?: boolean;
    overlay?: boolean;

    showInSideBar?: boolean;

    mobileTopSticky?: boolean;

    tabGroup?: string;
    disabled?: boolean;
    icon?: string;
    iconOnly?: boolean;
    badge?: string;
    to?: string;
    route?: string;
    align?: string;
    url?: string;
    target?: string;
    badgeStyleClass?: string;
    class?: string;
    // command?: (originalEvent: IOriginalEvent) => void; // <== AppSubMenu
    command?: string; // <== MainToolbarDropdown
    commandFunction?: (event: React.MouseEvent, item: IMenuDTO) => void;
    downloadFunction?: (action?: ActionConfigDTO) => void;
    actionOnlyFunction?: (action?: ActionConfigDTO) => void;
    // UI only
    isOpen?: boolean;
    tooltip?: string;
    externalUrl?: string;
    favQueries?: FavQueriesDTO;
    createForm?: CreateFormConfigDTO;

    mediaType?: MediaType;

    noLabel?: boolean;
    // MK 25-02-2024 inline edit
    updateMany?: string | undefined;
    insertMany?: string | undefined;
    globalSearch?: GlobalSearchDTO;

    fixedHeight?: number;
    // blastDeltaCommand?: string;
    // MK 02-10-2024
    infiniScroll?:boolean | undefined;
}

export interface WidgetPanelProperties {
    width: number,
    height: number,
    x: number,
    y: number,
    noTableControls: boolean,
    noPagination: boolean
}

export interface BaseWidgetChildDO {
    selectUrl?: string,
    settings?: FormRowConfigDTO[],
    templateUrl?: string,
    updateUrl?: string,
    menuActions?: ActionConfigDTO [],
    footerActions?: ActionConfigDTO []
    data?: any,
    refreshRate?: number
}

export interface WidgetChartDO extends BaseWidgetChildDO {
    charts: ChartDTO[]
}

export interface WidgetReportDO extends BaseWidgetChildDO {
    report?: ReportDO,
    reportUrl?: string,
}

export interface WidgetCardDO extends BaseWidgetChildDO {
    card?: CardBO
}

export interface WidgetHtmlDO extends BaseWidgetChildDO {
    externalCssUrls?: string [];
    externalJsUrls?: string [];
    js?: string;
    style?: string;
}

export interface WidgetIFrameDO {
    src: string;
    title: string;
    refreshRate?: number
}

export interface WidgetHtmlResponseDO {
    content: string,
    queryParams?: QueryParamsDTO,
    uniqueId?: string
    refreshRate?: number
}

export interface WidgetSchedulerDO {
    eventsUrl: string;
    title: string;
    hideMonth: boolean;
    hideWeek: boolean;
    hideDay: boolean;
    view: 'month' | 'week' | 'day';
    refreshRate?: number
}

export interface ScheduledEventDO {
    event_id: string,
    title: string,
    allDay: boolean,
    start: string,
    end: string,
    color: string,
    textColor: string,
    editable: boolean,
    deletable: boolean,
    draggable: boolean,
    disabled: boolean,
    agendaAvatar: string
}

export interface WidgetHtmlResponseDO {
    content: string,
    queryParams?: QueryParamsDTO,
    uniqueId?: string
    events?: ScheduledEventDO[]
}

export interface WidgetDO {
    i: string,
    label: string,
    code: string,
    permission: string,
    mediaType: MediaType,
    maxInstances?: number,
    x?: number,
    y?: number,
    w?: number,
    h?: number,
    minW?: number,
    maxW?: number,
    minH?: number,
    maxH?: number,
    open?: boolean,
    draggable?: boolean,
    resizable?: boolean,
    bounded?: boolean,
    action?: ActionConfigDTO,
    chart?: WidgetChartDO,
    report?: WidgetReportDO,
    card?: WidgetCardDO,
    html?: WidgetHtmlDO,
    iframe?: WidgetIFrameDO,
    scheduler?: WidgetSchedulerDO,
    scale?: number,
    noTableControls: boolean,
    noPagination: boolean,

    // UI only
    instanceId: number
    // refreshCounter: number
}

export interface MenuItemBadgeMessageBO {
    color: string;
    backgroundColor: string;
    value: number;
    menuCode?: string;
    tooltip?: string;
}

export interface LookupChange {
    field: string;
    value: any;
}

export enum MenuType {
    MULTIPLE = "MULTIPLE",
    MAINTAIN = "MAINTAIN",
    ENTITY_GROUP_SINGLE_FORM = "ENTITY_GROUP_SINGLE_FORM",
    ENTITY_GROUP = "ENTITY_GROUP",
    SINGLE_PAGE = "SINGLE_PAGE",
    ELASTIC = "ELASTIC",
    CASSANDRA = "CASSANDRA",
    DATA_TABLE = "DATA_TABLE",
    DASHBOARD = "DASHBOARD",
    TICKER = "TICKER",
    ACTION_ITEM = "ACTION_ITEM",
    BLAST_COLLECTION_TABLE = "BLAST_COLLECTION_TABLE",
    BLAST_COLLECTION_FORM = "BLAST_COLLECTION_FORM"
}

// export interface ChartDTO {
//     label: string;
//     menuItems: MenuDTO[];
// }
//
// export interface ChartOptionsDO {
//     animation: boolean;
//     scaleOverride: boolean;
//     scaleSteps: number;
//     scaleStepWidth: number;
//     scaleStartValue: number;
// }

export interface ChartDatasetDO {
    datasetIndex: number;
    id: number;
    chartKey: string;
    key: string;
    label: string;
    fontColor: string;
    backgroundColor: string[] | string;
    borderColor: string[] | string;
    borderWidth: number[] | number;
    hoverBackgroundColor: string[];
    hoverBorderColor: string[];
    hoverBorderWidth: number[];
}

export interface ChartCategoryDO {
    categoryKey: string;
    label: string;
    rank: number;
}

export interface PieChartOptionsDO {
    cutoutPercentage: number;
    legend: any;
}

export interface ChartDO {
    id: number;
    key: string;
    title: string;
    type: string;
    data: any;
    datasets: any;
    labels: any;
    chartDatasets: ChartDatasetDO[];
    chartCategories: ChartCategoryDO[];
    options: any;
    pieOptions: PieChartOptionsDO;
    width: number;
    height: number;
    chart: any; // the actual chart
}

// export interface ChartDatasetDataDO {
//     id: number;
//     datasetId: number;
//     rank: number;
//     value: number;
// }

export interface ToolbarItemDTO {
    code: string;

    routerLink: string;
    modal: string;
    tooltip: string;
    icon: string;
    leftSeparator: boolean;
    displayOrder: number;
    second: boolean;
}

export interface ToolbarTickerDTO {
    code: string;
    icon: string;
    displayOrder: number;
    leftSeparator: boolean;
    tooltip: string;
}

export interface LookupConfigDTO {
    name: string;
    // extractLabel: (any) => string;
    extractLabel: string;
    tableFields: string[];
    sortBy: string;
    pageable: boolean;
    inline: boolean;
    // retValue?: (any) => any;
    retValue?: string;
    filterRetValue?: (any: any) => any;
    fieldTypes?: string[];
    externalUrl?: string; // {data} is content of lookup, {label} is label
    uniqueId?: string;
    extractImage?: string;
    showImage?: boolean;
    defaultSelectUrl?: string;
    fields?: FieldConfigDTO[];

    // uniqueId?: ((a1: any) => any);
}

export interface QuickEntryCommandResponseBO {
    routerLink?: string;
    entryModal?: string;
    errorMessage?: string;
    data?: string;
}

export interface FormDTO {
    code: string;
    title: string;
    updateUrl: string;
    rowConfig: FormRowConfigDTO;
}

export interface SelectConditionDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
}

export interface SelectDeeplinkDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
}

export interface SelectBundleDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
}

export interface SelectRuleDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
}

export interface SelectOfferDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
}

export interface SelectSmartFilterDO {
    id: number;
    name: string;
    formConfig: FormRowConfigDTO[];
    displayOrder: number;
    dataTemplate: any;
    description: string;
}

export interface MaintainTabDTO {
    endpoints?: EndpointsConfigDTO;
    tableView: TableViewConfigDTO;
    initialConditions?: RowConditionDTO[];
}

export interface OrderByActionDO {
    field: string;
    order: string;
    ascIcon: string;
    descIcon: string;
    mediaType: MediaType;
}

export interface FilterActionControlDO {
    filterActions: FilterActionDO[];
    includeFavQueries?: boolean;
}

export interface FilterActionDO {
    label: string;
    icon: string;

    route: string;

    queryParams: QueryParamsDTO;
    mediaType: MediaType;

    displayOrder?: number;
}

export interface MaintainConfigDTO {
    id?: string;
    tabs: MaintainTabDTO[];
    rows: FormRowConfigDTO[];
    restActions?: ActionConfigDTO[];
    overallRestActions?: ActionConfigDTO[];
    selectUrl: string;
    tabsWithCount: boolean;
    countUrl: string;
    title: string;
    collectionName?: string;

    rowActions?: ActionConfigDTO[];

    groupActions?: ActionConfigDTO[];

    orderByActions?: OrderByActionDO[];

    tableActions?: ActionConfigDTO[];
    blastDeltaCommand?: string;
    highlightDelta?: boolean;
    disableTableControls?: boolean;

    disableColumnResize?: boolean;

    refreshRate?: number;

    tableControl?: TableControlsDTO;

    alphaList?: boolean;

    accordionList?: boolean;
    flexList?: boolean;
    defaultSortString?: string;
    panels?: PanelConfigDTO[];
}

export interface TableControlsDTO {
    pagination: MediaType;
    resize: MediaType;
    filters: MediaType;
    search: MediaType;
    refresh: MediaType;
    columnSettings: MediaType;
    infinityScroll: boolean
}


export interface TickerConfigDTO extends MaintainConfigDTO {
    commands: TickerCommandDTO[];
    code: string;
    hideCohort: boolean;
    hideLock: boolean;
    hideFilter: boolean;
    hideRefresh: boolean;
    hideHeader: boolean;
    hidePagination: boolean;
    showAdd: boolean;
    showPopout: boolean;
    popoutWidth: number;
    popoutHeight: number;
}

export interface ElasticConfigDTO extends MaintainConfigDTO {
    withTimestamp: boolean;
    convertTo: string;
    indexClass: string;
    indexName: string;
    elasticFilterGroup: string;
    includeCohortCondition: boolean;
}

export interface CassandraConfigDTO extends MaintainConfigDTO {
    convertTo: string;
    selectUrl: string;
}

export interface DashboardConfigDTO {
    title: string;
    columns: FormColumnConfigDTO[];
}

export interface EndpointsConfigDTO {
    selectAllUrl: string;
    selectOneUrl?: string;
    insertUrl?: string;
    updateUrl?: string;
    deleteUrl?: string;
}

export interface FilterConfigDTO {
    name: string;
    validators: UiValidator[];
    value: string;
}

export enum UiValidatorType {
    NOT_NULL = "NOT_NULL",
    MIN = "MIN",
    MAX = "MAX",
    MIN_LENGTH = "MIN_LENGTH",
}

export interface UiValidator {
    type: UiValidatorType;
    longValue?: number;
}

export interface RowConditionDTO {
    field: string;
    value: string;
    operand: QueryOperand;
}

export interface ConditionalRowDetailsDTO {
    conditions: RowConditionDTO[];
    url: string;
    expandUrl: string;
    fields: FormRowConfigDTO[];
    expandable: boolean;
    expand: boolean;
    grouped: boolean;
    detailsButton: boolean;
    table?: boolean;
    report?: boolean;
    form?: boolean;
    tableWidth?: string;
    tableNoHeader?: boolean;
    tableExpand?: boolean;
    tableExpandRowUrl?: string;
    tableExpandConfig?: FormRowConfigDTO;
    actions: ActionConfigDTO[] | undefined;
    disableTableControls?: boolean;
    disableColumnResize?: boolean;
}

export interface CascadingTableBO {
    content: any[];
    fields: FormRowConfigDTO[];
    tableWidth: string;
    noHeader: boolean;
    expand: boolean;
    report: boolean;
    expandUrl: string;
    actions: ActionConfigDTO[];
}

export interface SortDTO {
    field: string;
    direction: string;
}

export interface TableViewConfigDTO {
    title?: string;
    add?: AddConfigDTO;
    actions?: TableActionsConfigDTO[];
    rowsPerPageOptions?: number[];
    code?: string;
    filters?: FieldConfigDTO[];
    conditionals?: ConditionalRowDetailsDTO[];
    defaultSort?: SortDTO;
    aggsUrl?: string;
    sortTieBreaker?: string;
    paged?: boolean;
    expandable?: boolean;
}

export interface AddConfigDTO {
    allowAdd: boolean;
    title?: string;
}

export interface TableActionsConfigDTO {
    tag: string;
    pageTitle?: string;
    overrideHandle?: boolean;
    cssIcon?: string;
    tooltip?: string;
    conditional?: string;
}

export enum ActionType {
    VIEW_ONLY = "VIEW_ONLY",
    VIEW_EDIT = "VIEW_EDIT",
    ENTRY = "ENTRY",
    EDIT = "EDIT",
    DELETE = "DELETE",
    VIEW_WITH_ACTION = "VIEW_WITH_ACTION",
    DOWNLOAD_NO_PROMPT = "DOWNLOAD_NO_PROMPT",
    DOWNLOAD_WITH_PROMPT = "DOWNLOAD_WITH_PROMPT",
    ACTION_ONLY = "ACTION_ONLY",
    ROUTE = "ROUTE",
    ROUTE_IN_CARD_PANEL = "ROUTE_IN_CARD_PANEL",
    ROUTE_IN_OVERLAY = "ROUTE_IN_OVERLAY",
    FIRE_COMMAND = "FIRE_COMMAND"
}

export interface ActionConfigBaseDTO {
    buttonLabel: string;
    popUpTitle: string;
    popUpDescription: string;
    jsEditCondition: string;
    successMessage: string;
    rows: FormRowConfigDTO[];
    confirmationFormRows: FormRowConfigDTO[];
    minModalWidth: string;
    minModalHeight: string;
    parentMenuLabel: string;
    jsWarningCondition: string;
    warningMessage: string;
    confirmationMessage: string;
    confirmationUrl: string;
    confirmationFields: string[];
    downloadAction: boolean;
    multipartForm: boolean;
    dynamicResponse: boolean;
    displaySummary: boolean;
    templateUrl: string;
    icon: string;
    iconOnly: boolean;
    displayOrder: number;
    inLine: boolean;
    selectUrl: string;
    selectMethod: string;
    routerLink: string;
    modalCode: string;
    actionType: ActionType;
    cancelButton: string;
    cancelIcon: string;
    cancelColorTheme: string;
    showCancel: boolean;
    saveButton: string;
    saveIcon: string;
    saveColorTheme: string;
    actions: ActionConfigDTO[];
    tooltip?: string | undefined;
    // added by ui
    command?: Function | string;
    label?: string;
    commandFunction?: (event: React.MouseEvent, item: IMenuDTO) => void;
    downloadFunction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void;
    actionOnlyFunction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void;
    rowId: string;
    mediaType?: MediaType;

    buttonCss?: string;

    properties?: string;
    refreshTable?: () => void,
}

export interface ActionConfigDTO extends ActionConfigBaseDTO {
    method: string;
    url: string;
    children: ActionConfigDTO[];
    id?: string;
    route?: string;
    routeParameter?: string;

    // ui only
    selectedRows?: any // { [id: string]: TableRowDTO },

}

export interface GroupStatusChangeBO {
    rows: TableRowDTO[],
    formData: any
}

export interface TickerCommandDTO extends ActionConfigBaseDTO {
    blastCommand: string; // was command
    confirmationRequired?: boolean;
    quickAccess?: boolean;
    actionsBar?: boolean;
}

export interface DynamicResponseDTO {
    message: string;
    copyableMessage: string;
    type: string;
}

export interface FormColumnConfigDTO {
    fields: FieldConfigDTO[];
    code?: string;
    title?: string;
    css?: string;
    ignorePanel?: boolean;
    updateUrl?: string;
    deleteUrl: string;
    fullWidth: boolean;
    nested?: boolean;
    jsEditCondition?: string;
    actions?: ActionConfigDTO[];
    fxFlex: number | undefined;
    multipartForm?: boolean;
    hasTitle?: boolean;
    noLabel?: boolean;
    columns?: number;

    noBanner?: boolean;

    width: string | undefined;

    height: string | undefined;


}

export interface ActionResponseTableDO {
    title: string;
    content: any[];
    rows: FormRowConfigDTO[];
    hideForm: boolean;
    minModalWidth: string;
    minModalHeight: string;
}

export interface FormRowConfigDTO {
    columns: FormColumnConfigDTO[];
    css?: string;
}

export interface SingleFormDTO {
    rows: FormRowConfigDTO[];
    title: string;
    updateUrl: EndpointDTO;
    jsEditCondition: string;
    multipartForm: boolean;
    noLabel: boolean;
    cancelButton: string;
    cancelIcon: string;
    cancelColorTheme: string;
    showCancel: boolean;
    saveButton: string;
    saveColorTheme: string;
    saveIcon: string;
    templateUrl: string;
    actions: ActionConfigDTO[];
    popUpDescription?: string;

    noBanner?: boolean;

    minModalWidth?: string;
    minModalHeight?: string;


}

export interface SinglePageDTO {
    rows: FormRowConfigDTO[];
    panels: FormColumnConfigDTO[];
    title: string;
    selectUrl: EndpointDTO;
    properties: any;
}

export interface EndpointDTO {
    url: string;
    method: string;
    fields?: string[];
}

export interface RefDTO {
    nestedId?: any;
    entity?: string;
    code?: string;
    tab?: string;
}

export interface MetricOptionsDTO {
    name: string;
}

export interface TreeOptionsDTO {
    types: {
        [key: string]: TreeTypeDefDTO
    };
    depth: number;
    formFixedPositioned: boolean;
}

export interface TreeTypeDefDTO {
    type: string;
    updateUrl: string;
    addUrl: string;
    reorderUrl: string;
    innerFields?: FieldConfigDTO[];
    deleteUrl?: string;
    refreshViewAfterUpdate?: boolean;
}

export interface NextFieldAction {
    name: string;
    forward: boolean;
    rowIndex?: number;
}

export interface FormPanels {
    panels: FormColumnConfigDTO[];
}

export interface FieldConfigDTO {
    name: string;
    label: string;
    type: string;
    filterApplyFor?: string[];
    externalLink?: string;
    reorderWeightField?: string;
    filterElasticName?: string;
    elasticSearchOperand?: QueryOperand;
    sortableField?: string;
    sortable?: boolean;
    canAdd?: string;
    innerFields?: FieldConfigDTO[];
    formFullWidth?: boolean;
    displayInTable?: boolean;
    displayWhenSelected?: boolean;
    filterInTable?: boolean;
    advancedFilter?: boolean;
    addIgnore?: boolean;
    editHide?: boolean;
    deleteHide?: boolean;
    editReadOnly?: string;
    addReadOnly?: boolean;
    placeholder?: string;
    validators?: UiValidator[];
    cssClassPrefix?: string;
    options?: string[];
    child?: any;
    noLabel?: boolean;
    lookup?: string;
    moneyFormat?: string;
    category?: string;
    labelCss?: string;

    colCss?: string;
    entryCss?: string;
    tableCss?: string;
    lookupArg?: string;
    propertyName?: string;
    dateFormat?: string;
    selected?: (value: any) => void;
    drawOptions?: any[];
    decimalPlaces?: number;
    lookupData?: any[];
    // lookupConfig?: TableField[];
    lookupFilterable?: boolean;
    nestedTable?: FieldConfigDTO[];
    fixedCurrency?: boolean;
    suffix?: string;
    timezone?: string;
    rowConfig?: FormRowConfigDTO;
    selectUrl?: string;
    value?: any;
    alignRight?: boolean;
    idFieldName?: string;
    ignoreIfNotPresent?: boolean;
    ref?: RefDTO;
    popupUrl?: string;
    standaloneTableConfig?: IMenuDTO;
    localeFormatted?: boolean;
    align?: string;
    jsValue?: string;
    jsRecalculate?: string;
    viewHide?: boolean;
    lookupLayout?: string;
    canReorder?: boolean;

    canDelete?: boolean;
    codeMode?: string;
    theme?: string;
    aggs?: AggFieldConfigDO[];
    lookupInsertUrl?: string;
    lookupSearchUrl?: string;
    lookupSelectUrl?: string;

    lookupOverwritesForm?: boolean;

    metricOptions?: MetricOptionsDTO;
    treeOptions?: TreeOptionsDTO;
    newTableElementUrl?: string;
    strippedRows?: boolean;
    expandablePanel?: string;
    hideIfEmpty?: boolean;
    editIfTicked?: boolean;
    clipboard?: boolean;
    actions?: ActionConfigDTO[];
    durationOptions?: DurationPickerOptions;
    properties?: any;
    panelOptionsUrl?: any;
    lookupWatcherFieldName?: string;
    lookupWatcherFieldObjectId?: string;
    help?: string;
    objectKey?: boolean;
    clearableImgLookup?: boolean;
    sessionUserEmailFilter?: boolean;
    lookupCollection?: string;
    canClear?: boolean;
    showClipboard?: boolean;
    displayOrder?: number;
    rangeQueryUnits?: DateRangeUnitDO[],
    hasFuture?: boolean,
    hasPast?: boolean,
    hasTime?: boolean,
    distinctUrl?: string,
    searchable?: boolean,
    searchableSelected?: boolean,
    noAutoSearch?: boolean,
    searchbarOpen?: boolean,
    filterable?: boolean,
    cellWidth?: number | undefined
    fxFlex?: number | undefined
    style?: {
        [id: string]: any
    }

    colStyle?: {
        [id: string]: any
    }

    isResizing?: boolean
    hint?: string;

    updateFieldUrl?: string;

    deleteFieldUrl?: string;
    mediaType?: MediaType;

    blastDeltaCommand?: string;
    keyField?: string
    keyValue?: string;
    reorderUrl?: string;

    // grapejs
    plugins?: string[];
    styles?: string[];

    // MK 25-02-2024
    inlineReadOnly?: boolean | undefined;
    inlineNotNull?: boolean | false;
    inlineDisplayField?: string | undefined

}

export interface DurationPickerOptions {
    showNegative: boolean;
    showButtons: boolean;
    showPreview: boolean;
    showLetters: boolean;
    showYears: boolean;
    showMonths: boolean;
    showWeeks: boolean;
    showDays: boolean;
    showHours: boolean;
    showMinutes: boolean;
    showSeconds: boolean;
    zeroValue: string | null;
    previewFormat: string;
    customOutputFormat: string;
}

export enum AggFieldType {
    SUM = "SUM",
    AVG = "AVG",
}

export interface AggFieldConfigDO {
    type: AggFieldType;
}

export interface CurrencyDTO {
    id?: number;
    code: string;
    name?: string;
    symbol?: string;
    decimalPlaces?: number;
    displayPlaces?: number | undefined;
    exchangeRate?: number;
    lastUpdateTime?: Date;
    hideInRegistration?: boolean;
}

export interface MoneyDTO {
    currency: CurrencyDTO;
    value: number;
}

export interface MoneyTO {
    code: string;
    value: number;
}

export interface DataSourceDefDTO {
    type: MenuType;
    definition: MaintainConfigDTO;
}

export interface HeaderDTO {
    id?: string;
    name?: string;
    image?: string;
    info?: ReportDO;
    linkUrl?: string;
    icon?: string;
    idCss?: string;
    nameCss?: string;
    iconCss?: string;
    imageCss?: string;
    align?: string;
    rows?: FormRowConfigDTO[];
    data?: any;
    htmlBlock?: string;

    refreshRate?: number;

    refreshRateInSeconds?: number;

}

export interface EntityGroupConfigDTO {
    titleTag?: string;
    menuItems: IMenuDTO[];
    title?: string;
    heko?: string;
    selectUrl: string;
    header?: EntityGroupHeaderDTO;
    createConfig?: IMenuDTO;
    entryForm?: IMenuDTO;
    selectTableType?: MenuType;
    dataSources: DataSourceDefDTO[];
    currentMode?: number;
    headerUrl?: string;
}

export interface EntityGroupHeaderDTO {
    rows?: FormRowConfigDTO[];
    backgroundColor?: string;
    url?: string;
    fontColor?: string;
    title?: string;
    image?: string;
    actions?: ActionConfigDTO[];
    panels?: PanelConfigDTO[];
    withRefreshButton?: boolean;

    refreshRateInSeconds?: number;
}

export interface PanelConfigDTO {
    panelCss?: string;
    noTitle?: boolean;
    title?: string;
    titleCss?: string;
    rows?: FormRowConfigDTO[];
    selectUrl?: string;
    blastCollection?: string;
    blastTopic?: string;
    fxFlex?: number;
    jsEditCondition?: string;
    actions?: ActionConfigDTO[];
    htmlBlockUrl?: string;
    refreshRate?: number;
}

export interface DateRangeDTO {
    from: Date;
    to: Date;
}

export interface MapDTO {
    lat: number;
    lng: number;
    zoom: number;
    zoomControl: boolean;
    markers: LocationMarkerDTO[];
    circle: LocationCircleDTO;
}

export interface LocationMarkerDTO {
    lat: number;
    lng: number;
    label?: string;
    draggable?: boolean;
}

export interface LocationCircleDTO {
    lat: number;
    lng: number;
    radius: number;
    fillColor: string;
    circleDraggable: boolean;
    editable: boolean;
}

export interface AdminGaugeDO {
    name: string;
    value: number;
}

export interface CohortDO {
    id: number;
    currency: CurrencyDTO;
    code: string;
    name: string;
    locale: string;
    icon: string;
    status: CohortStatus;
}

export enum CohortStatus {
    SUSPENDED,
    ACTIVE,
    REMOVE,
}

export enum PageModeRequest {
    FIRST,
    NEXT,
    PREVIOUS,
    NEW,
}

export enum SummaryType {
    TABLE = "TABLE",
    FORM = "FORM",
    REPORT = "REPORT"
}

export interface SummaryDO {
    type: SummaryType;
    form: FormRowConfigDTO[];
    label?: string | undefined;
    tableData?: TableRowDTO[];
    formData?: any;
    report?: ReportDO;
    noTableHeader: boolean;
    tableActions?: ActionConfigDTO [];
    rowActions?: ActionConfigDTO [];
    groupActions?: ActionConfigDTO [];
    blastDeltaCommand?: string;
    highlightDelta?: boolean;
    disableTableControls?: boolean;
    disableColumnResize?: boolean;
}

export interface PageResponseDTO {
    offset: number;
    perPage: number;
    content: TableRowDTO[];
    total: number;
    queryParams: QueryParamsDTO;
    header: SummaryDO;
    footer: SummaryDO;
    // below additional response for cascading table
    conditions?: RowConditionDTO[];
    url?: string;
    expandUrl?: string;
    fields?: FormRowConfigDTO[];
    expandable?: boolean;
    expand?: boolean;
    grouped?: boolean;
    detailsButton?: boolean;
    table?: boolean;
    report?: boolean;
    form?: boolean;
    tableWidth?: string;
    tableNoHeader?: boolean;
    tableExpand?: boolean;
    tableExpandRowUrl?: string;
    tableExpandConfig?: FormRowConfigDTO;
    actions?: ActionConfigDTO[];
    groupActions?: ActionConfigDTO[];
    disableTableControls?: boolean;
    disableColumnResize?: boolean;

    breadCrumb: BreadcrumbsDO;

    orderByActions: OrderByActionDO[];

    filterAction: FilterActionControlDO;

    groupBy: string | undefined;
    noRowsMessage?: string | undefined;

}

export interface SortParamDTO {
    orderParam: string;
    orderDirection: string;
}

export interface IncidentsRequestDTO {
    content?: any;
    limit: number;
}

export interface ModulesRequestsDTO {
    content?: any;
    limit: number;
}

export interface PageRequestDTO {
    offset: number;
    perPage: number;
    index?: string;
    queryConditions?: QueryConditionsDTO;
    orderParam?: string;
    orderDirection?: string;
    convertTo?: string;
    indexClass?: string;
    orderParamTieBreaker?: string;
    after?: string;
    before?: string;
    last?: boolean;
}

export interface QueryConditionsDTO {
    index: string;
    conditions: QueryConditionDTO[];
}

export interface DateRange {
    from: string;
    to?: string;
}

export interface QueryConditionDTO {
    fieldName: string;
    operand: QueryOperand;
    fromName?: string;
    value?: any;
    dateRange?: DateRange;
    complexValue?: any;
}

export enum QueryOperand {
    EQUALS = "EQUALS",
    QUERY_STRING = "QUERY_STRING",
    DATE_RANGE = "DATE_RANGE",
}

export enum ChartType {
    bar = "bar",
    pie = "pie",
    line = "line",
    polarArea = "polarArea",
    donut = "doughnut",
    radar = "radar",
    horizontalBar = "horizontalBar",
}

export interface ChartPanelDTO {
    properties?: PanelPropertiesDTO;
    chart: ChartDTO;
}

export interface ChartDTO {
    type: ChartType;
    data: {
        [key: string]: any
    };
    options?: {
        [key: string]: any
    };
}

export interface PanelPropertiesDTO {
    width: string;
    height: string;
    noCard: boolean;
    style?: string;
}

export interface GetResponseDTO<T> {
    data: T;
}

export interface GuslError {
    message: string;
    messageKey: string;
    parameters: string[];
}

export interface GuslErrors {
    errors: GuslError[];
}

export interface MenubarItemDTO {
    label: string,
    icon: string,
    items?: MenubarItemDTO[],
    command?: (event: any) => void,
    iconOnly?: boolean
}


export interface ChartRequestDTO {
    singleValue?: string,
    singleChart?: string | undefined,
    mixedChart?: Array<{
        [index: string]: string
    }> | undefined,
    rangeQuery?: {
        [index: string]: string
    },
    timeUnitQuery?: {
        [index: string]: string
    },
    customCharts?: Array<string> | undefined,
}

export interface QueryParamsDTO {
    skip: number;
    limit: number;
    orderBys?: OrderByDTO[];
    rangeQuery?: RangeQueryDTO;
    rangeQueries?: RangeQueryDTO[];
    musts?: MatchQueryDTO[];
    mustNots?: MatchQueryDTO[];
    ins?: InQueryDTO[];
    should?: MatchQueryDTO[];
    firstAndLast?: boolean;
    total?: number;
    refresher?: number,
    searchString?: string,
    resetAt?: number,
    fuzzy?: boolean,
    caseInsensitive?: boolean,
    // MK 14-08-2024
    noCount?:boolean,
}


export interface FavQueryDTO {
    id?: string;
    queryName: string;

    code: string;
    isDefault: boolean; // if default is chosen, on initial load of the table, we will use this default queryParams from favQueries to initialise the table
    queryParams: QueryParamsDTO;

    isGlobal?: boolean | undefined;
}

export interface FavQueriesUrlsDTO {
    action: string; // add, remove, edit => ( we can allow to edit name and default, editing query itself could be messy)
    url: string;
}

export interface FavQueriesDTO {
    queries: FavQueryDTO[];
    urls: FavQueriesUrlsDTO[];
}

export interface FavQueryActionConfigDTO extends ActionConfigBaseDTO {
    pagedUrl: string;
    addUrl: string;
    updateUrl: string;
    deleteUrl: string;
}

export interface OrderByDTO {
    field: string;
    order: string;
}

export interface PaginationControl {
    firstActive: boolean,
    prevActive: boolean,
    nextActive: boolean,
    lastActive: boolean,
    total: number,
    from: number,
    to: number,
    hasPagination: boolean,
    hasActiveButtons: boolean,

}

export interface RangeQueryDTO {
    field: string;
    from?: string | number | Date;
    to?: string | number | Date;
    gt?: string | number | Date;
    lt?: string | number | Date;
    eq?: string | number | Date;
    type?: RangeQueryDO["type"];
    exclusive?: boolean;
}

export interface NumberRangeQueryDTO {
    field: string;
    from?: number;
    to?: number;

}


export interface MatchQueryDTO {
    field: string;
    value: string;
    fuzzy?: boolean;
}


export interface InQueryDTO {
    field: string;
    values: string[];
}

export const enum PaginationMode {
    FIRST,
    PREV,
    NEXT,
    LAST
}


export interface TableRowDTO extends IntersectionObserverEntry { // Object
    id: string,
    // used by nested table
    rowIndex?: number,
    // ui only
    actions?: ActionConfigDTO[],
    formMode?: FormMode,
    // currentAction?: ActionConfigDTO,
    conditionalRowDetails?: ConditionalRowDetailsDTO,
    flash?: boolean
    status?: string

    displayOrder?: number,

    rowOpened?: boolean,
    rowSelected?: boolean, // LookupModal

}

export interface PermissionDTO {
    permission: string;
    value: boolean;
}

export interface PermissionGroupDTO {
    name: string;
    permissions: PermissionDTO[];
}

export const enum NotificationType {
    SUCCESS = "SUCCESS",
    WARNING = "WARNING",
    ERROR = "ERROR",
    INFO = "INFO"
}

export const enum NotificationPosition {
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT",
    TOP_CENTER = "TOP_CENTER",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    BOTTOM_CENTER = "BOTTOM_CENTER"
}


export interface SystemNotificationDTO {
    type: NotificationType;
    position: NotificationPosition;
    noIcon: boolean;
    noAutoClose: boolean;
    message: string;
    autoCloseDuration: number;
}

export const enum MediaType {
    Mobile = "Mobile",
    Tablet = "Tablet",
    Laptop = "Laptop",
    Desktop = "Desktop",
    ExtraLarge = "ExtraLarge",

    // no casacde
    MobileOnly = "MobileOnly",

    MobileAndTabletOnly = "MobileAndTabletOnly",
    TabletOnly = "TabletsOnly",
    LaptopOnly = "LaptopsOnly",
    DesktopOnly = "DesktopOnly",
    ExtraLargeOnly = "ExtraLargeOnly",
    NotMobileOrTablet = "NotMobileOrTablet",
    Never = "Never"

}

export interface BreadcrumbDO {
    label: string;
    route?: string | undefined;

    queryParams?: QueryParamsDTO;
}

export interface BreadcrumbsDO {
    crumbs: BreadcrumbDO[];
}

export interface TotalDO {
    value: DecimalDO;
    prefix?: string | undefined;
    suffix?: string | undefined;
}

export interface FieldPositionProperties {
    name: string;
    startPos: number;
    windowWidth: number;
    panelWidth: number;
    isMobile: boolean;
    inLine?: boolean;
    isDialog?: boolean;
    hasLabel?: boolean;
    isEdit?: boolean;
    isLookup?: boolean;
    isResultTable?: boolean;
}
