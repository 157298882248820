import React, {MouseEventHandler} from "react";
import CloseIcon from "./CloseIcon";

type Props = {
    header?: JSX.Element | string,
    isVisible?: boolean,
    onClose?: MouseEventHandler<HTMLSpanElement>,
    children?: JSX.Element | JSX.Element[]
}
export default function Dialog({children, header, isVisible, onClose}: Props) {
    return (
        isVisible ? <div className={'widget_blue p-3'}>
            <p className={'lead'}>  {header}
                {onClose && <span className={'float-end'}>
                   <CloseIcon onClick={onClose}/>
                </span>}
            </p>
            {children}
        </div> : <></>
    );
}
