import {configureStore} from '@reduxjs/toolkit';
import activeUsersToggleSlice from '../components/active-fe-users/activeUsersToggleSlice';
import articleModalSlice from '../components/bespoke/otto-events/article-modal/articleModalSlice';
import ottoTableSlice from '../components/bespoke/otto-events/content/ottoTableSlice';
import dealOpenSlice from '../components/bespoke/otto-events/deal-view/dealOpenSlice';
import otherDealsSlice from '../components/bespoke/otto-events/other-deals-view/otherDealsSlice';
import ottoEventsSlice from '../components/bespoke/otto-events/ottoEventsSlice';
import betSlipSlice from '../components/bespoke/request-quote/betSlipSlice';
import slidingPanelOpenSlice
    from '../components/bespoke/sports-betting/landing-page/sliding-panel/slidingPanelOpenSlice';
import actionSlice from '../components/common/action-dialog/actionSlice';
import buySellSlice from '../components/common/buy-sell-form/buySellSlice';
import bespokeDataSlice from '../components/common/buy-sell-form/v2_components/watch-list/bespokeDataSlice';
import cardActionSlice from '../components/common/card/cardActionSlice';
import cardFormSlice from '../components/common/card/cardFormSlice';
import cardPanelOpenSlice from '../components/common/card/cardPanelOpenSlice';
import colorPickersToggleSlice from '../components/common/color/colorPickerSlice';
import dateSummarySlice from '../components/common/date-summary/dateSummarySlice';
import globalSearchSlice from '../components/common/global-search/GlobalSearchSlice';
import grapeSlice from '../components/common/grapejs/grapeSlice';
import guslColumnResizeSlice from '../components/common/gusl-table/guslColumnResizeSlice';
import guslTableRefreshSlice from "../components/common/gusl-table/guslTableRefreshSlice";
import guslTableSlice from '../components/common/gusl-table/guslTableSlice';
import inlineEditSlice from "../components/common/gusl-table/inline-edit/inlineEditSlice";
import queryParamsSlice from '../components/common/gusl-table/queryParamsSlice';
import scrollSlice from "../components/common/gusl-table/scrollSlice";
import guslFormSlice from '../components/common/maintain-form/guslFormSlice';
import maintainTableSlice from '../components/common/maintain-table/maintainTableSlice';
import groupActionsSlice from '../components/common/maintain-table/table-row/groupActionsSlice';
import rowActionOpenSlice from '../components/common/maintain-table/table-row/rowActionOpenSlice';
import rowExpanderSlice from '../components/common/maintain-table/table-row/rowExpanderSlice';
import tableRowSlice from "../components/common/maintain-table/table-row/tableRowSlice";
import nestedFieldSlice from '../components/common/nested-table/nestedTableSlice';
import optionFieldOpenSlice from '../components/common/option/optionFieldSlice';
import guslTabbedFormSlice from '../components/common/tabbed-form/tabbedFormSlice';
import dashboardSlice from '../components/dashboard/dashboardSlice';
import widgetSlice from '../components/dashboard/widgetSlice';
import pagedResponseSlice from '../components/paged-response/pagedResponseSlice';
import footerOverlaySlice from '../components/ui/navigation/left-hand-menu/footerOverlaySlice';
import {apiSlice} from "../features/api/apiSlice";
import devSlice from "../features/dev/devSlice";
import flagSlice from '../features/flagSlice';
import uiSlice from "../features/ui/uiSlice";
import lookupModalActionSlice from "../components/common/lookup-modal/lookupModalActionSlice";

export const store = configureStore({
        reducer: {
            devSlice: devSlice,
            uiSlice: uiSlice,
            actionSlice: actionSlice,
            maintainTableSlice: maintainTableSlice,
            rowExpanderSlice: rowExpanderSlice,
            guslTableSlice: guslTableSlice,
            guslTabbedFormSlice: guslTabbedFormSlice,
            tableRowSlice: tableRowSlice,
            queryParamsSlice: queryParamsSlice,
            dateSummarySlice: dateSummarySlice,
            guslTableRefreshSlice: guslTableRefreshSlice,
            guslColumnResizeSlice: guslColumnResizeSlice,
            activeUsersToggleSlice: activeUsersToggleSlice,
            buySellSlice: buySellSlice,
            colorPickersToggleSlice: colorPickersToggleSlice,
            optionFieldOpenSlice: optionFieldOpenSlice,
            rowActionOpenSlice: rowActionOpenSlice,
            nestedFieldSlice: nestedFieldSlice,
            slidingPanelOpenSlice: slidingPanelOpenSlice,
            groupActionsSlice: groupActionsSlice,
            cardActionSlice: cardActionSlice,
            cardFormSlice: cardFormSlice,
            guslFormSlice: guslFormSlice,
            cardPanelOpenSlice: cardPanelOpenSlice,
            footerOverlaySlice: footerOverlaySlice,
            betSlipSlice: betSlipSlice,
            flagSlice: flagSlice,
            dashboardSlice: dashboardSlice,
            // MK 29/08/2023
            scrollSlice: scrollSlice,
            grapeSlice: grapeSlice,
            inlineEditSlice: inlineEditSlice,
            widgetSlice: widgetSlice,
            globalSearchSlice: globalSearchSlice,
            bespokeDataSlice: bespokeDataSlice,
            dealOpenSlice: dealOpenSlice,
            ottoEventsSlice: ottoEventsSlice,
            otherDealsSlice: otherDealsSlice,
            ottoTableSlice: ottoTableSlice,
            articleModalSlice: articleModalSlice,
            pagedResponseSlice: pagedResponseSlice,
            lookupModalActionSlice:lookupModalActionSlice,
            [apiSlice.reducerPath]:
            apiSlice.reducer
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({serializableCheck: false}).concat(apiSlice.middleware)
    })
;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
