import React, {useEffect, useRef, useState} from 'react';
import {Subscription} from 'rxjs';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {SystemContext} from '../../../providers/system/SystemContext';
import {HEADER_HEIGHT} from '../../../providers/theme/GuslThemeProvider';
import {log} from '../../../services/LogService';
import {translateService} from '../../../services/TranslateService';
import {canShowActionButtons, canShowOnForm, getPostUrl, shouldDisable} from '../../../services/ValidationService';
import {isDefined} from '../../../utils/TypeCheckers';
import {constructUrl, getErrorMessage, RunOnceEffect, unSubscribe} from '../../../utils/Utils';
import {fieldService} from '../../FieldService';
import {
    ChildReference,
    FieldConfigDTO,
    FormMode,
    IMenuDTO,
    NotificationPosition,
    NotificationType,
    TableRowDTO
} from '../../types';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import Icon from '../maintain-table/bootstrap/Icon';
import MenuBar from '../maintain-table/bootstrap/MenuBar';
import {ActionButtonStyled} from '../maintain-table/bootstrap/styled';
import {maintainTableService} from '../maintain-table/MaintainTableService';
import {
    getData,
    GetDataResponse,
    guslFormFieldChange,
    GuslFormState,
    initForm,
    postFormData,
    resetFromData,
    UpdateDataResponse
} from './guslFormSlice';
import {
    ActionButtonContainerStyles,
    ErrorMessageStyled,
    MaintainPanelActionBarContainerStyled,
    MaintainPanelContainerStyled,
    PanelFormStyled,
    PanelFormWrapper,
    PanelHeaderWrapperStyled,
    PanelTitleStyled
} from './styled';
import {MaintainPanelProperties} from './types';

const MaintainPanel = (properties: MaintainPanelProperties): React.ReactElement => {
    const id = properties.code || 'id_' + (new Date()).getTime();

    const [className] = React.useState<string>(() => 'MaintainPanel-' + new Date().getTime());
    const systemContext = React.useContext(SystemContext)
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext);

    const dispatch = useAppDispatch();

    const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[id]);

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [fields, setFields] = useState<FieldConfigDTO[]>(properties.panel.fields);
    const [formData, setFormData] = useState<any>(properties.data || null);
    const [origFormData, setOrigFormData] = useState<any>(properties.data || null);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [formMode, setFormMode] = useState<FormMode>(properties.formMode);
    const [origFormMode] = useState(properties.formMode);
    const [menubarGroups, setMenubarGroups] = useState<IMenuDTO[]>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [childReferences] = useState<Map<string, ChildReference>>(new Map());
    const formElement = useRef(null);
    const [formHeight, setFormHeight] = useState<number>(400);
    const contentElement = useRef(null);
    const [formStartPos, setFormStartPos] = useState<number>(HEADER_HEIGHT);

    const isMobile = environmentContext.isMobileDevice(properties.widgetPanelProperties);

    const [footerHeight, setFooterHeight] = useState<number>(0);
    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    RunOnceEffect(() => {
        dispatch(initForm({
            code: id,
            fields: properties.panel.fields,
            formData: properties.data || {},
            rowData: properties.rowData || {}
        }))
    })

    // useEffect(() => {
    //     window.requestAnimationFrame(function () {
    //         setTimeout(() => {
    //
    //             if (contentElement) {
    //                 // @ts-ignore
    //                 const rect = contentElement?.current?.getBoundingClientRect();
    //                 if (rect) {
    //                     setFormStartPos(rect.y)
    //                 }
    //             }
    //         }, 100)
    //     });
    // }, [loading, contentElement]);

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                // @ts-ignore
                if (formElement?.current?.offsetHeight) {
                    // @ts-ignore
                    const rect = formElement?.current?.getBoundingClientRect();
                    if (rect) {
                        // const h = window.innerHeight - rect.top - 30;
                        const h = rect.top;
                        setFormHeight(h > 0 ? h : 500);
                        setFormStartPos(rect.y)
                    } else {
                        setFormHeight(400);
                    }
                }
            }, 100)
        });
    }, [loading]);


    const performGetData = (abortController: AbortController, mounted: boolean) => {
        if (properties.menuItem) {
            const baseUrl: string | undefined = maintainTableService.extractGetOneEndpoint(properties.menuItem)
            if (baseUrl) {
                const url: string | undefined = constructUrl(baseUrl, properties.data, properties.rowData)
                dispatch(getData({
                    code: id,
                    url: url,
                    sessionContext: sessionContext,
                    abortController: abortController,
                    formData: _guslFormState?.formData,
                    rowData: _guslFormState?.rowData,
                    panelCode: properties.panel.code,
                }))
                    .unwrap()
                    .then((reply: GetDataResponse) => {
                        let primaryName = maintainTableService.extractPrimaryObjectName(reply.response, _guslFormState?.fields);
                        if (primaryName) {
                            setMenubarGroups(maintainTableService.extractMenubarActionsForFormToMenuGroups(sessionContext.getLoggedInUser(), properties.menuItem, editForm, formData, onRefresh, reply.response[primaryName]));
                        } else {
                            setMenubarGroups(maintainTableService.extractMenubarActionsForFormToMenuGroups(sessionContext.getLoggedInUser(), properties.menuItem, editForm, formData, onRefresh, reply.response));
                        }
                        setLoading(false);
                    })
                    .catch((error: any) => {
                        log.error(className, 'ERR001', error)
                        setErrorMessage('Failed to load data');
                        setLoading(false)
                    })
            } else {
                setLoading(false);
            }
        }
    }

    const onRefresh = () => {
        let abortController = new AbortController();
        performGetData(abortController, mounted)
    }

    const reLoad = () => {
        onRefresh()
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let mounted = true;
        setMounted(mounted);
        let abortController = new AbortController();

        if (properties.menuItem) {
            performGetData(abortController, mounted)
        } else {
            setNotFound(true)
            setLoading(false)
        }
    }, [properties.menuItem?.label]);

    const saveCurrentRow = () => {
        let passed = true;
        childReferences.forEach(reference => {
            if (reference.doValidation) {
                if (!reference.doValidation(_guslFormState.formData[reference.name])) {
                    passed = false;
                }
            }
        })
        if (!passed) {
            setErrorMessage('Failed form validation')
            return;
        }
        setSubmitted(true);
        setErrorMessage(undefined)

        const multipartForm: boolean = properties?.panel?.multipartForm || false

        const postUrl = constructUrl(getPostUrl(formMode, properties.panel), _guslFormState.formData, _guslFormState.rowData)

        const formData = multipartForm ? maintainTableService.createRequestBody(_guslFormState.formData, 'data', true) : maintainTableService.createRequestBody(_guslFormState.formData)

        if (postUrl) {
            setLoading(true)
            const abortController = new AbortController();
            // setCurrentAbortController(abortController);

            dispatch(postFormData({
                code: id,
                endpoint: {url: postUrl, method: 'PUT'},
                sessionContext: sessionContext,
                abortController: abortController,
                formData: formData,
                multipartForm: multipartForm
            }))
                .unwrap()
                .then((reply: UpdateDataResponse) => {
                    if (reply?.response?.notificationMessage && !reply?.response.data?.hideNotification) {
                        systemContext.toast({
                            type: NotificationType.SUCCESS,
                            position: NotificationPosition.BOTTOM_RIGHT,
                            noIcon: false,
                            noAutoClose: false,
                            message: reply?.response?.notificationMessage,
                            autoCloseDuration: 2000
                        })

                    }
                    if (reply?.response?.reloadTemplate) {
                        properties.closeDialog(true)
                        return;
                    }
                    childReferences.forEach(reference => {
                        if (reference.formSaved) {
                            reference.formSaved(reply?.response)
                        }
                    })

                    setLoading(false)
                    setModeOnFields(FormMode.VIEW);
                    if (origFormMode === FormMode.DELETE) {
                        properties.closeDialog();
                    } else if (origFormMode === FormMode.EDIT) {
                        if (!reply?.response?.hideNotification) {
                            systemContext.toast({
                                type: NotificationType.SUCCESS,
                                position: NotificationPosition.BOTTOM_RIGHT,
                                noIcon: false,
                                noAutoClose: false,
                                message: 'Record updated',
                                autoCloseDuration: 2000
                            })
                        }
                        if (reply?.response?.data?.reloadTemplate) {
                            console.log('-- reload -- s/be underlying table')
                            reLoad();
                        }
                        properties.closeDialog();
                    }

                })
                .catch((error: any) => {
                    log.error(className, 'ERR001', error)
                    setErrorMessage(getErrorMessage(error))
                    setLoading(false)
                })
            //
            //
            // // sessionContext.put(constructUrl(postUrl, properties.data), formData)
            // const request = Object.assign({}, formData)
            // request.beforeData = origFormData
            // sessionContext.put(constructUrl(postUrl, request, properties.rowData), request)
            //     .then((response) => {
            //         setModeOnFields(FormMode.VIEW);
            //
            //         setFormData(response.data);
            //         setOrigFormData(clone(response.data));
            //
            //         setLoading(false)
            //         if (origFormMode === FormMode.EDIT) {
            //             // @ts-ignore
            //             if (!response.data?.hideNotification) {
            //                 systemContext.toast({
            //                     type: NotificationType.SUCCESS,
            //                     position: NotificationPosition.BOTTOM_RIGHT,
            //                     noIcon: false,
            //                     noAutoClose: false,
            //                     message: 'Record updated',
            //                     autoCloseDuration: 2000
            //                 })
            //             }
            //             properties.closeDialog();
            //         }
            //     }).catch(error => {
            //     setErrorMessage(getErrorMessage(error))
            //     setLoading(false)
            // })
        }
    }

    const renderFields = (): React.ReactElement => {
        const onSubmitted = (): void => {
            // do nothing
        }

        const onArrayChange = (parentName: string, childName: string, value: any, rowIndex: number): void => {
            const currentFormData = formData
            if (currentFormData) {
                const rows: TableRowDTO[] = currentFormData[parentName] || []
                if (rowIndex >= 0 && rowIndex < (rows?.length || 0)) {
                    // @ts-ignore
                    rows[rowIndex][childName] = value
                }
                setFormData(currentFormData)

            }
        }
        const onChange = (name: string, value: any, overwrite?: boolean, panelName?: string | undefined): void => {
            // console.log(`onChange ==>  name: ${name} value: ${value} overwrite: ${overwrite} panelName: ${panelName}`, value)
            dispatch(guslFormFieldChange({
                code: id,
                name: name,
                value: value,
                overwrite: overwrite,
                panelName: panelName
            }))
        }
        const getCurrentRowData = (): any => {
            return _guslFormState?.formData
        }

        const registerChildReference = (reference: ChildReference) => {
            childReferences.set(reference.name, reference);
        }
        let firstField: number = -1;

        return <>

            {_guslFormState?.fields?.filter((fieldConfig: FieldConfigDTO) => canShowOnForm(fieldConfig, formMode))
                .map((fieldConfig: FieldConfigDTO, index: number) => {
                    if (firstField < 0) {
                        if (!shouldDisable(FormMode.NEW, fieldConfig)) {
                            firstField = index;
                        }
                    }
                    const data = _guslFormState?.formData ? _guslFormState?.formData[fieldConfig.name] : undefined
                    return <div id={'frm_pnl_' + fieldConfig.name}
                                key={fieldConfig.name + '_' + _guslFormState?.counter}>
                        {fieldService.getFormTemplate({
                            code: _guslFormState.code,
                            formMode: formMode,
                            isFirstField: index === firstField,
                            fieldConfig,
                            menuItem: properties.menuItem,
                            // data: formData ? formData[fieldConfig.name] || undefined : undefined,
                            data: data,
                            rowData: _guslFormState?.formData,
                            onSubmitted,
                            onChange,
                            onArrayChange,
                            getCurrentRowData,
                            isDialog: properties.isDialog,
                            reference: {
                                name: fieldConfig.name,
                                displayOrder: index,
                                register: registerChildReference
                            },
                            reLoad: reLoad,
                            panelName: fieldConfig.name
                        })}
                    </div>
                })
            }
        </>
    }


    const editForm = () => {
        setModeOnFields(FormMode.EDIT);
    }

    const renderPanelHeader = (): React.ReactElement => {
        const canEdit = properties.canEdit && formMode === FormMode.VIEW;

        let hasLabel = isDefined(properties?.panel?.noLabel) ? !properties?.panel?.noLabel : true;
        let hasBanner = isDefined(properties?.panel?.noBanner) ? !properties?.panel?.noBanner : true;
        let hasHeader: boolean = hasLabel;
        if (canEdit && menubarGroups?.length > 0) {
            // if actions, then need to have a menu bar
            hasHeader = true;
        }
        return (
            <>
                {hasHeader && <PanelHeaderWrapperStyled hasTitle={hasLabel} hasBanner={hasBanner}>
                    <div className="col d-flex align-items-center">
                        {hasLabel && hasBanner &&
                            <PanelTitleStyled>{translateService.getTitle(properties.menuItem?.code, properties?.panel.title)}</PanelTitleStyled>}
                    </div>
                    <div className="col">
                        {canEdit && menubarGroups?.length > 0 &&
                            <MenuBar entityId={properties?.data?.id} menuGroups={menubarGroups}/>}
                    </div>
                </PanelHeaderWrapperStyled>
                }
            </>
        );
    }

    const renderActionBar = (): React.ReactElement => {
        const onCancel = () => {
            dispatch(resetFromData({
                code: id
            }))
            if (origFormMode === FormMode.EDIT) {
                properties.closeDialog();

            } else {
                setModeOnFields(FormMode.VIEW);
            }
        }

        const canShow = canShowActionButtons(formMode);
        const cancelIcon = maintainTableService.getIconForCancel(properties?.menuItem?.singleForm?.cancelIcon)
        const cancelColorTheme = properties?.menuItem?.singleForm?.cancelColorTheme || 'defaultColor'
        const saveIcon = maintainTableService.getIconForSave(properties?.menuItem?.singleForm?.saveIcon)
        const saveColorTheme = properties?.menuItem?.singleForm?.saveColorTheme || 'save'

        return (
            <>
                {!loading && canShow && <ActionButtonContainerStyles controlOnTop={properties.controlOnTop}>
                    <ActionButtonStyled onClick={onCancel} withMargin isAction colorName={cancelColorTheme}>
                        {cancelIcon && <><Icon icon={cancelIcon} className={'action_icon ' + cancelIcon}/><>&nbsp;</>
                        </>}
                        <span>{maintainTableService.getLabelForCancel(properties?.menuItem?.singleForm?.cancelButton)}</span>
                    </ActionButtonStyled>
                    <ActionButtonStyled onClick={saveCurrentRow} withMargin isAction colorName={saveColorTheme}>
                        {saveIcon && <><Icon icon={saveIcon} className={'action_icon ' + saveIcon}/><>&nbsp;</>
                        </>}
                        <span>{maintainTableService.getLabelForSave(properties?.menuItem?.singleForm?.saveButton)}</span>
                    </ActionButtonStyled>
                </ActionButtonContainerStyles>
                }
            </>
        );
    }

    const renderForm = (): React.ReactElement => {
        if (loading) {
            return <></>
        }
        return (
            <PanelFormWrapper
                id={'panel-form'} ref={formElement}
                height={properties?.panel?.height}
                isMobile={isMobile}
                isEdit={formMode === FormMode.EDIT}
                startFormPos={formStartPos}
                footerHeight={footerHeight}
            >
                <PanelFormStyled
                    isEdit={formMode === FormMode.EDIT}
                    isMobile={isMobile}
                >
                    {renderFields()}
                </PanelFormStyled>
            </PanelFormWrapper>
        )
    }

    const renderNotFound = (): React.ReactElement => {
        return <h5 className="invalid">Failed to find record</h5>
    }

    const renderPage = (): React.ReactElement => {
        return <>{notFound ? renderNotFound() : renderForm()}</>;
    };

    const renderErrorMessage = (): React.ReactElement => {
        if (errorMessage) {
            return (
                <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>
            );
        }
        return <></>
    };

    const setModeOnFields = (formMode: FormMode) => {
        setFormMode(formMode);

        childReferences.forEach(reference => {
            if (reference.changeMode) {
                reference.changeMode(formMode);
            }
        })
    }
    return (
        <MaintainPanelContainerStyled isDialog={properties.isDialog}
                                      height={properties.panel.height}
                                      isEdit={properties.formMode === FormMode.EDIT}
                                      key={'maintain_panel_container_' + (_guslFormState?.counter || 'a')}
                                      id={'maintain_panel_container_' + (_guslFormState?.counter || 'a')}
        >

            {renderPanelHeader()}
            {properties.controlOnTop && <>
                <MaintainPanelActionBarContainerStyled>
                    {renderActionBar()}
                </MaintainPanelActionBarContainerStyled>
                {renderErrorMessage()}
            </>}

            {loading ? <LoadingSpinner/> : renderPage()}

            {!properties.controlOnTop && <>
                {renderErrorMessage()}
                <MaintainPanelActionBarContainerStyled>
                    {renderActionBar()}
                </MaintainPanelActionBarContainerStyled>
            </>}
            <div className="">
            </div>
        </MaintainPanelContainerStyled>
    )
}
export default MaintainPanel;
