import {PaginationControl, PaginationMode, QueryParamsDTO} from '../../types';

export const ROWS_PER_PAGE = [20, 50, 100, 200, 500]; // 50 is used for infiniScroll ROWS_PER_PAGE[1]

class PaginationService {

    className = 'PaginationService-' + new Date().getTime()

    public blankQueryParam(): QueryParamsDTO {
        return Object.assign({}, {
            skip: 0,
            limit: ROWS_PER_PAGE[0],
            firstAndLast: false,
            mustNots:[],
            should:[],
            fuzzy:true,
            orderBys:[],
            refresher: 1,
            ins:[]

        })

    }

    public updatePaginatorControl(queryParams: QueryParamsDTO, total: number): PaginationControl {

        let firstActive = true
        let prevActive = true
        let nextActive = true
        let lastActive = true
        let from = 0
        let to = 0

        let _total = total;
        if(total === -2){
            // just to keep it simple...;-)
            _total = 100000000;
        }

        if (queryParams) {
            to = queryParams.skip + queryParams.limit;
            if (to > _total || queryParams.limit === 0) {
                to = _total;
            }
            from = queryParams.skip + 1

            if (queryParams.skip === 0) {
                firstActive = false;
                prevActive = false;
            }

            if (_total <= (queryParams.skip + queryParams.limit)) {
                lastActive = false;
                nextActive = false;
            }
        }
        const qpTotal = _total || -1
        let hasPagination = qpTotal > 0
        let hasActiveButtons = firstActive || prevActive || nextActive || lastActive

        return {
            hasPagination,
            hasActiveButtons,
            firstActive,
            prevActive,
            nextActive,
            lastActive,
            total,
            from,
            to
        }
    }


    public updatePagination(queryParams: QueryParamsDTO, paginationControl: PaginationControl, mode: PaginationMode): QueryParamsDTO {
        const updatedQueryParams = {...queryParams}
        let skip = 0;
        let total = paginationControl.total
        if(total === -2){
            // just to keep it simple...;-)
            total =  100000000;
        }

        switch (mode) {
            case PaginationMode.FIRST:
                skip = 0
                break;
            case PaginationMode.PREV:
                skip = queryParams.skip - queryParams.limit;
                if (skip < 0) {
                    skip = 0;
                }
                break;
            case PaginationMode.NEXT:
                // console.log("next 0",queryParams)
                skip = queryParams.skip + queryParams.limit;
                if (skip < 0) {
                    skip = 0;
                    // console.log("next 1")
                }
                if (skip > total) {
                    // console.log("next 2")
                    // I think this s/be a set
                    skip = total - queryParams.limit
                }
                break;
            case PaginationMode.LAST:
                skip = total - queryParams.limit
                break;
        }
        updatedQueryParams.skip = skip
        return updatedQueryParams
    }


}

const paginationService = new PaginationService();

export {paginationService};

export default paginationService;
