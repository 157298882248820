import {Capacitor} from '@capacitor/core';
import React from 'react';
import {User} from '../../../providers/session/types';
import {log} from '../../../services/LogService';
import {translateService} from '../../../services/TranslateService';
import {arrayIsEmpty, arrayNotEmpty, isBlank, isDefined, isObject, notBlank} from '../../../utils/TypeCheckers';
import {
    ActionConfigDTO,
    ActionType,
    ConditionalRowDetailsDTO,
    CreateFormConfigDTO,
    EndpointDTO,
    FieldConfigDTO,
    FormColumnConfigDTO,
    FormMode,
    FormPanels,
    FormRowConfigDTO,
    IMenuDTO,
    ISystemDTO,
    LookupConfigDTO,
    MediaType,
    MenubarItemDTO,
    MenuType,
    OrderByActionDO,
    OrderByDTO,
    PanelConfigDTO,
    QueryParamsDTO,
    SingleFormDTO,
    TableControlsDTO,
    TableRowDTO,
} from '../../types';
import {CreateEntityProperties} from './types';

class MaintainTableService {

    className = 'MaintainTableService-' + new Date().getTime()

    public extractRefreshRate = (menuItem?: IMenuDTO): number => {
        let refreshRate: number = 5 * 60 * 1000 // 5 min
        if (menuItem?.entityGroup && menuItem?.entityGroup?.dataSources && menuItem.entityGroup?.dataSources?.length > 0) {
            if (menuItem.entityGroup.dataSources[0].definition.refreshRate && menuItem.entityGroup.dataSources[0].definition.refreshRate !== -1) {
                refreshRate = menuItem.entityGroup.dataSources[0].definition.refreshRate * 1000
            } else {
                return -1
            }
        }
        return refreshRate;
    }

    public extractFields = (menuItem: IMenuDTO, onlyTableFields?: boolean): FieldConfigDTO[] => {
        if (!menuItem) {
            return [];
        }
        if (menuItem.menuType === MenuType.ENTITY_GROUP || menuItem.menuType === MenuType.MAINTAIN || menuItem.menuType === MenuType.BLAST_COLLECTION_TABLE) {
            if (menuItem.entityGroup && menuItem.entityGroup?.dataSources && menuItem.entityGroup?.dataSources?.length > 0) {
                return this.extractFieldsFromRows(menuItem.entityGroup.dataSources[0].definition.rows, onlyTableFields);
            }
        } else {
            log.warn(this.className, 'Do not know what to do with menu item', menuItem);
        }
        return [];
    }

    public isFieldTypeSearchable = (field: FieldConfigDTO) => {
        switch (field.type) {
            case 'text' :
            case 'html' :
                return true;
            case 'number' :
                return false;
            case 'percent' :
                return true;
        }
        return false;
    }

    public extractAllSearchableFields = (menuItem: IMenuDTO, onlyTableFields?: boolean): FieldConfigDTO[] => {
        if (!menuItem) {
            return [];
        }
        if (menuItem.menuType === MenuType.ENTITY_GROUP || menuItem.menuType === MenuType.MAINTAIN || menuItem.menuType === MenuType.BLAST_COLLECTION_TABLE) {
            if (menuItem.entityGroup && menuItem.entityGroup?.dataSources && menuItem.entityGroup?.dataSources?.length > 0) {
                const fields = this.extractFieldsFromRows(menuItem.entityGroup.dataSources[0].definition.rows, onlyTableFields);
                const searchableFields: FieldConfigDTO[] = [];
                fields.forEach(fld => {
                    if (fld.searchable && this.isFieldTypeSearchable(fld)) {
                        searchableFields.push(fld)
                    }
                })

                return searchableFields;
            }
        } else {
            log.warn(this.className, 'Do not know what to do with menu item', menuItem);
        }
        return [];
    }


    public findLookupConfig = (lookupCode: string | undefined, lookups: LookupConfigDTO[]): LookupConfigDTO | undefined => {
        const lookupEntityConf: LookupConfigDTO | undefined = lookups.find(lookup => lookup.name === 'standard_id');
        if (lookupCode) {
            const found: LookupConfigDTO | undefined = lookups.find(lookup => lookup.name === lookupCode);
            return found ? found : lookupEntityConf
        }
        return lookupEntityConf
    }

    public extractFieldsFromRows = (rows?: FormRowConfigDTO[], onlyTableFields?: boolean): FieldConfigDTO[] => {
        if (rows) {
            return rows
                .flatMap(row => {
                    return row.columns?.flatMap(col => {
                        return col.fields
                            .filter(fld => {
                                if (onlyTableFields === undefined) {
                                    return true;
                                } else if (onlyTableFields) {
                                    return fld.displayInTable === true;
                                } else {
                                    return fld.displayInTable === undefined || !fld.displayInTable;
                                }
                            })
                            .flatMap((fld: FieldConfigDTO) => fld)
                    })
                });
        } else {
            return [];
        }
    }

    public extractAllFieldsFromRows = (rows?: FormRowConfigDTO[]): FieldConfigDTO[] => {
        if (rows) {
            return rows
                .flatMap(row => {
                    return row.columns?.flatMap(col => {
                        return col.fields
                            .flatMap((fld: FieldConfigDTO) => fld)
                    })
                });
        } else {
            return [];
        }
    }

    public extractLookupConfig = (lookupCode: string, systemConfig: ISystemDTO): LookupConfigDTO | undefined => {
        if (isBlank(lookupCode) || !systemConfig || !systemConfig.lookups) {
            return undefined;
        }
        let lookups: LookupConfigDTO[] = (systemConfig.lookups || [])
            .filter(lookup => lookup.name === lookupCode)
            .map(lookup => lookup);
        return arrayIsEmpty(lookups) ? undefined : lookups[0]
    }


    public extractFormDetailsFromActionConfig = (actionConfig: ActionConfigDTO | undefined): CreateEntityProperties => {
        return {
            canCreate: true,
            label: actionConfig?.label,
            fields: this.extractFieldsFromRows(actionConfig?.rows, false),
            templateUrl: actionConfig?.templateUrl,
            updateEndpoint: {method: 'POST', url: actionConfig?.url} as EndpointDTO
        } as CreateEntityProperties;
    }

    public createRequestBody(data: any, primaryObjectName?: string | undefined, fileUpload?: boolean) {
        if (fileUpload) {
            const formData = new FormData();
            const body: any = {};
            Object.keys(data).forEach(key => {
                if (isObject(data[key]) && data[key]['name'] && data[key]['type'] && data[key]['size'] && data[key]['lastModified']) {
                    formData.append(key, data[key]);
                    body[key] = data[key]['name']
                } else {
                    body[key] = data[key]
                }
            })
            formData.set('data', JSON.stringify(body))
            return formData
        }

        if (primaryObjectName) {
            const body: any = {};
            body[primaryObjectName] = data;
            return body;
        } else {
            return {...data};
        }
    }

    public extractDisplayInTableFields(menuItem: IMenuDTO): FieldConfigDTO[] {
        return this.extractFields(menuItem, true);
    }

    public extractInitialOrderBy(menu: IMenuDTO | undefined): OrderByDTO | undefined {
        if (!menu) {
            return undefined;
        }
        if (menu.menuType === MenuType.ENTITY_GROUP || menu.menuType === MenuType.MAINTAIN) {
            if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                if (menu.entityGroup.dataSources[0].definition?.tabs?.length > 0) {
                    if (menu.entityGroup.dataSources[0].definition.tabs[0].tableView && menu.entityGroup.dataSources[0].definition.tabs[0].tableView.defaultSort) {
                        return {
                            field: menu.entityGroup.dataSources[0].definition.tabs[0].tableView.defaultSort.field,
                            order: menu.entityGroup.dataSources[0].definition.tabs[0].tableView.defaultSort.direction
                        }
                    }
                } else if (menu.entityGroup.dataSources[0].definition?.defaultSortString) {
                    const split = menu.entityGroup.dataSources[0].definition?.defaultSortString.split(',');
                    if (split && split.length === 2) {
                        return {
                            field: split[0],
                            order: split[1]
                        }
                    }

                }
            }
        }
        return undefined;
    }


    public extractUpdateUrl(menuItem: IMenuDTO): string {
        if (menuItem.menuType === MenuType.ENTITY_GROUP) {
            if (menuItem.entityGroup) {
                return menuItem.entityGroup.menuItems[0]?.singleForm?.rows[0]?.columns[0].updateUrl || '';
            }
        }
        return '';
    }


    public convertToMenu(row: TableRowDTO | undefined, action: ActionConfigDTO): IMenuDTO {
        return {
            label: this.getTitle(row, action.popUpTitle),
            menuType: MenuType.ACTION_ITEM,
            selectUrl: this.getSelectUrl(action),
            displayOrder: 10,
            singleForm: {
                rows: action.rows,
                title: this.getTitle(row, action.popUpTitle),
                templateUrl: action.templateUrl,
                updateUrl: {
                    url: action.url,
                    method: 'POST',
                } as EndpointDTO,
                jsEditCondition: '',
                multipartForm: action.multipartForm,
                noLabel: false,
                cancelIcon: action.cancelIcon,
                cancelButton: action.cancelButton,
                cancelColorTheme: action.cancelColorTheme,
                showCancel: action.showCancel,
                saveButton: action.saveButton,
                saveIcon: action.saveIcon,
                saveColorTheme: action.saveColorTheme,
                actions: (action.actions || []).concat(action.children),
                popUpDescription: action.popUpDescription,
            } as SingleFormDTO,
        } as IMenuDTO;
    }


    public convertCreateEntityToMenu(createEntityProperties: CreateEntityProperties | undefined): IMenuDTO | undefined {
        // @ts-ignore
        if (!createEntityProperties) {
            return undefined;
        }
        return {
            label: createEntityProperties.label,
            menuType: MenuType.ENTITY_GROUP_SINGLE_FORM,
            selectUrl: createEntityProperties.templateUrl,
            displayOrder: 10,
            singleForm: {
                rows: [{
                    columns: [{
                        fields: createEntityProperties.fields
                    }]
                }] as FormRowConfigDTO[],
                title: createEntityProperties.label,
                templateUrl: createEntityProperties.templateUrl || '',
                updateUrl: createEntityProperties.updateEndpoint || {} as EndpointDTO,
                jsEditCondition: '',
                multipartForm: false,
                noLabel: false,
                cancelIcon: createEntityProperties.cancelIcon || '',
                cancelButton: createEntityProperties.cancelButton || '',
                cancelColorTheme: '', // createEntityProperties.cancelColorTheme,
                showCancel: createEntityProperties.showCancel || '',
                saveButton: createEntityProperties.saveButton || '',
                saveIcon: createEntityProperties.saveIcon || '',
                saveColorTheme: '', //createEntityProperties.saveColorTheme,
                actions: []
            } as SingleFormDTO,
        } as IMenuDTO;
    }

    public getFxFlex(fxFlex: number | undefined, fields: FieldConfigDTO[]): number | undefined {
        if (fxFlex) {
            return fxFlex;
        }
        let fx: number | undefined = undefined
        fields?.forEach(fld => {
            if (fld.fxFlex && fld.fxFlex > 1) {
                fx = fld.fxFlex
            }
        })
        return fx;
    }

    extractMenuItem(menuItem: IMenuDTO | undefined): IMenuDTO | undefined {
        if (!menuItem) {
            return undefined;
        }
        if (menuItem.menuType === MenuType.ENTITY_GROUP_SINGLE_FORM) {
            if (!menuItem.singleForm && menuItem?.entityGroup?.menuItems && menuItem?.entityGroup?.menuItems.length > 0) {
                return menuItem?.entityGroup?.menuItems[0]
            }
        }

        return menuItem;
    }

    public extractFormPanels(menuItem: IMenuDTO | undefined): FormPanels {

        const panels: FormColumnConfigDTO[] = []
        if (!menuItem) {
            return {panels: []} as FormPanels;
        }
        if (menuItem.menuType === MenuType.ENTITY_GROUP) {
            if (menuItem.entityGroup) {
                menuItem.entityGroup.menuItems[0]?.singleForm?.rows
                    .forEach(row => {
                        row.columns?.forEach(col => {
                            const panel = {...Object.assign(col, {})}; // all is well
                            panel.title = menuItem.label
                            panels.push(panel);
                        });
                    });
            }
        }
        if (menuItem.menuType === MenuType.ENTITY_GROUP_SINGLE_FORM) {
            if (menuItem.singleForm) {
                menuItem.singleForm?.rows
                    .forEach(row => {
                        row.columns?.forEach(col => {
                            const panel = {...Object.assign(col, {})}; // all is well
                            panel.title = col.title || menuItem.label
                            panel.noBanner = menuItem.singleForm?.noBanner
                            panels.push(panel);
                        });
                    });
            } else if (menuItem?.entityGroup?.menuItems) {
                if (menuItem?.entityGroup?.menuItems.length > 0) {
                    if (menuItem?.entityGroup?.menuItems[0].singleForm) {
                        menuItem?.entityGroup?.menuItems[0].singleForm?.rows
                            .forEach(row => {
                                row.columns?.forEach(col => {
                                    const panel = {...Object.assign(col, {})}; // all is well
                                    panel.title = menuItem.label
                                    panel.noBanner = col.noBanner
                                    panels.push(panel);
                                });
                            });
                    }

                    // if (menuItem.menuItems[0].entityGroup) {
                    //     menuItem.menuItems[0].entityGroup.menuItems[0]?.singleForm?.rows
                    //         .forEach(row => {
                    //             row.columns?.forEach(col => {
                    //                 const panel = {...Object.assign(col, {})}; // all is well
                    //                 panel.title = menuItem.label
                    //                 panels.push(panel);
                    //             });
                    //         });
                    // }
                }
            }
        }
        if (menuItem.menuType === MenuType.SINGLE_PAGE) {
            if (menuItem.singlePage) {
                if (menuItem.singlePage.panels) {
                    menuItem.singlePage?.panels
                        .forEach(panel => {
                            panels.push(panel);
                        });

                } else {
                    menuItem.singlePage?.rows
                        .forEach(row => {
                            row.columns?.forEach(col => {
                                const panel = {...Object.assign(col, {})}; // all is well
                                panel.title = menuItem.singlePage?.title || menuItem.label
                                // panel.title = menuItem.label
                                panels.push(panel);
                            });
                        });
                }
            }
        }
        if (menuItem.menuType === MenuType.ACTION_ITEM) {
            menuItem.singleForm?.rows?.forEach(row => {
                row.columns?.forEach(col => {
                    const panel = {...Object.assign(col, {})}; // all is well
                    panel.title = menuItem.label
                    panel.fxFlex = this.getFxFlex(col.fxFlex, col.fields)
                    panel.noLabel = true // gbw
                    if (!panel.updateUrl) {
                        panel.updateUrl = menuItem.singleForm?.updateUrl?.url
                    }
                    panels.push(panel);
                });
            });
        }
        return {panels} as FormPanels;
    }


    public extractFormFields(menuItem: IMenuDTO): FieldConfigDTO[] {
        if (menuItem.menuType === MenuType.ENTITY_GROUP) {
            if (menuItem.entityGroup) {
                return (menuItem.entityGroup.menuItems[0]?.singleForm?.rows || [])
                    .flatMap(row => {
                        return row.columns?.flatMap(col => {
                            return col.fields
                                //     .filter(fld => {
                                //     if (onlyTableFields === undefined) {
                                //         return true;
                                //     } else if (onlyTableFields) {
                                //         return fld.displayInTable === true;
                                //     } else {
                                //         return fld.displayInTable === undefined || fld.displayInTable === false;
                                //     }
                                // })
                                .flatMap(fld => fld)
                        })
                    }) || []
            }
        }
        return this.extractFields(menuItem, false);
    }

    public extractNonDisplayInTableFields(menuItem: IMenuDTO): FieldConfigDTO[] {
        return this.extractFields(menuItem, false);
    }

    public extractAllFields(menuItem: IMenuDTO): FieldConfigDTO[] {
        return this.extractFields(menuItem);
    }

    public extractFirstChildTab = (menu: IMenuDTO | undefined): string | undefined => {
        if (arrayNotEmpty(menu?.entityGroup?.menuItems)) {
            return menu?.entityGroup?.menuItems[0].code
        }
    }

    public extractFirstChildTabLabel = (menu: IMenuDTO | undefined): string | undefined => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    const title = menu.entityGroup.dataSources[0].definition.title || '';
                    if (title === 'Active') {
                        return undefined;
                    }
                    return title;
                }
            }
        }
        return undefined;
        // if (arrayNotEmpty(menu?.entityGroup?.menuItems)) {
        //     return menu?.entityGroup?.menuItems[0].label
        // }
    }

    public extractBlastCollectionName = (menu: IMenuDTO | undefined): string | undefined => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.collectionName;
                }
            }
        }
        return undefined;
    }

    public extractBlastDeltaCommand = (menu: IMenuDTO | undefined): string | undefined => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.blastDeltaCommand;
                }
            } else if (menu.menuType === MenuType.MAINTAIN) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.blastDeltaCommand;
                }
            }
        }
        return undefined;
    }
    public extractHighlightDelta = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.highlightDelta || false;
                }
            }
        }
        return false;
    }
    public extractHeaderPanels = (menu: IMenuDTO | undefined): PanelConfigDTO [] => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0]?.definition?.panels || [];
                }
            }
        }
        return [];
    }


    public extractDisableTableControls = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.disableTableControls || false;
                }
            }
        }
        return false;
    }

    public extractFixedHeight = (menu: IMenuDTO | undefined): number | undefined => {
        return menu?.fixedHeight;
        // if (menu) {
        //     if (menu.fixedHeight) {
        //         if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
        //             return menu.entityGroup.dataSources[0].definition.disableTableControls || false;
        //         }
        //     }
        // }
        // return undefined;
    }

    public extractDisableColumnResize = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition.disableColumnResize || false;
                }
            }
        }
        return false;
    }

    public extractIsAlphaList = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition?.alphaList || false;
                }
            }
        }
        return false;
    }

    public extractIsAccordionList = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition?.accordionList || false;
                }
            }
        }
        return false;
    }

    public extractIsFlexList = (menu: IMenuDTO | undefined): boolean => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    return menu.entityGroup.dataSources[0].definition?.flexList || false;
                }
            }
        }
        return false;
    }

    public extractSelectAllEndpoint = (menu: IMenuDTO | undefined): string => {
        if (menu) {
            if (menu.menuType === MenuType.ENTITY_GROUP) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    const endpoint: string = menu.entityGroup.dataSources[0].definition.selectUrl;
                    log.info(this.className, 'MSG002', 'extracted endpoint: ' + endpoint + ' from:', menu);
                    return endpoint;
                }
            }
            if (menu.menuType === MenuType.MAINTAIN) {
                if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                    const endpoint: string = menu.entityGroup.dataSources[0].definition?.tabs[0]?.endpoints?.selectAllUrl || '';
                    log.info(this.className, 'MSG002', 'extracted endpoint: ' + endpoint + ' from:', menu);
                    return endpoint;
                }
            }
        }
        log.warn(this.className, 'MSG003', 'failed to extract endpoint', menu);
        return '';
    }

    public extractGetOneEndpoint = (menu: IMenuDTO): string | undefined => {
        if (menu.menuType === MenuType.ENTITY_GROUP) {
            if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                const endpoint: string = menu.entityGroup.selectUrl;
                log.info(this.className, 'MSG004', 'extractGetOneEndpoint ENTITY_GROUP: ' + endpoint + ' from:', menu);
                return endpoint;
            }
        }
        if (menu.menuType === MenuType.ENTITY_GROUP_SINGLE_FORM) {
            const endpoint: string = menu.selectUrl || '';
            log.info(this.className, 'MSG004', 'extractGetOneEndpoint ENTITY_GROUP_SINGLE_FORM: ' + endpoint + ' from:', menu);
            return endpoint;
        }
        if (menu.menuType === MenuType.SINGLE_PAGE) {
            const endpoint: string = menu.singlePage?.selectUrl.url || '';
            // console.log(this.className, 'MSG004', 'extractGetOneEndpoint SINGLE_PAGE: ' + endpoint + ' from:', menu);
            return endpoint;
        }

        if (menu.menuType === MenuType.ACTION_ITEM) {
            return menu.selectUrl || undefined;
        }
        log.warn(this.className, 'MSG005', 'failed to extract endpoint', menu);
        return undefined;
    }

    public extractUpdateEndpoint = (menu: IMenuDTO): string => {
        if (menu.menuType === MenuType.ENTITY_GROUP) {
            if (menu.entityGroup && menu.entityGroup?.dataSources && menu.entityGroup?.dataSources?.length > 0) {
                const endpoint: string = menu.entityGroup.selectUrl;
                log.info(this.className, 'MSG004', 'extractUpdateEndpoint: ' + endpoint + ' from:', menu);
                return endpoint;
            }
        }
        log.warn(this.className, 'MSG005', 'failed to extract endpoint', menu);
        return '';
    }

    /**
     * want to identify the 'panel' name in the data, normally 'details' but could be anything
     * normally, we only have 1 panel, but we can have more than 1
     * also, it may not be a panel and only have 1 field ... which may look like a panel (this would be actions)
     *
     * @param data
     * @param fields
     */
    public extractPrimaryObjectName = (data: any, fields: FieldConfigDTO[]): string | undefined => {
        let count = 0;
        let primaryKey: string | undefined = undefined;
        for (const origKey in data) {
            if (data.hasOwnProperty(origKey)) {
                primaryKey = origKey;
                count++;
            }
        }
        /*
        step 1: we have extracted the only field or last field name and how many top level fields
        {
        details: {
                  name:'foo'
                 }
        }
        so we have extracted 'details'

         */
        // check primary is not a field
        primaryKey = count === 1 ? primaryKey : undefined;

        if (primaryKey && data && isObject(data[primaryKey])) {
            return primaryKey
        } else {
            return undefined
        }

        // if (fields && fields.length > 0) {
        //     fields
        //         .filter((fld: FieldConfigDTO) => {
        //             console.log(fld.name,fld.name === primaryKey,fld.type === 'nested_table',(fld.name === primaryKey || fld.type === 'nested_table') )
        //             return true;
        //         })
        //         .filter((fld: FieldConfigDTO) => fld.name === primaryKey || fld.type === 'nested_table')
        //         .forEach((fld: FieldConfigDTO) => primaryKey = undefined)
        // }
        // return primaryKey
    }

    public extractIsCreateForm = (menuItem: IMenuDTO | undefined): boolean => {
        return (menuItem && (
            (menuItem?.menuType === MenuType.ENTITY_GROUP_SINGLE_FORM && isDefined(menuItem.createForm))
            || (menuItem?.menuType === MenuType.SINGLE_PAGE))) || false
    }


    public extractCanCreateEntity = (menuItem: IMenuDTO | undefined): boolean => {
        let canEdit = false;
        if (menuItem && menuItem.menuType === MenuType.SINGLE_PAGE) {
            if (menuItem.singlePage) {
                if (menuItem.singlePage?.panels) {
                    menuItem.singlePage?.panels
                        .forEach(panel => {
                            if (panel.updateUrl) {
                                canEdit = true;
                            }
                        });
                }
                if (menuItem.singlePage?.rows) {
                    menuItem.singlePage?.rows
                        .forEach(row => {
                            row.columns?.forEach(col => {
                                if (col.updateUrl) {
                                    canEdit = true;
                                }
                            });
                        });
                }
            }
        }
        return canEdit;
    }

    public extractCanEditEntity = (menuItem: IMenuDTO | undefined): boolean => {
        return isDefined(menuItem?.entityGroup?.createConfig) || false;
    }

    public extractCreateEntityMenu = (menuItem: IMenuDTO | undefined): IMenuDTO | undefined => {
        return menuItem?.entityGroup?.createConfig;
    }

    public extractCreateEntityDetails = (menuItem: IMenuDTO | undefined): CreateEntityProperties => {
        if (menuItem?.entityGroup?.createConfig) {
            return {
                canCreate: true,
                code: menuItem?.entityGroup?.createConfig?.code || ('f_' + new Date().getTime()),
                label: menuItem?.entityGroup?.createConfig.label,
                templateUrl: menuItem?.entityGroup?.createConfig.selectUrl,
                updateEndpoint: menuItem?.entityGroup?.createConfig?.singleForm?.updateUrl,
                cancelButton: menuItem?.entityGroup?.createConfig?.singleForm?.cancelButton,
                cancelIcon: menuItem?.entityGroup?.createConfig?.singleForm?.cancelIcon,
                showCancel: menuItem?.entityGroup?.createConfig?.singleForm?.showCancel || true,
                saveIcon: menuItem?.entityGroup?.createConfig?.singleForm?.saveIcon,
                saveButton: menuItem?.entityGroup?.createConfig?.singleForm?.saveButton,
                minModalWidth: menuItem?.entityGroup?.createConfig?.singleForm?.minModalWidth,
                minModalHeight: menuItem?.entityGroup?.createConfig?.singleForm?.minModalHeight,

                // fields: menuItem?.entityGroup?.createConfig?.singleForm?.rows
                //     .flatMap(row => row.columns?.flatMap(col => col.fields.flatMap(fld => fld))) || []
                fields: this.extractAllFieldsFromRows(menuItem?.entityGroup?.createConfig?.singleForm?.rows)
            }
        } else if (menuItem?.createForm) {
            const createForm: CreateFormConfigDTO = menuItem?.createForm;
            log.info(this.className, 'MSG004', 'createForm', createForm);
            return {
                canCreate: true,
                code: 'f_' + new Date().getTime(),
                label: createForm.title,
                templateUrl: createForm.templateUrl?.url,
                updateEndpoint: createForm.insertUrl,
                showCancel: true,
                // cancelButton:createForm.cancelButton,
                // cancelIcon: menuItem?.createForm?.singleForm?.cancelIcon,
                // showCancel: menuItem?.createForm?.singleForm?.showCancel || true,
                // saveIcon: menuItem?.createForm?.singleForm?.saveIcon,
                // saveButton: menuItem?.createForm?.singleForm?.saveButton,
                fields: this.extractAllFieldsFromRows(createForm.rows)
            }
        } else if (menuItem && menuItem.menuType === MenuType.ACTION_ITEM) {
            log.info(this.className, 'MSG004', 'createConfig', menuItem?.singleForm);
            return {
                canCreate: true,
                label: menuItem?.singleForm?.title || '',
                templateUrl: menuItem?.singleForm?.templateUrl,
                updateEndpoint: menuItem?.singleForm?.updateUrl,
                cancelButton: menuItem?.singleForm?.cancelButton,
                cancelIcon: menuItem?.singleForm?.cancelIcon,
                showCancel: menuItem && menuItem.singleForm ? menuItem.singleForm.showCancel : true,
                saveIcon: menuItem?.singleForm?.saveIcon,
                saveButton: menuItem?.singleForm?.saveButton,
                minModalWidth: menuItem?.singleForm?.minModalWidth,
                minModalHeight: menuItem?.singleForm?.minModalHeight,

                fields: menuItem?.singleForm?.rows
                    .flatMap(row => row.columns?.flatMap(col => col.fields.flatMap(fld => fld))) || []
            }
        } else if (menuItem?.singleForm) {
            log.info(this.className, 'MSG004', 'createConfig', menuItem?.entityGroup?.createConfig);
            return {
                canCreate: true,
                label: menuItem.label,
                templateUrl: menuItem.selectUrl,
                updateEndpoint: menuItem?.singleForm?.updateUrl,
                cancelButton: menuItem?.singleForm?.cancelButton,
                cancelIcon: menuItem?.singleForm?.cancelIcon,
                showCancel: menuItem?.singleForm?.showCancel || true,
                saveIcon: menuItem?.singleForm?.saveIcon,
                saveButton: menuItem?.singleForm?.saveButton,
                minModalWidth: menuItem?.singleForm?.minModalWidth,
                minModalHeight: menuItem?.singleForm?.minModalHeight,
                // fields: menuItem?.entityGroup?.createConfig?.singleForm?.rows
                //     .flatMap(row => row.columns?.flatMap(col => col.fields.flatMap(fld => fld))) || []
                fields: this.extractAllFieldsFromRows(menuItem?.singleForm?.rows)
            }

        } else {
            return {canCreate: false, fields: [], showCancel: true}
        }
    }

    public translateLabel = (menuCode: string | undefined, code: string | undefined, defaultValue: string | undefined): string => {
        return translateService.getLabel(menuCode, code, defaultValue);
    }


    public extractActionbarActionsFromPanel = (panel: FormColumnConfigDTO): ActionConfigDTO[] => {
        // console.log('extractMenubarActionsFromPanel panel', panel)
        // const actions: ActionConfigDTO[] = [];
        // panel?.actions?.forEach(action => {
        //
        // })
        return panel?.actions || [];
    }


    /**
     * Deprecated
     * @param panel
     */
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public extractMenubarActionsFromPanel = (
        panel: FormColumnConfigDTO
    ): MenubarItemDTO[] => {
        const menubar: MenubarItemDTO[] = [];
        panel?.actions?.forEach(action => {

        })
        return [];
    }

    public extractMenubarActionsForForm = (
        menuItem: IMenuDTO | undefined,
        editFunction: (event: any) => void
    ): MenubarItemDTO[] => {
        const menubar: MenubarItemDTO[] = [];
        menubar.push({
            label: this.translateLabel('maintain_table', 'edit', 'Edit'),
            icon: 'fa-solid fa-edit',
            command: editFunction
        });
        return menubar;
    }

    public extractMenubarActionsForFormToMenuGroups = (
        user: User,
        menuItem: IMenuDTO | undefined,
        editFunction: (event: any) => void,
        headerData?: any,
        refreshFunction?: (event: any) => void,
        formData?: any
    ): IMenuDTO [] => {
        const menubar: IMenuDTO[] = [];
        if (this.canEdit(user, menuItem, headerData, formData)) {
            menubar.push({
                label: this.translateLabel('maintain_table', 'edit', 'Edit'),
                displayOrder: 10,
                icon: 'fa-solid fa-edit',
                commandFunction: editFunction
            });
        }
        if (refreshFunction) {
            menubar.push({
                label: this.translateLabel('maintain_table', 'refresh', 'Refresh'),
                displayOrder: 10,
                icon: 'fa fa-refresh',
                iconOnly: true,
                commandFunction: refreshFunction
            })
        }

        if (menuItem?.singleForm?.actions) {
            menubar.push(...this.convertActionsToMenuGroup(user, menuItem?.singleForm?.actions, headerData))
        }
        return menubar;
    }

    public extractHeaderRestActionsToMenuGroup = (
        user: User,
        menuItem: IMenuDTO | undefined,
        headerData?: any,
        refreshFunction?: (event: any) => void,
        openCreateNewEntityDialog?: (event: any) => void,
        inlineMenuGroupAction?: (event: React.MouseEvent, item: IMenuDTO) => void,
    ): IMenuDTO[] => {
        const menuGroups: IMenuDTO[] = [];

        if (refreshFunction) {
            menuGroups.push({
                label: this.translateLabel('maintain_table', 'refresh', 'Refresh'),
                displayOrder: 10,
                icon: 'fa fa-refresh',
                iconOnly: true,
                commandFunction: refreshFunction
            })
        }

        // const canCreate = this.extractCanCreateEntity(menuItem);
        // if (canCreate) {
        //     menuGroups.push({
        //         label: this.translateLabel('maintain_table', 'new', 'New'),
        //         displayOrder: 20,
        //         icon: 'fa fa-plus',
        //         iconOnly: true,
        //         commandFunction: openCreateNewEntityDialog
        //     });
        // }

        if (menuItem?.entityGroup?.header && menuItem?.entityGroup?.header?.actions) {
            const actions: IMenuDTO[] = this.convertActionsToMenuGroup(user, (menuItem?.entityGroup?.header?.actions || []), headerData,
                inlineMenuGroupAction);

            const actionsWithChildren: IMenuDTO[] = this.convertChildrenActionsToMenuGroup(user, menuItem?.entityGroup?.header?.actions, headerData,
                inlineMenuGroupAction, headerData);


            if (actions.length === 1) {
                menuGroups.push(actions[0]);
            } else if (actions.length > 1) {
                const icon = actions.length === 1 ? actions[0].icon : 'fa fa-bars'
                const iconOnly = actions.length === 1 ? actions[0].iconOnly : false

                menuGroups.push({
                    label: this.translateLabel('maintain_table', 'Actions', 'Actions'),
                    displayOrder: 30,
                    icon: icon,
                    iconOnly: iconOnly,
                    menuItems: actions
                });
            }
            if (actionsWithChildren.length > 0) {
                menuGroups.push(...actionsWithChildren);
            }
        }

        return menuGroups;
    }
    public extractOverallRestActionsToMenuGroup = (
        user: User,
        menuItem: IMenuDTO | undefined,
        headerData?: any,
        refreshFunction?: (event: any) => void,
        openCreateNewEntityDialog?: (event: any) => void,
        inlineMenuGroupAction?: (event: React.MouseEvent, item: IMenuDTO) => void
    ): IMenuDTO[] => {
        const menuGroups: IMenuDTO[] = [];

        if (refreshFunction) {
            menuGroups.push({
                label: this.translateLabel('maintain_table', 'refresh', 'Refresh'),
                displayOrder: 10,
                icon: 'fa fa-refresh',
                iconOnly: true,
                commandFunction: refreshFunction
            })
        }

        const canCreate = this.extractCanCreateEntity(menuItem);
        if (canCreate) {
            menuGroups.push({
                label: this.translateLabel('maintain_table', 'new', 'New'),
                displayOrder: 20,
                icon: 'fa fa-plus',
                iconOnly: true,
                commandFunction: openCreateNewEntityDialog
            });
        }

        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            if (menuItem?.entityGroup?.dataSources[0].definition.overallRestActions) {
                const actions: IMenuDTO[] = this.convertActionsToMenuGroup(user, (menuItem?.entityGroup?.dataSources[0].definition?.overallRestActions || []), headerData,
                    inlineMenuGroupAction);

                // console.log({actions})
                const actionsWithChildren: IMenuDTO[] = this.convertChildrenActionsToMenuGroup(user, menuItem.entityGroup.dataSources[0].definition.overallRestActions, headerData,
                    inlineMenuGroupAction);


                if (actions.length === 1) {
                    menuGroups.push(actions[0]);
                } else if (actions.length > 1) {
                    const icon = actions.length === 1 ? actions[0].icon : 'fa fa-bars'
                    const iconOnly = actions.length === 1 ? actions[0].iconOnly : false

                    menuGroups.push({
                        label: this.translateLabel('maintain_table', 'Actions', 'Actions'),
                        displayOrder: 30,
                        icon: icon,
                        iconOnly: iconOnly,
                        menuItems: actions
                    });
                }
                if (actionsWithChildren.length > 0) {
                    menuGroups.push(...actionsWithChildren);
                }
            }
        }

        return menuGroups;
    }

    public extractSummaryActionsToMenuGroup = (
        user: User,
        actionConfigs: ActionConfigDTO[] | undefined,
        refreshFunction?: (event: any) => void,
        openCreateNewEntityDialog?: (event: any) => void,
        inlineMenuGroupAction?: (event: React.MouseEvent, item: IMenuDTO) => void,
        createConfig?: IMenuDTO,
        performDownloadWithoutPromptAction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void,
        performActionOnly?: (action?: ActionConfigDTO, row?: TableRowDTO) => void
    ): IMenuDTO[] => {
        const menuGroups: IMenuDTO[] = [];
        if (!actionConfigs) {
            return menuGroups;
        }

        if (refreshFunction) {
            menuGroups.push({
                label: this.translateLabel('maintain_table', 'refresh', 'Refresh'),
                displayOrder: 10,
                icon: 'fa fa-refresh',
                iconOnly: true,
                commandFunction: refreshFunction
            })
        }

        if (createConfig) {
            menuGroups.push({
                label: this.translateLabel('maintain_table', 'new', 'New'),
                displayOrder: 20,
                icon: 'fa fa-plus',
                iconOnly: false,
                commandFunction: openCreateNewEntityDialog
            });
        }

        const actions: IMenuDTO[] = this.convertActionsToMenuGroup(user, actionConfigs || [], {}, inlineMenuGroupAction, performDownloadWithoutPromptAction, performActionOnly);

        const actionsWithChildren: IMenuDTO[] = this.convertChildrenActionsToMenuGroup(user, actionConfigs, {}, inlineMenuGroupAction, performDownloadWithoutPromptAction, performActionOnly);


        if (actions.length === 1) {
            menuGroups.push(actions[0]);
        } else if (actions.length > 1) {
            const icon = actions.length === 1 ? actions[0].icon : 'fa fa-bars'
            const iconOnly = actions.length === 1 ? actions[0].iconOnly : false

            menuGroups.push({
                label: this.translateLabel('maintain_table', 'Actions', 'Actions'),
                displayOrder: 30,
                icon: icon,
                iconOnly: iconOnly,
                menuItems: actions
            });
        }
        if (actionsWithChildren.length > 0) {
            menuGroups.push(...actionsWithChildren);
        }
        return menuGroups;
    }

    public extractOverallActions = (
        menuItem: IMenuDTO | undefined,
        performAction: (event: any) => void,
        performDialogAction: (event: any) => void
    ): MenubarItemDTO[] => {
        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            if (menuItem?.entityGroup?.dataSources[0].definition.overallRestActions) {
                return this.convertActionsToMenu((menuItem?.entityGroup?.dataSources[0]?.definition?.overallRestActions || []), performAction, performDialogAction);
            }
        }
        return [];
    }

    public onMobileDevice = (): boolean => {
        return Capacitor.isNativePlatform() || window.innerWidth < 1025;
    }
    public extractRowActionsFlattenedForRow = (
        row: TableRowDTO,
        actionConfigs: ActionConfigDTO[] | undefined,
        performDownloadWithoutPromptAction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void,
        actionOnlyFunction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void
    ): ActionConfigDTO[] => {
        const actions: ActionConfigDTO[] = [];
        let idx = 0;
        if (actionConfigs) {
            // to do, should only add action if passes condition
            const isMobile = this.onMobileDevice();
            actionConfigs
                // hide download if mobile
                .filter(action => isMobile ? !(action.actionType === ActionType.DOWNLOAD_NO_PROMPT) : true)
                .forEach(action => {
                    idx++
                    const rowAction = {...action, id: 'key_' + idx + '_' + row.id, rowId: row.id} as ActionConfigDTO
                    rowAction.downloadFunction = action.actionType === ActionType.DOWNLOAD_NO_PROMPT ? performDownloadWithoutPromptAction : undefined
                    rowAction.actionOnlyFunction = action.actionType === ActionType.ACTION_ONLY ? actionOnlyFunction : undefined
                    actions.push(rowAction)
                })
        }
        return actions;
    }

    public extractTableActionsFromMenuItem = (menuItem: IMenuDTO | undefined): ActionConfigDTO[] => {
        const actionConfigs: ActionConfigDTO[] = (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0 &&
            menuItem?.entityGroup?.dataSources[0].definition?.overallRestActions &&
            menuItem?.entityGroup?.dataSources[0].definition?.overallRestActions) || [];
        return actionConfigs;
    }

    public extractRowActionsFromMenuItem = (menuItem: IMenuDTO | undefined): ActionConfigDTO[] => {
        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            let rowActions = menuItem?.entityGroup?.dataSources[0].definition?.restActions || []
            // let rowActions = []
            if (menuItem?.entityGroup?.dataSources[0].definition?.rowActions) {
                rowActions.push(...menuItem?.entityGroup?.dataSources[0].definition?.rowActions || [])
            }
            return rowActions
        } else {
            return []
        }
    }

    public extractOrderByActionsFromMenuItem = (menuItem: IMenuDTO | undefined): OrderByActionDO[] => {
        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            let actions: OrderByActionDO[] = []
            if (menuItem?.entityGroup?.dataSources[0].definition?.orderByActions) {
                actions.push(...menuItem?.entityGroup?.dataSources[0].definition?.orderByActions || [])
            }
            return actions
        } else {
            return []
        }
    }

    public extractGroupActionsFromMenuItem = (menuItem: IMenuDTO | undefined): ActionConfigDTO[] => {
        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            let actions = [] // menuItem?.entityGroup?.dataSources[0].definition?.groupActions || []
            // let rowActions = []
            if (menuItem?.entityGroup?.dataSources[0].definition?.groupActions) {
                actions.push(...menuItem?.entityGroup?.dataSources[0].definition?.groupActions || [])
            }
            return actions
        } else {
            return []
        }
    }


    public extractActionsFlattenedForRow = (row: TableRowDTO, menuItem: IMenuDTO | undefined): ActionConfigDTO[] => {
        let actions: ActionConfigDTO[] = [];

        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            menuItem?.entityGroup?.dataSources[0].definition?.restActions &&
            menuItem?.entityGroup?.dataSources[0].definition?.restActions.forEach((action, idx) => {
                if (action.children) {
                    action.children.forEach(child => actions.push({...child, id: row.id}))
                } else {
                    actions.push({...action, id: 'key_' + idx + '_' + row.id});
                }
            })
        }
        return actions;
    }


    public extractRowActions = (
        menuItem: IMenuDTO | undefined,
        viewFunction?: (event: any) => void,
        editFunction?: (event: any) => void,
        performAction?: (event: any) => void,
        performDialogAction?: (event: any) => void,
        rowData?: any
    ): MenubarItemDTO[] => {
        const menubar: MenubarItemDTO[] = [];
        let actions: MenubarItemDTO[] = [];
        let actionsWithChildren: MenubarItemDTO[] = [];

        if (menuItem?.entityGroup?.dataSources && menuItem?.entityGroup?.dataSources.length > 0) {
            if (menuItem?.entityGroup?.dataSources[0].definition.restActions) {
                actions = this.convertActionsToMenu(menuItem.entityGroup.dataSources[0].definition.restActions, performAction, performDialogAction);
                actionsWithChildren = this.convertChildrenActionsToMenu(menuItem.entityGroup.dataSources[0].definition.restActions, performAction, performDialogAction);
            }
        }

        if (actions.length > 0) {
            menubar.push(
                {
                    label: this.translateLabel('maintain_table', 'actions', 'Actions'),
                    icon: '',
                    items: actions
                }
            );
        }
        if (actionsWithChildren.length > 0) {
            menubar.push(...actionsWithChildren);
        }
        return menubar;
    }

    public convertActionsToMenu = (
        actionConfigs: ActionConfigDTO[],
        performAction?: (event: any) => void,
        performDialogAction?: (event: any) => void
    ): MenubarItemDTO[] => {
        const actions: MenubarItemDTO[] = [];
        actionConfigs
            .filter(actionConfig => arrayIsEmpty(actionConfig.children))
            .forEach(actionConfig => {
                actions.push(
                    {
                        label: actionConfig.buttonLabel,
                        icon: actionConfig.icon, // 'fa fa-check',
                        command: actionConfig.rows ? performDialogAction : performAction,
                        actionConfig: actionConfig
                    } as MenubarItemDTO
                );

            });
        return actions;
    }

    public convertChildrenActionsToMenu = (
        actionConfigs: ActionConfigDTO[],
        performAction?: (event: any) => void,
        performDialogAction?: (event: any) => void
    ): MenubarItemDTO[] => {
        const menuItems: MenubarItemDTO[] = [];
        actionConfigs
            .filter(actionConfig => arrayNotEmpty(actionConfig.children))
            .forEach(actionConfig => {
                const actions: MenubarItemDTO[] = this.convertActionsToMenu(actionConfig.children, performAction, performDialogAction);

                if (actions.length > 0) {
                    menuItems.push(
                        {
                            label: actionConfig.parentMenuLabel,
                            icon: actionConfig.icon, // 'fa fa-check',
                            items: actions
                        } as MenubarItemDTO
                    );
                }

            });
        return menuItems;
    }

    public convertActionsToMenuGroup = (
        user: User,
        actionConfigs: ActionConfigDTO[],
        headerData?: any,
        inlineMenuGroupAction?: (event: React.MouseEvent, item: IMenuDTO) => void,
        performDownloadWithoutPromptAction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void,
        performActionOnly?: (action?: ActionConfigDTO, row?: TableRowDTO) => void,
    ): IMenuDTO[] => {
        const menuGroups: IMenuDTO[] = [];
        const isMobile = this.onMobileDevice();

        (actionConfigs || []).filter(actionConfig => isDefined(actionConfig))
            .filter(actionConfig => arrayIsEmpty(actionConfig?.children))
            // hide download if mobile
            .filter(actionConfig => isMobile ? !(actionConfig.actionType === ActionType.DOWNLOAD_NO_PROMPT) : true)
            .filter(actionConfig => {
                if (actionConfig.jsEditCondition) {
                    return this.passesCondition(actionConfig, headerData, user);
                }

                return true;
            })
            .forEach(actionConfig => {
                menuGroups.push(
                    {
                        label: actionConfig.buttonLabel,
                        displayOrder: actionConfig.displayOrder,
                        icon: actionConfig.icon,
                        iconOnly: actionConfig.iconOnly,
                        commandFunction: actionConfig.inLine ? inlineMenuGroupAction : undefined,
                        downloadFunction: actionConfig.actionType === ActionType.DOWNLOAD_NO_PROMPT ? performDownloadWithoutPromptAction : undefined,
                        actionOnlyFunction: actionConfig.actionType === ActionType.ACTION_ONLY ? performActionOnly : undefined,
                        action: actionConfig
                    } as IMenuDTO
                );
            });
        return menuGroups;
    }
    public convertChildrenActionsToMenuGroup = (
        user: User,
        actionConfigs: ActionConfigDTO[],
        headerData?: any,
        inlineMenuGroupAction?: (event: React.MouseEvent, item: IMenuDTO) => void,
        performDownloadWithoutPromptAction?: (action?: ActionConfigDTO, row?: TableRowDTO) => void,
        performActionOnly?: (action?: ActionConfigDTO, row?: TableRowDTO) => void
    ): IMenuDTO[] => {
        const menuItems: IMenuDTO[] = [];
        actionConfigs
            .filter(actionConfig => arrayNotEmpty(actionConfig.children))
            .forEach(actionConfig => {
                const menuGroups: IMenuDTO[] = this.convertActionsToMenuGroup(user, actionConfig.children, headerData, inlineMenuGroupAction, performDownloadWithoutPromptAction);
                if (menuGroups.length > 0) {
                    menuItems.push(
                        {
                            label: actionConfig.parentMenuLabel,
                            icon: actionConfig.icon,
                            displayOrder: actionConfig.displayOrder,
                            iconOnly: actionConfig.iconOnly,
                            menuItems: menuGroups,
                            commandFunction: inlineMenuGroupAction,
                            action: actionConfig,
                            downloadFunction: performDownloadWithoutPromptAction,
                            actionOnlyFunction: performActionOnly
                        }
                    );
                }

            });
        return menuItems;
    }

    public inCurrentOrderBy(field: FieldConfigDTO, queryParams: QueryParamsDTO | undefined): boolean {
        if (!queryParams || !field || !queryParams.orderBys) {
            return false;
        }
        return queryParams.orderBys.filter(orderBy => orderBy.field === field.name || (field.sortableField && orderBy.field === field.sortableField)).length > 0
    }

    public isAscOrderBy(field: FieldConfigDTO, queryParams: QueryParamsDTO): boolean {
        return this.isOrderBy(field, queryParams, 'asc')
    }

    public isDescOrderBy(field: FieldConfigDTO, queryParams: QueryParamsDTO): boolean {
        return this.isOrderBy(field, queryParams, 'desc')
    }

    public getOrderBy(field: FieldConfigDTO): OrderByDTO[] {
        return [{
            field: field.sortableField || field.name,
            order: 'ASC'
        }]
    }

    public getOrderByWithOrder(field: FieldConfigDTO, order: string): OrderByDTO[] {
        return [{
            field: field.sortableField || field.name,
            order: order
        }]
    }

    public toggleOrderBy(field: FieldConfigDTO, tableState: QueryParamsDTO | undefined): OrderByDTO[] {
        if (tableState && tableState.orderBys && tableState.orderBys.length > 0) {
            return [{
                field: field.sortableField || field.name,
                order: tableState.orderBys[0].order === 'ASC' ? 'DESC' : 'ASC'
            }]
        }
        return [];
    }

    public isOrderBy(field: FieldConfigDTO, queryParams: QueryParamsDTO, order: string): boolean {
        if (!queryParams || !field || !queryParams.orderBys || !order) {
            return false;
        }
        let matchingOrderBys = queryParams.orderBys.filter(orderBy => orderBy.field === field.name || (field.sortableField && orderBy.field === field.sortableField));
        if (matchingOrderBys.length > 0 && matchingOrderBys[0].order) {
            return matchingOrderBys[0].order.toLowerCase() === order.toLowerCase()
        } else {
            return false;
        }
    }

    public getTitle(row: any, label?: string): string {
        let title = (label || '');
        if (row) {
            Object.keys(row)
                .filter((k) => !!row[k])
                .forEach(k => {
                    title = title.replace(`\${${k}}`, row[k]);
                });
        }
        return title;
    }

    public translateButton(code: string | undefined, defaultValue: string | undefined): string {
        return translateService.getLabel('maintain_form', code, defaultValue);
    }

    public getCancelIcon(properties?: CreateEntityProperties): string {
        return properties?.cancelIcon || 'fas fa-ban'
    }

    public getSaveIcon(properties?: CreateEntityProperties): string {
        return properties?.saveIcon || 'fas fa-check'
    }

    public getIconForSave(icon: string | undefined): string {
        return icon || 'fas fa-check'
    }

    public getIconForCancel(icon: string | undefined): string {
        return icon || 'fas fa-ban'
    }

    public getLabelForSave(label: string | undefined): string {
        return label || this.translateButton('ok', 'OK');
    }

    public getLabelForCancel(label: string | undefined): string {
        return label || this.translateButton('cancel', 'Cancel');
    }

    public getCancelLabel(properties?: CreateEntityProperties): string {
        if (properties?.cancelButton) {
            return properties.cancelButton
        }
        return this.translateButton('cancel', 'Cancel');
    }

    public getSaveLabel(formMode: FormMode, properties?: CreateEntityProperties): string {
        if (properties?.saveButton) {
            return properties.saveButton
        }

        if (formMode === FormMode.ACTION_DIALOG) {
            return this.translateButton('proceed', 'Proceed');
        } else {
            return this.translateButton('save', 'Save');
        }
    }

    public getSelectUrl(action: ActionConfigDTO): string {
        switch (action.actionType) {
            case ActionType.ENTRY:
                return action.templateUrl;
            default:
                return action.selectUrl;

        }
    }

    public getFormModeForAction(action?: ActionConfigDTO): FormMode {
        if (action?.actionType) {
            switch (action.actionType) {
                case ActionType.ENTRY:
                    return FormMode.NEW;
                case ActionType.EDIT:
                    return FormMode.EDIT;
                case ActionType.VIEW_WITH_ACTION:
                case ActionType.DOWNLOAD_WITH_PROMPT:
                    return FormMode.VIEW_WITH_ACTION;
                case ActionType.DELETE:
                    return FormMode.DELETE;
                default:
                case ActionType.VIEW_EDIT:
                case ActionType.VIEW_ONLY:
                    return FormMode.VIEW;
            }
        } else {
            return FormMode.VIEW;
        }
    }

    public getCanEditForAction(action?: ActionConfigDTO): boolean {
        if (action?.actionType) {
            switch (action.actionType) {
                case ActionType.VIEW_ONLY:
                case ActionType.VIEW_WITH_ACTION:
                case ActionType.DELETE:
                case ActionType.DOWNLOAD_WITH_PROMPT:
                case ActionType.DOWNLOAD_NO_PROMPT:
                    return false;
                default:
                case ActionType.ENTRY:
                case ActionType.VIEW_EDIT:
                    return true
            }
        } else {
            return true
        }
    }

    public extractExpandableRowConfig(menuItem: IMenuDTO | undefined): ConditionalRowDetailsDTO | undefined {
        if ((menuItem?.entityGroup?.dataSources || []).length > 0
            && ((menuItem?.entityGroup?.dataSources[0].definition.tabs || []).length > 0)
            && ((menuItem?.entityGroup?.dataSources[0].definition.tabs[0].tableView.conditionals || []).length > 0)) {
            // @ts-ignore
            return menuItem?.entityGroup?.dataSources[0]?.definition?.tabs[0]?.tableView?.conditionals[0] || undefined;
        }
        return undefined;
    }

    public passesCondition(action: ActionConfigDTO, row: TableRowDTO, loggedInUser: User) {
        if (isBlank(action.jsEditCondition)) {
            return true;
        }
        let functionCode: string = 'return true'
        if (action.jsEditCondition.startsWith('data.') || action.jsEditCondition.startsWith('data?.')) {
            // MK 28/08/2023 disabled logging
            functionCode = `
           //console.log('loggedInUser',loggedInUser,data,"${action.jsEditCondition}");
            return ${action.jsEditCondition}
            `
        } else if (action.jsEditCondition.startsWith('loggedInUser')) {
            functionCode = `
            // console.log('loggedInUser',loggedInUser,data,"${action.jsEditCondition}");
            return ${action.jsEditCondition}
            `
        } else {
            functionCode = `
            // console.log('function: loggedInUser',loggedInUser,data,"${action.jsEditCondition}");
            return data.${action.jsEditCondition}
            `
        }
        try {
            /* eslint-disable no-new-func */
            const func = new Function("self", "data", "loggedInUser", functionCode);
            const val = func(this, row, loggedInUser);
            if (val === "undefined") {
                return false;
            }
            return val;
        } catch (error) {
            // console.log('error', error)
            // log.error("MaintainTableService", "ERR001", "Failed to evaluate", error, row, loggedInUser, action.jsEditCondition);
            return false;
        }
    };

    public passesJsCondition(jsEditCondition: string | undefined, data: any, loggedInUser: User) {
        if (!jsEditCondition || isBlank(jsEditCondition)) {
            return true;
        }
        let functionCode: string = 'return true'
        if (jsEditCondition.startsWith('data.') || jsEditCondition.startsWith('data?.')) {
            // MK 28/08/2023 disabled logging
            functionCode = `
           //console.log('loggedInUser',loggedInUser,data,"${jsEditCondition}");
            return ${jsEditCondition}
            `
        } else if (jsEditCondition.startsWith('loggedInUser')) {
            functionCode = `
            // console.log('loggedInUser',loggedInUser,data,"${jsEditCondition}");
            return ${jsEditCondition}
            `
        } else {
            functionCode = `
            // console.log('function: loggedInUser',loggedInUser,data,"${jsEditCondition}");
            return data.${jsEditCondition}
            `
        }
        try {
            /* eslint-disable no-new-func */
            const func = new Function("self", "data", "loggedInUser", functionCode);
            const val = func(this, data, loggedInUser);
            if (val === "undefined") {
                return false;
            }
            return val;
        } catch (error) {
            // console.log('error', error)
            // log.error("MaintainTableService", "ERR001", "Failed to evaluate", error, row, loggedInUser, action.jsEditCondition);
            return false;
        }
    }


    public isNarrowCell(field: FieldConfigDTO) {
        const narrowCells: string[] = ['bs', 'movement'];

        return narrowCells.indexOf(field.name) !== -1;
    }

    public createCodeFromUrl() {
        return window.location.pathname.split('/').join('');
    }

    public getCodeFromUrl() {
        try {
            const split: string[] = window.location.pathname.split('/');
            return split[split.length - 1];
        } catch (e) {
            return window.location.pathname;
        }
    }

    public getFirstPartFromUrl() {
        try {
            const split: string[] = window.location.pathname.split('/');
            return split[1];
        } catch (e) {
            return window.location.pathname;
        }
    }

    public extractMenuItemsFromMenuGroups(items: IMenuDTO[]) {
        const _menuItems: IMenuDTO[] = [];
        items.forEach((item) => item.menuItems?.forEach((menuItem) => {
            _menuItems.push(menuItem)
        }))
        return _menuItems;
    }

    public extractTextAlign(fld: FieldConfigDTO): string {
        return this.extractTextAlignWithFormMode(fld, FormMode.VIEW)
    }


    public extractTextAlignWithFormMode(fld: FieldConfigDTO, formMode?: FormMode): string {
        if (!fld) {
            return 'left'
        }

        if (fld.type === 'cohort_logo' || fld.type === 'circle') {
            return 'center'
        }

        if (fld.type === 'checkbox') {
            if (formMode === FormMode.NEW || formMode === FormMode.EDIT) {
                return 'left';
            }
        }

        if (fld.type === 'money' || fld.type === 'number') {
            if (formMode === FormMode.NEW || formMode === FormMode.EDIT) {
                return 'left';
            } else {
                return 'right';
            }
        }

        if (notBlank(fld.align) && fld.type !== 'percent') {
            return fld.align || 'left'
        }
        if (fld.tableCss && fld.tableCss.indexOf('textAlign')) {
            try {
                const style = JSON.parse(fld.tableCss);
                return style('textAlign')
            } catch (error) {
                return 'left'
            }
        }
        if (fld.type === 'percent') {
            return 'right';
        }

        return 'left'
    }

    public defaultTableControl(): TableControlsDTO {

        return {
            pagination: MediaType.Desktop,
            resize: MediaType.Desktop,
            filters: MediaType.Desktop,
            search: MediaType.Desktop,
            refresh: MediaType.Desktop,
            columnSettings: MediaType.Desktop,
            infinityScroll: false
        }
    }

    public extractTableControls(menuItem?: IMenuDTO): TableControlsDTO {
        let tableControl: TableControlsDTO = this.defaultTableControl();
        if (menuItem?.entityGroup && menuItem?.entityGroup?.dataSources && menuItem.entityGroup?.dataSources?.length > 0) {
            if (menuItem.entityGroup?.dataSources[0].definition.tableControl) {
                tableControl = menuItem.entityGroup?.dataSources[0].definition.tableControl
            }
        }
        return tableControl;
    }

    // public createDistinctValuesForFilters({content, filterData}: DistinctValuesDO) {
    //     const textFilterFields = filterData.textFilterFields.map(field => field.name);
    //     const _tempValues: { [index: string]: { [index: string]: number } } = {};
    //
    //
    //     if (typeof content === 'undefined') {
    //         return filterData;
    //     }
    //     content.forEach((row) => {
    //         textFilterFields.forEach((field) => {
    //             /*@ts-ignore*/
    //             const value = row[field];
    //
    //             if (!_tempValues[field]) {
    //                 _tempValues[field] = {[value]: 1};
    //             } else if (!_tempValues[field][value]) {
    //                 _tempValues[field][value] = 1;
    //             } else {
    //                 _tempValues[field][value]++;
    //             }
    //
    //         })
    //     })
    //
    //     Object.keys(_tempValues).forEach((name) => {
    //
    //         let fieldValues: FilterValueDO[] = []
    //         Object.entries(_tempValues[name]).forEach((entry) => {
    //             fieldValues.push({value: entry[0], count: entry[1]})
    //         })
    //         filterData.textFilterFields.filter((filter) => filter.name === name)[0].values = fieldValues;
    //     })
    //     return filterData
    // }


    private canEdit(user: User, menuItem: IMenuDTO | undefined, headerData?: any, formData?: any) {
        if (!menuItem || !menuItem.singleForm?.rows || menuItem.singleForm?.rows?.length === 0 || !menuItem.singleForm?.rows[0].columns || menuItem.singleForm?.rows[0].columns?.length === 0) {
            return false;
        }
        return this.passesJsCondition(menuItem.singleForm?.rows[0].columns[0].jsEditCondition, formData, user)
    }
}

const maintainTableService = new MaintainTableService();

export {maintainTableService};

export default MaintainTableService;
