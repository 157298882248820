import React, {useContext, useEffect, useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../../../app/hooks";
import {EnvironmentContext} from '../../../../../providers/environment/EnvironmentContext';
import {GuslThemeContext} from "../../../../../providers/theme/GuslThemeProvider";
import {FieldConfigDTO, QueryParamsDTO, WidgetPanelProperties} from "../../../../types";
import {ElementWithTooltip} from "../../../element-with/element-with-tooltip/ElementWithTooltip";
import GetDefaultSearchableFields from "../../GetDefaultSerarchableFields";
import {resetSearchableField, setSearchString, toggleSearchableField} from "../../guslTableSlice";
import {
    GuslTableQueryParamState,
    refreshListView,
    resetSearchable,
    setSearchable,
    toggleFuzzySearch
} from "../../queryParamsSlice"
import {FuzzySearchStyled, SearchDropdownItemStyled, SearchIconStyled} from "../../styled/search-box/styled";
import {WarningMsgStyled} from "../../styled/table-controls/styled";
import {SearchboxContainerStyled, SearchboxWrapperStyled} from './styled';

export interface SearchBoxProperties {
    code: string;

    searchableFields: FieldConfigDTO[];
    searchString: string;
    searchPrompt: string
    splitView?: boolean;

    onSearchValueChange?: (value: string | undefined) => void;

    showFieldSelect?: boolean;
    widgetPanelProperties?: WidgetPanelProperties

}

export default function SearchBox({
                                      code,
                                      searchableFields,
                                      searchPrompt,
                                      splitView,
                                      onSearchValueChange,
                                      showFieldSelect = true,
                                      widgetPanelProperties
                                  }: SearchBoxProperties) {
    const [className] = useState<string>('SearchBox-' + new Date().getTime());
    const dispatch = useAppDispatch();
    const searchBoxRef = useRef<HTMLInputElement>(null);
    // const searchableFields: FieldConfigDTO[] = state.searchableFields;
    const [missingSearchTerm, setMissingSearchTerm] = useState<boolean>(false);
    const [missingSearchField, setMissingSearchField] = useState<boolean>(false);
    const guslThemeContext = useContext(GuslThemeContext);
    const environmentContext = React.useContext(EnvironmentContext);
    const [themeColors] = useState(guslThemeContext.getCurrentTheme(environmentContext.getStoragePrefix()));
    const queryParamsState: GuslTableQueryParamState = useAppSelector(state => state.queryParamsSlice[code]);
    // const [guslTableSearchString, setGuslTableSearchString] = useState(state.searchString)
    const [searchString] = useState<string | undefined>('');
    const [fuzzy, setFuzzy] = useState<boolean | undefined>(queryParamsState?.queryParams?.fuzzy);

    const queryParams: QueryParamsDTO = queryParamsState?.queryParams;
    const should: QueryParamsDTO["should"] = queryParams?.should;
    useEffect(() => {
        if (should && should.length > 0 && typeof queryParams?.fuzzy === "undefined") {
            setFuzzy(typeof should[0].fuzzy === "undefined" ? true : should[0].fuzzy)
        } else if ((typeof should === "undefined" || should?.length === 0) && typeof queryParams?.fuzzy === "undefined") {
            setFuzzy(true)
        } else {
            setFuzzy(queryParams.fuzzy)
        }

    }, [should, queryParams]);
    const defaultSearchableFields = GetDefaultSearchableFields({code});
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties);
    const querySearchString = queryParamsState?.queryParams?.searchString || '';

    const [haveNoAuto] = useState<boolean>(() => {
        let found: boolean = false;
        (searchableFields || []).filter(fld => fld.noAutoSearch)
            .forEach(fld => found = true)
        return found;
    });

    const haveFieldSelected = (): boolean => {
        let found: boolean = false;
        (searchableFields || []).filter(fld => fld.searchableSelected)
            .forEach(fld => found = true)
        return found;
    }

    const selectFieldsHandler = (field: FieldConfigDTO) => {
        dispatch(toggleSearchableField({code: code, field: field}));
        dispatch(setSearchString({code, searchString: searchBoxRef.current?.value}));
        setMissingSearchTerm(false);
        setMissingSearchField(false);
        if (onSearchValueChange) {
            onSearchValueChange(searchBoxRef.current?.value)
        }
    }

    const resetSearchHandler = () => {
        // @ts-ignore
        document!.getElementById(("search_" + code))!.value = "";
        dispatch(resetSearchable({code}));
        dispatch(resetSearchableField({code, defaultSearchableFields}));
        dispatch(setSearchString({code, searchString: ''}));
        setMissingSearchTerm(false);
        setMissingSearchField(false);
        if (onSearchValueChange) {
            onSearchValueChange(undefined)
        }

    }

    function sendSearchQueryHandler() {
        if (!searchBoxRef.current?.value) {
            setMissingSearchTerm(true);
            return
        } else {
            setMissingSearchTerm(false);
        }
        if (!haveFieldSelected()) {
            setMissingSearchField(true);
            return
        } else {
            setMissingSearchField(false);
        }

        if (searchBoxRef.current?.value && haveFieldSelected()) {
            setMissingSearchTerm(false);
            setMissingSearchField(false);
            dispatch(setSearchable({code: code, fields: searchableFields, searchValue: searchBoxRef.current.value}));
            dispatch(setSearchString({code, searchString: searchBoxRef.current.value}))
            if (onSearchValueChange) {
                onSearchValueChange(searchBoxRef.current?.value)
            }

        }
    }

    function SearchableFieldsDropdown() {
        return (
            <span className={"m-0 p-0 " + (isMobile ? "" : "input-group-text")}
                  id={"search_box_" + code}>
                    <Dropdown className={isMobile ? 'row g-0' : ''}>
                        <Dropdown.Toggle
                            className={'rounded-0 border-0 text-start ' + (isMobile ? 'mb-1' : '')}
                            style={{
                                color: themeColors.colors.dark[1],
                                backgroundColor: themeColors.colors.dark[0],
                            }}
                            id={'searchable_fields_' + code}>
                            {searchPrompt}

                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'end'}
                                       className={'m-0 ' + (isMobile ? 'p-0 rounded-0' : 'p-1 border-0')}
                                       style={{
                                           backgroundColor: themeColors.colors.dark[0],

                                       }}>
                            {(searchableFields || []).map((field, idx) =>
                                <Dropdown.Item
                                    key={idx}
                                    className={'p-0 m-0'}
                                >
                                    <SearchDropdownItemStyled role={'button'}
                                                              onClick={() => selectFieldsHandler(field)}
                                                              className={'d-flex justify-content-between px-1 ' + (isMobile ? ' py-2' : '')}
                                                              active={field.searchable}
                                    >
                                        {(field.searchableSelected) ?
                                            <span className={" px-2"}>&#x2713;</span> :
                                            <span className={" px-2"}>&#x2717;</span>}
                                        <span> {field.label} </span>
                                    </SearchDropdownItemStyled>
                                </Dropdown.Item>
                            )}
                            <FuzzySearchStyled role={'button'}
                                               onClick={() => {
                                                   dispatch(toggleFuzzySearch({code}))
                                               }}
                                               className={'d-flex justify-content-between px-1 ' + (isMobile ? ' py-2' : '')}
                                               active={fuzzy}
                            >
                                  {fuzzy ?
                                      <span className={" px-2"}>&#x2713;</span> :
                                      <span className={" px-2"}>&#x2717;</span>}
                                <span>  fuzzy search</span>
                             </FuzzySearchStyled>
                        </Dropdown.Menu>
                </Dropdown>
            </span>
        );
    }

    const onDataInputChange = (value: string) => {
        if (!haveNoAuto) {
            if (value && value.length >= 1) {
                sendSearchQueryHandler()
            } else {
                resetSearchHandler()
            }
        }
    }

    return (

        <SearchboxContainerStyled>
            <div> {isMobile && showFieldSelect &&
                <SearchableFieldsDropdown/>
            }</div>
            <SearchboxWrapperStyled>
                <input type="text"
                       autoFocus
                       ref={searchBoxRef}
                       id={'search_' + code}
                       defaultValue={searchString || searchString || querySearchString}
                       className="form-control border-0 rounded-0"
                       placeholder={'Search'}
                       aria-label="Search..."
                       aria-describedby={"search_box_" + code}
                       onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                               sendSearchQueryHandler()
                           }
                       }}
                       onChange={(e) => onDataInputChange(e.target.value)}

                />

                {!isMobile && showFieldSelect && <SearchableFieldsDropdown/>}

                <SearchIconStyled
                    className={"input-group-text border-0"}
                    haveNoAuto={haveNoAuto}
                    role={haveFieldSelected() ? 'button' : ''}
                    onClick={sendSearchQueryHandler}
                    id={"search_box2_" + code}
                    active={true}
                >
                    <i className={'fa-solid fa-search'}/>
                </SearchIconStyled>
                <SearchIconStyled
                    className={"input-group-text border-0"}
                    role={'button'}
                    onClick={resetSearchHandler}
                    id={"reset_" + code}
                    active={true}
                >
                    <ElementWithTooltip
                        element={<i className={'fa-solid fa-close'}/>}
                        tooltip={<span>Reset search.</span>}/>
                </SearchIconStyled>
                {splitView && <SearchIconStyled
                    className={"input-group-text border-0"}
                    role={'button'}
                    onClick={() => {
                        dispatch(refreshListView({code}));
                    }}
                    id={"reset_" + code}
                    active={true}
                >
                    <ElementWithTooltip
                        element={<i className={'fa-solid fa-refresh'}/>}
                        tooltip={<span>Refresh data.</span>}/>
                </SearchIconStyled>}
            </SearchboxWrapperStyled>

            <div className="text-start  small ">
                {missingSearchTerm && <WarningMsgStyled>Missing search term</WarningMsgStyled>}
                {missingSearchField && <WarningMsgStyled>Missing search field</WarningMsgStyled>}
            </div>

        </SearchboxContainerStyled>

    );
}
