import {isArray} from "chart.js/helpers";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {BlastContext} from "../../../providers/blast/BlastContext";
import {EnvironmentContext} from "../../../providers/environment/EnvironmentContext";
import {SessionContext} from "../../../providers/session/SessionContext";
import {SystemContext} from "../../../providers/system/SystemContext";
import {log} from "../../../services/LogService";
import {getSettings, shouldHide, validate} from "../../../services/ValidationService";
import {isDefined, isObject, isString} from "../../../utils/TypeCheckers";
import {
    areFieldsMediaTypeSensitive,
    buildPathFromRef,
    constructUrl,
    matchMediaTypeWithField,
    replaceSpaceWithDash,
    RunOnceEffect,
    safeStream,
    unSubscribe
} from "../../../utils/Utils";
import {fieldService} from "../../FieldService";
import {
    FieldConfigDTO,
    FieldPositionProperties,
    FieldProperties,
    FormMode,
    LookupConfigDTO,
    MediaType
} from "../../types";
import {handleResize, handleWindowChange, initFieldPositionProperties} from "../../ui/FieldUtils";
import {TableContentStyled, TableHeaderRowStyled} from "../date-summary/styled";
import {ElementWithTooltip} from "../element-with/element-with-tooltip/ElementWithTooltip";
import FieldLabel from "../field-label/FieldLabel";
import {TableColumnResizeState} from "../gusl-table/guslColumnResizeSlice";
import MiniLoadingSpinner from "../gusl-table/table-controls/MiniLoadingSpinner";
import {GuslFormState} from "../maintain-form/guslFormSlice";
import {maintainTableService} from "../maintain-table/MaintainTableService";
import {OptionFieldOpenState, toggleOptionFieldOpen} from "../option/optionFieldSlice";
import {
    FieldContentStyled,
    FieldContentWrapperStyled,
    FloatingFormStyled,
    HintStyled,
    TextFieldStyled
} from "../text/styled";
import {
    CloseButtonIconStyled,
    CloseButtonStyled,
    ComboWrapperStyled,
    CurrentlySelected,
    DownArrowStyled,
    DownArrowSvgPathStyled,
    DownArrowSvgStyled,
    IndicatorsContainerStyled,
    InputFieldContainerStyled,
    LookupColumnStyled,
    LookupContainerStyled,
    LookupControlContainerStyled,
    LookupDataItemStyled,
    LookupHeaderColumnStyled,
    LookupInputStyled,
    LookupItemStyled,
    LookupTableBodyStyled,
    LookupTableContainerStyled,
    LookupTableHeadStyled,
    LookupTableResponsiveStyled,
    LookupTableRowStyled,
    LookupTableStyled,
    LookupTableViewStyled,
    LookupValueStyled,
    MatchesContainerStyled,
    MatchesContentStyled,
    MatchesStyled,
    NoMatchesContentStyled,
    NoMatchStyled,
    NoneCurrentlySelected,
    SelectedFormValueStyled,
    SeparatorStyled
} from "./styled";
import {CurrentMatchesDO, IsSelectedDO, SearchRequestDTO} from "./types";
import {useKeyPress} from "./useKeyPress";

export const LookupField = (properties: FieldProperties): React.ReactElement<FieldProperties> => {

        const systemContext = useContext(SystemContext);
        const sessionContext = useContext(SessionContext);
        const blastContext = useContext(BlastContext);
        const environmentContext = React.useContext(EnvironmentContext);

        const _guslFormState: GuslFormState = useAppSelector(state => state.guslFormSlice[properties.code]);
        // MK 05-01-2024
        const code: string = maintainTableService.getCodeFromUrl();
        const _columnResizeState: TableColumnResizeState = useAppSelector(state => state.guslColumnResizeSlice[code]);
        const headerId: string = "hdr_" + properties.fieldConfig.name + "_" + code;
        const correspondingHeader = document.getElementById(headerId);
        // @ts-ignore
        const cellWidth: number | string | undefined = (correspondingHeader?.offsetWidth || _columnResizeState?.resizedColumns[properties.fieldConfig.name]) - 15;
        const optionFieldOpenState: OptionFieldOpenState = useAppSelector(state => state.optionFieldOpenSlice);
        const dispatch = useAppDispatch();

        const [className] = React.useState<string>(() => "LookupField-" + new Date().getTime());
        const [formMode, setFormMode] = useState(properties.formMode);
        const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
        const [submitted, setSubmitted] = useState(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const serverSearch: boolean = typeof properties?.fieldConfig?.lookupSearchUrl !== "undefined";
        const blastSearch: boolean = properties?.fieldConfig?.lookupCollection !== "";
        const [loading, setLoading] = useState<boolean>(false);
        const [lookupData, setLookupData] = useState<any[]>([]);
        const [formValue, setFormValue] = useState<string>(_guslFormState?.getFieldValue(properties) || properties.data || "");
        const [refreshRequest, setRefreshRequest] = useState<number>(0);
        const [searchValue, setSearchValue] = useState<string>(inputRef?.current?.value || "");
        const [lookupHasFields, setLookupHasFields] = useState<boolean>(false);

        const [lookupFields, setLookupFields] = useState<FieldConfigDTO[]>([]);

        let matches: any[] = [];
        const referenceName = properties?.reference?.name;
        const matchesWrapperId = "matches_" + referenceName;
        const [statusClassName, setStatusClassName] = useState<string>("");
        const [mediaType, setMediaType] = useState<MediaType>(environmentContext.getCurrentMediaType());

        const lookupElement = useRef(null);
        const [oldResize, setOldResize] = useState<number | undefined>(undefined);
        const [fieldPositionProperties, setFieldPositionProperties] = useState<FieldPositionProperties>(() => initFieldPositionProperties(environmentContext, properties));

        RunOnceEffect(() => {
            const subscriptionResize = handleResize(environmentContext, lookupElement, oldResize, fieldPositionProperties, setFieldPositionProperties, setOldResize);
            return () => {
                unSubscribe(subscriptionResize);
            };
        });


        useEffect(() => {
            handleWindowChange(lookupElement, fieldPositionProperties, setFieldPositionProperties);
        }, [loading, formMode]);

        const updateStatusClass = (value: string | undefined): void => {
            let extraClass = "";
            if (value) {
                // if (canColorise(properties.fieldConfig)) {
                extraClass += " " + replaceSpaceWithDash(properties?.fieldConfig?.name?.toLowerCase() || 'l_') + "-badge " +
                    replaceSpaceWithDash(properties?.fieldConfig?.name?.toLowerCase() || 'l_') +
                    "-" + (isString(value) ? (replaceSpaceWithDash(value?.toLowerCase()) || "") : '') + " ";
                // }
                // if (canColoriseText(properties.fieldConfig)) {
                extraClass += replaceSpaceWithDash(properties?.fieldConfig?.name?.toLowerCase() || 'l_') + "-text-" + (isString(value) ? (replaceSpaceWithDash(value?.toLowerCase()) || "") : '') + " ";
                // }
            }
            setStatusClassName(extraClass);
        };

        function scrollToSelected(scrollTop: number) {
            try {
                // @ts-ignore
                document.getElementById(matchesWrapperId).scrollTo({top: scrollTop, behavior: "smooth"});
            } catch (e) {
                /** dropdown is closed*/
                //log.error(className, 'MSGE-1', 'no element')

            }
        }

        const [lookupConfig, setLookupConfig] = useState<LookupConfigDTO | undefined>(() => {
            return {
                name: "lookup",
                extractLabel: "return `${data.code}` ",
                tableFields: ["code"],
                fieldTypes: ["text"],
                sortBy: "code",
                pageable: false,
                retValue: "return data"
            } as LookupConfigDTO;
        });

// log.info(className, "MSG001", "LookupField", {properties, lookupConfig, rn: properties.reference});

        const extractTableValue = () => {
            if (properties.data) {
                // need to use Lookup config
                return properties.data.code;
            }
            return "";
        };

        const formatLookupRow = (lookupCfg: LookupConfigDTO | undefined, rowData: any): string => {
            if (!lookupCfg || !rowData || !lookupCfg.extractLabel) {
                return "";
            }

            try {
                const func = new Function("self", "data", lookupCfg.extractLabel);
                // log.debug(className, 'MSG001', {func})
                // const val = func(this, convertData(rowData));
                const val = func(this, rowData);

                if (val === "undefined") {
                    return "";
                }
                return val;
            } catch (error) {
                log.error(className, "ERR001", "Error extracting lookup value", rowData, error);
                console.error('Error formatting out put', className, rowData, error)
                return "";
            }
        };

        const extractValue = (): string => {
            if (properties?.fieldConfig?.lookup) {
                const lookupCfg: LookupConfigDTO | undefined = maintainTableService.findLookupConfig(properties?.fieldConfig?.lookup, systemContext?.getSystemConfig()?.lookups || []);
                if (lookupCfg) {
                    if (lookupCfg.fields) {
                        setLookupHasFields(true);
                        const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupCfg?.fields || []);
                        setLookupFields(lookupCfg?.fields?.filter(field => matchMediaTypeWithField(field, mediaType, mediaTypeSensitive))
                            .filter(field => field.displayInTable));
                    }
                    setLookupConfig(lookupCfg);
                    try {
                        const formatted = isObject(properties.data) ? formatLookupRow(lookupCfg, properties.data) : properties.data;
                        updateStatusClass(formatted);
                        return formatted;
                    } catch (err) {
                        console.log("Error setting table value", err, properties.data);
                    }
                }
            }
            updateStatusClass(properties?.data?.code || properties?.data || "");
            return properties?.data?.code || properties?.data || "";
        };

        const [tableValue, setTableValue] = useState<string>(() => {
            return extractValue();
        });

        const [hovered, setHovered] = useState(undefined);
        const [cursor, setCursor] = useState<number>(-1);

        const downPress: boolean = useKeyPress("ArrowDown");
        const upPress: boolean = useKeyPress("ArrowUp");


        useEffect(() => {

            if (lookupData.length && downPress) {
                setCursor(prevState =>
                    prevState < lookupData.length - 1 ? prevState + 1 : prevState === lookupData.length - 1 ? 0 : prevState
                );

                if (cursor > 2) {
                    setTimeout(scrollToSelected, 100, cursor * 40);
                }

            }
        }, [downPress]);
        useEffect(() => {

            if (lookupData.length && upPress) {
                setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
            }
        }, [upPress]);
        useEffect(() => {
            if (lookupData.length) {
                rowSelected(lookupData[cursor]);
            }
            if (cursor === 0) {
                setTimeout(scrollToSelected, 100, 0);
            }
        }, [cursor]);

        const getLookupData = () => {
            let data: any[] = [];
            if (properties.fieldConfig.lookupCollection) {

                blastContext.getCollection(properties.fieldConfig.lookupCollection).forEach(item => data.push(item));
                setLookupData(data);
                setLoading(false);
                log.debug(className, "MSG001", "=========> BLAST");
            } else if (properties.fieldConfig.lookupSelectUrl) {
                log.debug(className, "MSG001", "=========> LOOKUP URL", properties.getCurrentRowData());
                setLoading(true);
                // sessionContext.get<any[]>(constructUrl(properties.fieldConfig.lookupSelectUrl, _guslFormState?.formData || properties.getCurrentRowData(), _guslFormState?.rowData))
                sessionContext.get<any[]>(constructUrl(properties.fieldConfig.lookupSelectUrl, _guslFormState?.formData, properties.getCurrentRowData()))
                    .then((response) => {
                        data = response.data || [];
                        setLookupData(response.data || []);
                        setLoading(false);

                    }, reason => {
                        setErrorMessage("Could not load data");
                        setLoading(false);
                        log.error(className, "ERR001", "Error getting template data", reason);
                    });
            } else if (properties.fieldConfig.lookupSearchUrl) {
                setLoading(true);
                sessionContext.post<SearchRequestDTO, any[]>(constructUrl(properties.fieldConfig.lookupSearchUrl, properties.getCurrentRowData()),
                    {searchValue: inputRef?.current?.value as string})
                    .then((response) => {
                        data = response.data || [];
                        setLookupData(response.data || []);
                        setLoading(false);
                    }, reason => {
                        log.error(className, "ERR001", "Error getting template data", reason);
                        setErrorMessage("Could not load data");
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }

            return data;
        };

        const onFormModeChange = (mode: FormMode) => {
            setFormMode(mode);
        };

        const onRefresh = () => {
            startAgainHandler();
        };

        const doValidation = (fieldValue: any): boolean => {
            setSubmitted(true);
            setErrorMessage(undefined);
            const hideField = shouldHide(formMode, properties.fieldConfig, fieldValue);
            if (!hideField) {
                const message = validate(properties.menuItem?.code,
                    properties.fieldConfig.name,
                    properties.fieldConfig.validators,
                    "text",
                    fieldValue);
                if (message) {
                    setErrorMessage(message);
                    return false;
                }
            }
            return true;
        };

        RunOnceEffect(() => {
            // init
            properties.reference.changeMode = onFormModeChange;
            properties.reference.doRefresh = onRefresh;
            properties.reference.doValidation = doValidation;
            properties.reference.register(properties.reference);
            return () => {
                // clean up
            };
        });

        const renderTableView = (): React.ReactElement => {

            const onFieldClick = (e: React.MouseEvent<HTMLDivElement>) => {
                const linkPath = buildPathFromRef(properties?.fieldConfig, properties.rowData);
                if (linkPath && e) {
                    e.stopPropagation();
                    window.open(linkPath);
                    // navigate(linkPath)
                }
            };

            const hasRef: boolean = isDefined(properties?.fieldConfig?.ref);

            return (
                <TextFieldStyled hasLink={hasRef}
                                 cellWidth={cellWidth}
                                 onClick={(e) => {
                                     onFieldClick(e);
                                 }}
                                 className={statusClassName}>{tableValue}</TextFieldStyled>
            );
        };


        const rowSelected = (rowData: any) => {
            if (properties.setTicker && rowData && rowData.id) {
                properties.setTicker({id: rowData.id, name: rowData.name, symbol: rowData.symbol});
            }
            // dispatch(guslFormFieldChange({
            //     code: properties.code,
            //     name: properties.fieldConfig.name,
            //     value: rowData
            // }))

            setFormValue(formatLookupRow(lookupConfig, rowData));
            setTableValue(formatLookupRow(lookupConfig, rowData));
            properties.onChange(properties.fieldConfig.name, rowData, properties.fieldConfig.lookupOverwritesForm);
            dispatch(toggleOptionFieldOpen({id: undefined}));
            updateStatusClass(formatLookupRow(lookupConfig, rowData));
        };


        const renderDownArrow = (): React.ReactElement => {
            const matchesOpen = optionFieldOpenState.id ? optionFieldOpenState.id === properties.fieldConfig.name : false;
            return <ElementWithTooltip
                element={
                    <IndicatorsContainerStyled isActive={matchesOpen} onClick={showLookupDataHandler}>
                        <SeparatorStyled/>
                        <DownArrowStyled>
                            <DownArrowSvgStyled><DownArrowSvgPathStyled/></DownArrowSvgStyled>
                        </DownArrowStyled>
                    </IndicatorsContainerStyled>
                }
                tooltip={<span>{matchesOpen ? "Collapse" : "Expand"}</span>}/>;
        };

        const resetOverwriteFormData = () => {
            if (!properties.fieldConfig.lookupOverwritesForm) {
                return;
            }
            const data: any = {};

            safeStream(lookupConfig?.tableFields).forEach(fld => {
                data[fld] = undefined;
            });
            properties.onChange(properties.fieldConfig.name, data, true);
        };

        function startAgainHandler() {
            setFormValue("");
            setTableValue("");
            setSearchValue("");
            setErrorMessage("");
            dispatch(toggleOptionFieldOpen({id: undefined}));
            setCursor(-1);
            if (inputRef?.current?.value) {
                inputRef.current.value = "";
            }
            if (properties.setTicker) {
                properties.setTicker("");
            }
            resetOverwriteFormData();
        }

        useEffect(() => {
            if (properties.reset) {
                startAgainHandler();
            }
        }, [properties.reset]);

        useEffect(() => {
            if (formValue !== null && formValue !== undefined) {
                if (formValue === "") {
                    if (properties.onReset) {
                        properties.onReset();
                    }
                }
            }
        }, [formValue]);

// not sure this is a good UX
        const showCurrentlyArrowed: boolean = false; // lookupData.length > 0 && cursor >= 0 && !formValue;

        function StartAgain() {
            return <ElementWithTooltip
                element={<i className={"fa-solid fa-xmark ms-3 me-1"}
                            onClick={startAgainHandler}
                />}
                tooltip={<span>Cancel / Start again</span>}
            />;
        }

        const renderResultsHeader = (): React.ReactElement => {
            const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupConfig?.fields || []);
            return (
                <TableHeaderRowStyled key={"hdr_row"} id={"hdr_row"}>
                    {!lookupHasFields && lookupConfig?.tableFields
                        .map((field, idx) => <LookupHeaderColumnStyled bold={true}
                                                                       key={"hdr_row_" + idx}>{field}</LookupHeaderColumnStyled>)}
                    {lookupHasFields && lookupFields.map((field, idx) =>
                        <LookupHeaderColumnStyled bold={true}
                                                  textAlign={field.align}
                                                  id={"hdr_rowx_" + idx}
                                                  key={"hdr_rowx_" + idx}>{field.label}{lookupFields.length === idx + 1 && renderCloseButton()} </LookupHeaderColumnStyled>)}

                </TableHeaderRowStyled>
            );
        };

        const renderResultRow = (rowData: any, idx: number): React.ReactElement => {
            // const mediaTypeSensitive = areFieldsMediaTypeSensitive(lookupConfig?.fields || []);
            return (
                <LookupTableRowStyled role={"button"}
                                      key={"id_" + idx}
                                      id={"id_" + idx}
                                      active={idx === cursor}
                                      lookupField={true}
                                      onClick={() => rowSelected(rowData)}
                    // onMouseEnter={() => setHovered(rowData)}
                    // onMouseLeave={() => setHovered(undefined)}

                >
                    {!lookupHasFields && (lookupConfig?.tableFields || [])
                        .map((field, idx) => <LookupColumnStyled textAlign={'left'}
                                                                 key={"hdr_row_" + idx}>{rowData[field]}</LookupColumnStyled>)}
                    {lookupHasFields && lookupFields
                        .filter(field => field.displayInTable).map((field, idx) =>
                            <LookupColumnStyled textAlign={field.align}
                                                key={"hdr_row_" + idx}> {fieldService.getTableField(field, undefined, rowData)?.render()}</LookupColumnStyled>)}
                </LookupTableRowStyled>
            );
        };

        const renderResultRows = (rowData: any[]) => {
            return (
                <>
                    {rowData && isArray(rowData) && rowData.map((row, idx) => renderResultRow(row, idx))}
                </>
            );

        };

        const renderResults = (rowData: any[]): React.ReactElement => {
            //                 {lookupHasFields ? renderResultsWithFieldConfig(collection) : renderResults(collection)}
            return (
                <LookupTableContainerStyled id={"lookup_results_tcs_" + properties.code}>
                    <TableContentStyled id={"lookup_results_tcontent_" + properties.code}>
                        <LookupTableResponsiveStyled className={'tbl_content'}>
                            <LookupTableStyled>
                                <LookupTableHeadStyled>
                                    {renderResultsHeader()}
                                </LookupTableHeadStyled>
                                <LookupTableBodyStyled id={matchesWrapperId}
                                                       tBodyHeight={200}
                                                       isOverFlown={false}>
                                    {renderResultRows(rowData)}
                                </LookupTableBodyStyled>
                            </LookupTableStyled>
                        </LookupTableResponsiveStyled>
                    </TableContentStyled>
                </LookupTableContainerStyled>
            );
        };
        const renderCloseButton = (): React.ReactElement => {
            const onCloseClick = () => {
                dispatch(toggleOptionFieldOpen({id: undefined}));
                setSearchValue("");
                setErrorMessage("");
                setFormValue("");
                setTableValue("");

                if (inputRef?.current?.value) {
                    inputRef.current.value = "";
                }
            };
            return (
                <CloseButtonStyled id={"lookup_matches_button_" + properties.code}>
                    <CloseButtonIconStyled onClick={() => onCloseClick()}/>
                </CloseButtonStyled>
            );
        };

        function CurrentMatches({collection}: CurrentMatchesDO) {
            return (
                <ComboWrapperStyled {...fieldPositionProperties}>
                    <MatchesStyled id={"lookup_matches_ms_" + properties.code}>
                        {renderResults(collection)}
                    </MatchesStyled>
                </ComboWrapperStyled>
            );
        }

        function CurrentMatchesOld({collection}: CurrentMatchesDO) {
            return <MatchesStyled id={matchesWrapperId}>
                {collection.map((rowData, idx) =>
                    <LookupDataItemStyled key={idx}
                                          isJustSelected={isJustSelected({rowData})}
                                          onClick={() => rowSelected(rowData)}>
                        {lookupConfig && lookupConfig.tableFields.map((field, idx) =>
                            <LookupItemStyled key={idx}>{rowData[field]}</LookupItemStyled>)}
                        {isJustSelected({rowData}) && <>&#x2713;</>}
                    </LookupDataItemStyled>)}
            </MatchesStyled>;
        }

        const ErrorMessage = (): React.ReactElement => {
            if ((searchValue.length > 1 && !matches.length)) {
                return (
                    <NoMatchesContentStyled {...fieldPositionProperties} isResultTable={true}>
                        <NoMatchStyled>No matches<StartAgain/></NoMatchStyled>
                        <NoMatchStyled>Please contact the desk to place order</NoMatchStyled>
                    </NoMatchesContentStyled>
                );
            } else {
                return <></>;
            }
        };

        function Matches() {

            if (searchValue.length > (serverSearch ? 1 : 0)) {
                matches = serverSearch ? lookupData : lookupData.filter((item) => {
                        let found = false;
                        for (const key in item) {
                            if (item.hasOwnProperty(key)) {
                                if (item[key]) {
                                    try {
                                        if (item[key].toLowerCase().includes(searchValue.toLowerCase())) {
                                            found = true;
                                        }
                                    } catch (err) {
                                        // ignore
                                    }
                                }
                            }
                        }
                        return found;
                    }
                );


                // matches = serverSearch ? lookupData : lookupData.filter((item) =>
                //     item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                //     item.code.toLowerCase().includes(searchValue.toLowerCase())
                // )
                //;
            }
            const matchesOpen = optionFieldOpenState.id ? optionFieldOpenState.id === properties.fieldConfig.name : false;


            if (loading) {
                return <MiniLoadingSpinner isLoading={loading}/>;
            } else if (errorMessage) {
                return <NoMatchStyled>{errorMessage} <StartAgain/></NoMatchStyled>;
            } else if ((matches.length && !formValue)) {
                return (
                    <MatchesContentStyled {...fieldPositionProperties}>
                        <CurrentMatches collection={matches}/>
                    </MatchesContentStyled>
                );
            } else if (matchesOpen) {
                return (
                    <MatchesContentStyled {...fieldPositionProperties} isResultTable={true}>
                        <CurrentMatches collection={lookupData}/>
                    </MatchesContentStyled>
                );
            } else {
                return <></>;
            }
        }

        function inputHandler() {
            if (inputRef.current) {
                setSearchValue(inputRef.current.value);
                /* SERVER LOOKUP*/
                if (properties.fieldConfig.lookupSelectUrl) {
                    /*get data once*/
                    if (inputRef.current.value.length === 1 && !lookupData.length) {
                        getLookupData();
                    }
                }
                /*BLAST | SERVER SEARCH*/
                else if (properties.fieldConfig.lookupCollection || properties.fieldConfig.lookupSearchUrl) {
                    /*look for data when search string is between 2-5 chars*/
                    if (inputRef.current.value.length > 1 && inputRef.current.value.length < 6) {
                        getLookupData();
                    }
                }
            }
        }

        function isJustSelected({rowData}: IsSelectedDO) {
            return formValue === formatLookupRow(lookupConfig, rowData);
        }

        function showLookupDataHandler() {
            if (!lookupData.length || blastSearch) {
                getLookupData();
            }
            dispatch(toggleOptionFieldOpen({id: optionFieldOpenState.id === properties.fieldConfig.name ? undefined : properties.fieldConfig.name}));
            setSearchValue("");
            setErrorMessage("");

            if (inputRef?.current?.value) {
                inputRef.current.value = "";
            }
        }

        const renderFormView = (): React.ReactElement => {
            const [hideField, disableField, required] = getSettings(formMode, properties.fieldConfig, formValue);

            if (hideField) {
                return <></>;
            }
            if (formMode === FormMode.VIEW || disableField) {
                return <FloatingFormStyled key={"id_" + refreshRequest}>
                    <FieldContentWrapperStyled>
                        <FieldContentStyled>
                            <LookupValueStyled>
                                {renderTableView()}
                            </LookupValueStyled>
                        </FieldContentStyled>
                        {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                    </FieldContentWrapperStyled>
                    <FieldLabel properties={properties}/>
                </FloatingFormStyled>;
            }
            return (
                <FloatingFormStyled id={"lookup_ff_" + properties.code} ref={lookupElement}>
                    <FieldContentWrapperStyled id={"lookup_fcw"} {...fieldPositionProperties} isLookup={true}>
                        <FieldContentStyled id={"lookup_ffcs_" + properties.code}>

                            {showCurrentlyArrowed ? <CurrentlySelected>
                                {lookupData[cursor].id}
                                <br/> <span>Press enter or click to select</span>
                            </CurrentlySelected> : <><NoneCurrentlySelected/></>}

                            <LookupContainerStyled submitted={submitted}
                                                   noValue={!formValue}
                                                   id={"lookup_lc_" + properties.code}
                            >
                                <LookupControlContainerStyled
                                    id={"lookup_cc_" + properties.code}  {...fieldPositionProperties}
                                    hasLabel={properties.hasLabel}>
                                    <InputFieldContainerStyled id={"lookup_ifc_" + properties.code}>
                                        {formValue ?
                                            <SelectedFormValueStyled>
                                                <LookupTableViewStyled> {tableValue} </LookupTableViewStyled>
                                                <StartAgain/>
                                            </SelectedFormValueStyled>
                                            :
                                            <LookupInputStyled onInput={inputHandler}
                                                               id={"lookup_li_" + properties.code}
                                                               ref={inputRef}
                                                               type="text"
                                                               defaultValue={formValue ? formValue : ""}
                                                               placeholder={serverSearch ? "Start typing..." : "Start typing or select..."}/>}

                                    </InputFieldContainerStyled>
                                    {/* I think we should have down arrow for search ..  was {!serverSearch && renderDownArrow()}*/}
                                    {renderDownArrow()}
                                </LookupControlContainerStyled>
                            </LookupContainerStyled>
                        </FieldContentStyled>
                        {properties.fieldConfig.hint && <HintStyled>{properties.fieldConfig.hint}</HintStyled>}
                        {submitted && !formValue &&
                            <small className="invalid">{properties?.fieldConfig?.label || ""} is required.</small>}
                    </FieldContentWrapperStyled>
                    <FieldLabel properties={properties}/>
                    <span data-lookup={referenceName} data-symbol={formValue}/>
                    <MatchesContainerStyled {...fieldPositionProperties} >
                        <Matches/>
                    </MatchesContainerStyled>
                    <ErrorMessage/>
                </FloatingFormStyled>
            );
        };

        return (
            <>
                {properties.isTableView ? renderTableView() : renderFormView()}
            </>
        );

    }
;
