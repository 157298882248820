import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../../app/hooks';
import {constructUrl, safeStream} from '../../../../../utils/Utils';
import PagedResponse from '../../../../paged-response/PagedResponse';
import ArticleModal from '../../article-modal/ArticleModal';
import {
    ArticleDateStyled,
    ArticleSubjectStyled,
    ArticleSummaryStyled,
    ArticleTimeStyled,
    ArticleTitleStyled
} from '../../articles-view/styled';
import {OtherDealsState, selectedOtherDealArticle} from '../../other-deals-view/otherDealsSlice';
import {OttoBoxStyled, ScrollBox} from '../../styled';
import {OSSArticleDTO, UpcomingEventDTO} from '../../types';
import {CollectionHeaderStyled} from '../styled';

const SELECT_URL = '/otto-articles/{dealType}/paged-comments'

interface GeneralArticlesProperties {
    code: string,
    dealType: string,
    contentsHeight: string
}

const GeneralArticles = ({code, dealType, contentsHeight}: GeneralArticlesProperties): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const _otherDealsState: OtherDealsState = useAppSelector(state => state.otherDealsSlice);

    const onArticleClick = (selectedEvent: OSSArticleDTO | UpcomingEventDTO) => {
        dispatch(selectedOtherDealArticle({article: selectedEvent}))

        // navigate(DEAL_PAGE + '/' + selectedEvent.dealId + '/' + selectedEvent.id)
    }

    const renderCard = (article: OSSArticleDTO, idx: number): React.ReactElement => {
        return (
            <ArticleTitleStyled
                key={'t_' + idx + article.id}
                large={true}
                active={false}
                onClick={() => {
                    console.log('---- aticle clicked')
                    onArticleClick(article)
                }}>
                <ArticleSummaryStyled>
                    <ArticleTimeStyled>{article.articleTime}</ArticleTimeStyled>
                    {article?.subject && <ArticleSubjectStyled
                        dangerouslySetInnerHTML={{__html: article?.subject || ''}}></ArticleSubjectStyled>}
                </ArticleSummaryStyled>
            </ArticleTitleStyled>
        )
    }

    const renderItem = (articlesByDate: any, idx: number): React.ReactElement => {
        return (
            <div key={idx} className={"p-2"}>
                <ArticleDateStyled large={true}>{articlesByDate.publishedDate}</ArticleDateStyled>
                {safeStream(articlesByDate.articles).map((article: OSSArticleDTO, idx) => renderCard(article, idx))}
            </div>
        )
    }
    const onHide = () => {
        dispatch(selectedOtherDealArticle({article: undefined}))
    }

    return (
        <>
            <OttoBoxStyled>
                <CollectionHeaderStyled>Comments</CollectionHeaderStyled>
                <ScrollBox id={'scrollable_' + code} height={contentsHeight}>
                    <PagedResponse code={code} selectUrl={constructUrl(SELECT_URL, {dealType})}
                                   renderItem={renderItem}/>
                </ScrollBox>
            </OttoBoxStyled>
            {_otherDealsState?.selectedArticle &&
                <ArticleModal articleId={_otherDealsState?.selectedArticle?.id || ''} onHideCallBack={onHide}/>}
        </>

    )
}

export default GeneralArticles
