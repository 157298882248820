import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Subscription} from 'rxjs';
import {useAppDispatch} from '../../../app/hooks';
import {store} from "../../../app/store";
import {EnvironmentContext} from '../../../providers/environment/EnvironmentContext';
import {environmentService} from '../../../providers/environment/EnvironmentService';
import {SessionContext} from '../../../providers/session/SessionContext';
import {SystemContext} from '../../../providers/system/SystemContext';
import {CONTENT_START_POSITION, HEADER_HEIGHT, TAB_HEIGHT} from '../../../providers/theme/GuslThemeProvider';
import {splitAndAddCssProperties} from '../../../utils/CssUtils';
import {arrayNotEmpty} from '../../../utils/TypeCheckers';
import {constructUrl, RunOnceEffect, splitPath, unSubscribe} from '../../../utils/Utils';
import {AccordionListView} from '../../accordion-list/AccordionList';
import {AlphaListView} from '../../alpha-list-view/AlphaListView'
import {LEFT_HAND_MENU_COMMAND} from '../../FieldComponentMap';
import {FlexList} from '../../flex-list/FlexList';
import {
    ActionConfigDTO,
    FormMode,
    HeaderDTO,
    IMenuDTO,
    MenuType,
    PanelConfigDTO,
    QueryParamsDTO,
    WidgetPanelProperties
} from '../../types';
import {ComponentTitleWrapperStyled} from '../../ui/styled';
import {GuslReport} from '../gusl-report/GuslReport';
import {downloadBlob} from "../gusl-table/functions/functions";
import {GuslTableState, toggleInline} from '../gusl-table/guslTableSlice';
import {DownloadRequest} from "../gusl-table/ListView";
import {GuslTableQueryParamState} from "../gusl-table/queryParamsSlice";
import {HeaderPanels} from '../header-panel/HeaderPanels';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import MaintainForm from '../maintain-form/MaintainForm';
import MenuBar from '../maintain-table/bootstrap/MenuBar';
import MaintainTable from '../maintain-table/MaintainTable';
import {maintainTableService} from '../maintain-table/MaintainTableService';
import {closeAction} from "../maintain-table/table-row/tableRowSlice";
import {
    HeaderEntityStyled,
    HeaderIconStyled,
    HeaderImageStyled,
    HeaderItemStyled,
    HeaderItemsWrapperStyled,
    HeaderTextStyled,
    NoTabsStyledWrapperStyled,
    TabbedPageBackButtonStyled,
    TabbedPageHeaderContentStyled,
    TabbedPageHeaderContentWrapperStyled,
    TabbedPageMenuBarWrapperStyled,
    TabbedPageRefreshIcon,
    TabbedPageTableContainerStyled,
    TabbedPageTableContentStyled,
    TabbedPageTitleBlockStyled,
    TabbedPageTitleStyled,
    TabContainerStyled,
    TabsContainerStyled,
    TabScrollContainerStyled,
    TabsStyledWrapperStyled,
    TabStyled
} from './styled';

enum TabType {
    TABLE = 'TABLE',
    FORM = "FORM"
}


type TabbedPageProperties = {
    queryParams?: QueryParamsDTO | undefined;
    widgetPanelProperties?: WidgetPanelProperties;
    path?: string;
    onTabClicked?: (entity: string | undefined, entityId: string | undefined, tabCode: string | undefined) => void;
    noHeader?: boolean;
    fixedHeight?: number;
}

export const TabbedPage = ({
                               queryParams,
                               widgetPanelProperties,
                               path,
                               onTabClicked,
                               noHeader = false,
                               fixedHeight
                           }: TabbedPageProperties): React.ReactElement<TabbedPageProperties> => {

    const [className] = useState("TabbedPage-" + new Date().getTime());
    const systemContext = React.useContext(SystemContext)
    const sessionContext = React.useContext(SessionContext)
    const environmentContext = React.useContext(EnvironmentContext)

    const tabContentElement = useRef(null);
    const contentElement = useRef(null);
    const [startPos, setStartPos] = useState<number>(CONTENT_START_POSITION);

    const location = useLocation();
    const navigate = useNavigate();

    const paths = path ? path.split('/') : location.pathname.split('/');
    const code = path ? paths[0] : paths[paths.length - 1]; // not sure if this will be always the case...

    let clientId: string = "";
    if (paths.length > 2) {
        clientId = paths[paths.length - 2];
    } else {
        clientId = 'ui'
    }

    const [menuItems, setMenuItems] = useState<IMenuDTO[]>([]);
    const dispatch = useAppDispatch();

    const [parentData, setParentData] = useState<any>({id: clientId, code: code});

    const [parentMenuItem, setParentMenuItem] = useState<IMenuDTO | undefined>(undefined);
    const [activeItem, setActiveItem] = useState<IMenuDTO | undefined>(undefined);
    const [activeItemMenuGroups, setActiveItemMenuGroups] = useState<ActionConfigDTO[] | undefined>([]);
    const [entityId, setEntityId] = useState<string|undefined>(() => {
        const [entity, entityId, tab] = path ? splitPath(path) : splitPath(location.pathname)
        return entityId;
    });
    const [key, setKey] = useState<any>(`tabbed_page_${code || ''}_${clientId || ''}_${entityId || ''}`);

    const [entity, setEntity] = useState<string | undefined>(() => {
        const [entity] = path ? splitPath(path) : splitPath(location.pathname)
        return entity;
    });
    const [tab, setTab] = useState<string | undefined>(() => {
        const [entity, id, tab] = path ? splitPath(path) : splitPath(location.pathname)
        return tab;
    });
    const [notFound, setNotFound] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
//    const [headerLoading, setHeaderLoading] = useState<boolean>(false);
    const [headerData, setHeaderData] = useState<HeaderDTO | undefined>(undefined);
    const [hasComplexHeader, setHasComplexHeader] = useState<boolean>(false);
    const [hasHeader, setHasHeader] = useState<boolean>(!noHeader);
    const [menuGroups, setMenuGroups] = useState<IMenuDTO[]>([]);
    const [downloadAbortController, setDownloadAbortController] = useState<AbortController | undefined>(undefined);
    const [hasPanelHeader, setHasPanelHeader] = useState<boolean>(false);
    const [headerPanels, setHeaderPanels] = useState<PanelConfigDTO[]>([]);
    const [headerUrl, setHeaderUrl] = useState<string | undefined>(undefined);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);
    const [hasRefreshIcon, setHasRefreshIcon] = useState<boolean>(false);
    const [systemReady, setSystemReady] = useState<boolean>(false);
    const isMobile = environmentContext.isMobileDevice(widgetPanelProperties);
    const [startHeight, setStartHeight] = useState<number>(TAB_HEIGHT + HEADER_HEIGHT);

    const [footerHeight, setFooterHeight] = useState<number>(0);

    useEffect(() => {
        setParentData({id: clientId, code: code})
        const [entity, entityId, tab] = path ? splitPath(path) : splitPath(location.pathname)
        setKey(`tabbed_page_${code || ''}_${clientId || ''}_${entityId || ''}`)
        setEntityId(entityId)
        setEntity(entity)
        setTab(tab)

        return () => {
            setEntity(undefined)
            setKey('k')
            setEntityId('')
            setParentData({})
        }
    }, [clientId, code]);

    RunOnceEffect(() => {
        let heightSubscription: Subscription = environmentService.watchFooterHeight().subscribe((height: number) => {
            setFooterHeight(height)
        })
        return () => {
            unSubscribe(heightSubscription);
        }
    });

    RunOnceEffect(() => {
        let loaderSubscription = sessionContext.watchSystemReady().subscribe((systemReady: boolean) => {
            setSystemReady(systemReady)
        });
        return () => {
            unSubscribe(loaderSubscription);
        }
    });

    useEffect(() => {
        window.requestAnimationFrame(function () {
            setTimeout(() => {
                if (tabContentElement) {
                    // @ts-ignore
                    const rect = tabContentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartHeight(rect.y);
                    }
                }
                if (contentElement) {
                    // @ts-ignore
                    const rect = contentElement?.current?.getBoundingClientRect();
                    if (rect) {
                        setStartPos(rect.y);
                    }
                }
            }, 100)
        });
    }, [tabContentElement]);


    // useTimeout(() => {
    //     console.log('scroll to top')
    //     window.scrollTo(0, 1)
    // }, 100)
    //
    const onInlineMenuGroupAction = (event: React.MouseEvent, item: IMenuDTO) => {
        // @ts-ignore
        dispatch(toggleInline({code: parentMenuItem?.code, menuItem: item}))
    }

    const getHeaderData = (headerUrl: string | undefined) => {
        if (headerUrl && entityId) {
            // setHasComplexHeader(true)
            // // setHeaderLoading(true);

            sessionContext.get<HeaderDTO>(constructUrl(headerUrl, {id: entityId}))
                .then(response => {
                        setHeaderData(response.data)
                        if (entity) {
                            const mainMenu = systemContext.getMenuConfigFromPath(entity);
                            if (mainMenu) {
                                setMenuGroups(
                                    maintainTableService.extractHeaderRestActionsToMenuGroup(sessionContext.getLoggedInUser(), mainMenu, response.data, undefined, undefined, onInlineMenuGroupAction)
                                );
                            }
                        }
                        setRefreshCounter(new Date().getTime())
                    }
                ).catch(error => {
                console.error('Error in getting header [' + constructUrl(headerUrl, {id: entityId}) + ']', error)
            })
        }
    }

    const loadHeader = (menuItem: IMenuDTO) => {
        setHeaderUrl(menuItem?.entityGroup?.header?.url)
        getHeaderData(menuItem?.entityGroup?.header?.url)
    }

    useEffect(() => {
        let subscription: Subscription | undefined = undefined;
        let refreshRate = -1;
        if (parentMenuItem) {
            refreshRate = parentMenuItem?.entityGroup?.header?.refreshRateInSeconds ? parentMenuItem?.entityGroup?.header?.refreshRateInSeconds * 1000 : -1;
            if (refreshRate > 0) {
                subscription = sessionContext.watchRefreshRate().subscribe((nextRefreshRate: number) => {
                    if (nextRefreshRate !== -1 && refreshRate === nextRefreshRate) {
                        getHeaderData(headerUrl)
                    }
                })
                sessionContext.registerRefreshRate(refreshRate)
            }
        }
        return () => {
            unSubscribe(subscription);
            sessionContext.unregisterRefreshRate(refreshRate)
        }
    }, [parentMenuItem]);


    /*todo this is just copy from ListView.tsx  , probably need to extract it into maintainTableService ...*/
    const performDownloadWithoutPromptAction = (action?: ActionConfigDTO, rowData?: any) => {
        if (action) {
            dispatch(closeAction({rowId: action.rowId})) // clean the action

            const tableState: GuslTableState = store.getState().guslTableSlice[code]
            const queryParamState: GuslTableQueryParamState = store.getState().queryParamsSlice[code]
            const abortController = new AbortController();
            setDownloadAbortController(abortController);
            const fields: string[] = tableState.allFields
                .filter(fld => fld.displayInTable)
                .map(fld => fld.name);

            const url = constructUrl(action.url, {id: entityId || clientId});
            sessionContext.download<DownloadRequest, any>(url, {
                queryParams: queryParamState.queryParams,
                fields: fields
            }, abortController)
                .then((response: any) => {
                    const contentDisposition = response.headers['content-disposition']
                    let filename = response.headers['export-filename']
                    const contentType = response.headers['content-type']

                    if (contentDisposition) {
                        let startFileNameIndex = contentDisposition.indexOf('"') + 1
                        let endFileNameIndex = contentDisposition.lastIndexOf('"');
                        filename = contentDisposition.substring(startFileNameIndex, endFileNameIndex);
                    }

                    downloadBlob(filename, contentType, response.data)
                }, (reason: any) => {
                    console.error('Download error ', reason)
                });
        }
    }


    useEffect(() => {
        if (systemReady) {
            setNotFound(false)
            setActiveItem(undefined)
            setLoaded(false)
            setHasComplexHeader(false)
            // setHeaderLoading(false);
            setActiveItemMenuGroups(undefined)
            const [entityCode, id, tabCode] = splitPath(path || location.pathname)
            setEntity(entityCode)
            setEntityId(id)
            setTab(tabCode)
            // @ts-ignore
            const mainMenu = systemContext.getMenuConfigFromPath(entityCode);
            if (mainMenu && id) {
                setParentMenuItem(mainMenu);

                if (mainMenu?.entityGroup?.header?.panels && mainMenu?.entityGroup?.header?.panels.length > 0) {
                    setHasPanelHeader(true)
                    setHeaderPanels(mainMenu?.entityGroup?.header?.panels || [])
                    setHasRefreshIcon(mainMenu?.entityGroup?.header?.withRefreshButton || false)
                    setHasComplexHeader(true)
                }


                if (mainMenu?.entityGroup?.header?.url) {
                    setHasComplexHeader(true)
                    loadHeader(mainMenu)
                }
                //               setMenuGroups(maintainTableService.extractHeaderRestActionsToMenuGroup(mainMenu, undefined, undefined, onInlineMenuGroupAction));

                if (arrayNotEmpty(mainMenu?.entityGroup?.menuItems)) {
                    setMenuItems(mainMenu?.entityGroup?.menuItems || [])
                    let tabIndex = 0;
                    if (tabCode) {
                        let count = 0;
                        (mainMenu?.entityGroup?.menuItems || []).forEach(menuItem => {
                            // @ts-ignore
                            if (tabCode === menuItem.code) {
                                tabIndex = count;
                            }
                            count++;
                        })
                    }
                    const _activeItem = mainMenu?.entityGroup?.menuItems[tabIndex];

                    if (_activeItem && fixedHeight) {
                        _activeItem.fixedHeight = fixedHeight;
                    }

                    /** not used...*/
                    setMenuGroups(
                        maintainTableService.extractHeaderRestActionsToMenuGroup(sessionContext.getLoggedInUser(), mainMenu, headerData, undefined, undefined, onInlineMenuGroupAction)
                    );

                    setActiveItem(_activeItem);
                    if (_activeItem?.entityGroup?.dataSources[0].definition?.overallRestActions) {
                        /*@ts-ignore*/
                        setActiveItemMenuGroups(maintainTableService.convertActionsToMenuGroup(
                            sessionContext.getLoggedInUser(),
                            /*@ts-ignore*/
                            _activeItem?.entityGroup?.dataSources[0]?.definition?.overallRestActions,
                            headerData,
                            undefined,
                            performDownloadWithoutPromptAction,
                            undefined)
                        )
                    }

                } else {
                    setNotFound(true)
                }
            } else {
                // console.log('no id')
                // setNotFound(true)
            }


        } else {
            // console.log('code not found')
            setNotFound(true)
        }
        setLoaded(true)
    }, [location.pathname, systemReady, systemContext, path]);


    const renderNotFound = (): React.ReactElement => {
        return (
            <h5>Page not found</h5>
        )
    }

    const renderTabLabel = (menuItem: IMenuDTO, idx: number, len: number): React.ReactElement => {
        const onTabLabelClicked = () => {
            if (onTabClicked) {
                onTabClicked(entity, entityId, menuItem.code)
            } else {
                setLoaded(false)
                navigate('/' + entity + '/' + entityId + '/' + menuItem.code)
            }
        }

        const active: boolean = (activeItem?.code === menuItem?.code) || (activeItem?.code === LEFT_HAND_MENU_COMMAND)

        return (
            <TabStyled
                active={active}
                key={'tab_' + menuItem.code}
                isFirst={idx === 0}
                isLast={idx === len - 1}
                isMobile={isMobile}
                onClick={() => onTabLabelClicked()}>{menuItem.label}</TabStyled>
        )
    }

    const renderBackButton = (): React.ReactElement => {
        const onBackButtonClicked = () => {
            navigate('/' + entity)
        }

        if (entityId === 'ui') {
            // no back button
            return <></>
        }

        return (<span role={'button'} onClick={() => onBackButtonClicked()}>
                <i className="fa-solid fa-angles-left"/>
                </span>
        )
    }


// const renderHeaderAsForm = (rows?: FormRowConfigDTO[], data?: any): React.ReactElement => {
//     return (
//         <HeaderFormWrapperStyled>
//             {rows?.map((row: FormRowConfigDTO, index: number) => renderFormRow(row, index, data))}
//         </HeaderFormWrapperStyled>
//     )
// }

    const renderHeaderPanels = (): React.ReactElement => {
        if (!hasPanelHeader) {
            return <></>
        }
        return (
            <HeaderItemsWrapperStyled>
                <HeaderPanels
                    parentData={parentData}
                    panels={headerPanels}
                    key={'tabbed_hdr_panels_' + refreshCounter + '_' + (clientId || '')}
                />
            </HeaderItemsWrapperStyled>
        )
    }

    const renderDetailHeaderData = (): React.ReactElement => {

        const getCss = (css: string | undefined): object => {
            const style: {
                [id: string]: any
            } = {}
            if (css) {
                splitAndAddCssProperties(style, css);
            }
            return style;
        }

        if (!headerData) {
            return <></>
        }

        return (
            <>
                {headerData.icon &&
                    <HeaderItemStyled>
                        <HeaderIconStyled className={headerData.icon} style={getCss(headerData.iconCss)}/>
                    </HeaderItemStyled>
                }
                {headerData && headerData.image &&
                    <HeaderItemStyled>
                        <HeaderImageStyled src={headerData.image} style={getCss(headerData.imageCss)}/>
                    </HeaderItemStyled>
                }
                {headerData && headerData.id &&
                    <HeaderItemStyled>
                        <HeaderTextStyled style={getCss(headerData.idCss)}>{headerData.id}</HeaderTextStyled>
                    </HeaderItemStyled>
                }
                {headerData && headerData.name &&
                    <HeaderItemStyled>
                        <HeaderTextStyled style={getCss(headerData.idCss)}>{headerData.name}</HeaderTextStyled>
                    </HeaderItemStyled>
                }
                {headerData && headerData.info &&
                    <HeaderItemStyled>
                        <GuslReport
                            code={entity + '_' + activeItem?.code + '_' + entityId}
                            data={headerData.info}
                        />
                    </HeaderItemStyled>
                }
            </>
        )
    }

    const renderHeaderData = (): React.ReactElement => {
        if (!headerData || hasPanelHeader) {
            return <></>
        }

        if (headerData && headerData.linkUrl) {
            return (
                <HeaderItemsWrapperStyled>
                    <a href={headerData.linkUrl} target={'_blank'}>{renderDetailHeaderData()}</a>
                </HeaderItemsWrapperStyled>
            )
        } else {
            return (
                <HeaderItemsWrapperStyled key={key}>
                    {renderDetailHeaderData()}
                </HeaderItemsWrapperStyled>
            )
        }
    }

    const renderHeaderContents = (): React.ReactElement => {
        return (
            <div key={'tabbed_hdr'}>
                {renderHeaderData()}
                {renderHeaderPanels()}
            </div>
        )
    }

    const renderEntityGroupContents = (): React.ReactElement => {
        return (
            <HeaderEntityStyled>
                {renderHeaderContents()}
            </HeaderEntityStyled>
        )
    }
    /*
     col={(menuGroups?.length || 0) > 0 ? '9' : '11'}
     */

    const onRefreshClicked = () => {
        setRefreshCounter(refreshCounter + 1)
    }
    const renderRefreshIcon = (): React.ReactElement => {
        return (
            <TabbedPageRefreshIcon onClick={() => onRefreshClicked()}>
                <i className={'fa-solid fa-refresh'}/>
            </TabbedPageRefreshIcon>
        )
    }

    const renderEntityGroupHeader = (): React.ReactElement => {
        return (
            <TabbedPageTitleBlockStyled>
                <TabbedPageTitleStyled>
                    <TabbedPageHeaderContentWrapperStyled>
                        <TabbedPageBackButtonStyled>{renderBackButton()}</TabbedPageBackButtonStyled>
                        <TabbedPageHeaderContentStyled align={headerData?.align || 'left'}
                        >
                            {renderEntityGroupContents()}</TabbedPageHeaderContentStyled>
                        <TabbedPageMenuBarWrapperStyled id={"menu-bar-wrapper"}
                                                        key={'menubar_' + refreshCounter}>
                            {hasRefreshIcon && renderRefreshIcon()}
                            {entityId && menuGroups?.length > 0 &&
                                <MenuBar entityId={entityId} menuGroups={menuGroups}/>
                            }
                        </TabbedPageMenuBarWrapperStyled>
                    </TabbedPageHeaderContentWrapperStyled>
                </TabbedPageTitleStyled>
            </TabbedPageTitleBlockStyled>
        )
    }

    const renderSimpleHeader = (): React.ReactElement => {
        return (
            <div className="col-md-4 d-flex align-items-center">
                <TabbedPageTitleStyled>{renderBackButton()} {parentMenuItem?.label || ''} </TabbedPageTitleStyled>
            </div>
        )
    }

    const closeDialog = () => {
        navigate('/' + entity)
    }

    const renderForm = (): React.ReactElement => {
        return (
            <div className={'row g-0'}>

                <div className="col-md-12">
                    {activeItem && <MaintainForm
                        isDialog={false}
                        controlOnTop={false}
                        canEdit={true}
                        formMode={FormMode.VIEW}
                        data={{id: entityId}}
                        menuItem={activeItem}
                        minModalWidth={'800px'}
                        minModalHeight={'500px'}
                        closeDialog={() => closeDialog()}/>}
                </div>
            </div>
        )
    }

    const renderContents = (): React.ReactElement => {
        const isAlphaList = maintainTableService.extractIsAlphaList(activeItem);
        if (isAlphaList && activeItem) {
            return (
                <TabScrollContainerStyled isMobile={isMobile}
                                          key={key}
                                          hasTabs={menuItems.length > 1}
                                          ref={tabContentElement}
                                          startHeight={startHeight}>
                    <AlphaListView menuItem={activeItem} queryParams={queryParams}/>
                </TabScrollContainerStyled>
            )
        }

        const isAccordionList = maintainTableService.extractIsAccordionList(activeItem);
        if (isAccordionList && activeItem) {
            return (
                <TabScrollContainerStyled isMobile={isMobile}
                                          key={key}
                                          hasTabs={menuItems.length > 1}
                                          ref={tabContentElement}
                                          startHeight={startHeight}>
                    <AccordionListView menuItem={activeItem}/>
                </TabScrollContainerStyled>
            )
        }

        const isFlexList = maintainTableService.extractIsFlexList(activeItem);
        if (isFlexList && activeItem) {
            return (
                <TabScrollContainerStyled isMobile={isMobile}
                                          key={key}
                                          hasTabs={menuItems.length > 1}
                                          ref={tabContentElement}
                                          startHeight={startHeight}>
                    <FlexList menuItem={activeItem} queryParams={queryParams}
                              widgetPanelProperties={widgetPanelProperties}/>
                </TabScrollContainerStyled>
            )
        }

        return (
            <TabScrollContainerStyled isMobile={isMobile}
                                      key={key}
                                      hasTabs={menuItems.length > 1}
                                      ref={tabContentElement}
                                      fixedHeight={fixedHeight}
                                      startHeight={startHeight}>
                {activeItem && activeItem.menuType === MenuType.ENTITY_GROUP_SINGLE_FORM && renderForm()}
                {activeItem && (activeItem.menuType === MenuType.MAINTAIN || activeItem.menuType === MenuType.ENTITY_GROUP) &&
                    <MaintainTable menuItem={activeItem} hideHeaderPanel={true} pathParams={{id: entityId}}
                                   isTabChild={menuItems.length > 1}/>}
            </TabScrollContainerStyled>
        )
    }

    const renderTabLabels = (): React.ReactElement => {
        /**
         * We only render labels if there are more than 1 tab (there is always at least one)
         */
        if (!menuItems || menuItems.length < 2) {
            return <NoTabsStyledWrapperStyled isMobile={isMobile}/>
        }
        return (
            <TabsStyledWrapperStyled isMobile={isMobile}>
                <TabsContainerStyled isMobile={isMobile}>
                    {menuItems && menuItems.length > 1 &&
                        menuItems.map((menuItem, idx: number) => renderTabLabel(menuItem, idx, menuItems.length))
                    }
                </TabsContainerStyled>
                {/** actions on the same level as tabs , can be under tabs as well, line 430*/}
                <div className={"ms-auto"}>{entityId && activeItemMenuGroups &&
                    <TabbedPageMenuBarWrapperStyled id={"menu-bar-wrapper"}>
                        {/*@ts-ignore*/}
                        <MenuBar entityId={entityId} menuGroups={activeItemMenuGroups}/>
                    </TabbedPageMenuBarWrapperStyled>
                }</div>
            </TabsStyledWrapperStyled>
        )
    }

    const renderTabs = (): React.ReactElement => {
        return (
            <TabbedPageTableContainerStyled>
                <TabbedPageTableContentStyled isMobile={isMobile} id={'tabbed_page'}
                                              startPos={startPos}
                                              fixedHeight={fixedHeight}
                                              footerHeight={footerHeight}>
                    {hasHeader && <ComponentTitleWrapperStyled>
                        {hasComplexHeader ? renderEntityGroupHeader() : renderSimpleHeader()}
                    </ComponentTitleWrapperStyled>}

                    {renderTabLabels()}

                    <TabContainerStyled>
                        {renderContents()}
                    </TabContainerStyled>
                </TabbedPageTableContentStyled>
            </TabbedPageTableContainerStyled>
        )
    }


    const renderPage = (): React.ReactElement => {
        return (
            <>

                <>{notFound ? renderNotFound() : renderTabs()}</>
            </>
        )
    }
    return (
        <>
            {loaded && systemReady ? renderPage() : <LoadingSpinner/>}
        </>
    )
}
export {TabbedPage as default}
